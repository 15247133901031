import React, { useState } from 'react';

function EmailForm() {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className='form-container'>
      <h2>Créer un nouveau mail</h2>
      <form className='contact-form'>
        <div>
          <label htmlFor="email">Destinataire:</label>
          <input
          className='input-group'
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Entrez l'adresse e-mail du destinataire"
          />
        </div>
        <div>
          <label htmlFor="subject">Sujet:</label>
          <input
          className='input-group'
            type="text"
            id="subject"
            value={subject}
            onChange={handleSubjectChange}
            placeholder="Entrez le sujet du mail"
          />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            value={message}
            onChange={handleMessageChange}
            placeholder="Entrez votre message ici"
          />
        </div>
        <div><button>envoyer</button></div>
      </form>
      <div>
        <h3>Aperçu du mail:</h3>
        <p><strong>Destinataire:</strong> {email}</p>
        <p><strong>Sujet:</strong> {subject}</p>
        <p><strong>Message:</strong> {message}</p>
      </div>
    </div>
  );
}

export default EmailForm;
