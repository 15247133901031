import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet-async';
import QuizE from '../../../composants/quizE';
import gestionDesFichiers from "../../../media/gestionDesFichiers.png";
import gestion from "../../../media/gestionFichier.mp4";
import gestion1 from "../../../media/gestionFichier1.mp4";
import gestion2 from "../../../media/gestionFichier2.mp4";
import gestion3 from "../../../media/gestionFichier3.mp4";
import gestion4 from "../../../media/gestionFichier4.mp4";
const Gestion = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
   const [isSubscribed, setIsSubscribed] = useState (false);
   useEffect(() => {
      // Vérifier l'état d'abonnement de l'utilisateur
      const checkSubscription = async () => {
        try {
          const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
          setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
          console.error('Erreur lors de la vérification de l\'abonnement :', error);
        }
      };
  
      checkSubscription();
    }, [userId]);
    
    useEffect(() => {
      axios
        .get(`https://www.clicdesouris.fr/api/users/${userId}`)
        .then((response) => {
          // Traitement des données de réponse ici
          console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          // Gestion des erreurs ici
          console.error("Erreur lors de la requête :", error);
        });
    }, [userId]);
    return (
      <>
      <Helmet>
        <title>Cours Gestion des Fichiers - Clic de souris</title>
      </Helmet>
        <div className='gestion-container'>
         
            <div className='gestion-content'>
             <h1>Gestion des fichiers et des dossiers sur Windows</h1> 
                <p>La gestion efficace des fichiers et des dossiers sur un système d'exploitation Windows est essentielle pour organiser, accéder et manipuler les données de manière optimale. Dans cet exposé, nous explorerons les principes fondamentaux de la gestion des fichiers et des dossiers sur Windows, y compris la navigation dans l'Explorateur de fichiers, la création, la modification, le déplacement et la suppression de fichiers et de dossiers, ainsi que des techniques avancées telles que la recherche et la gestion des versions.</p>
                <img src={gestionDesFichiers} alt="représentation d'un fichier jaune"  />
                <h2>Navigation dans l'Explorateur de fichiers</h2>
                <p>L'Explorateur de fichiers est l'outil principal pour naviguer à travers les fichiers et dossiers sur un ordinateur Windows. Pour y accéder, vous pouvez cliquer sur l'icône du dossier dans la barre des tâches ou appuyer sur la touche Windows + E. Une fois ouvert, vous verrez une structure d'arborescence de dossiers sur le côté gauche et une liste de fichiers et de dossiers dans la fenêtre principale.</p>
                <video width="320" height="240" controls>
        <source src={gestion} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <hr />
                <h2>.Création, modification, déplacement et suppression de fichiers et de dossiers</h2>
                <p>Création : Pour créer un nouveau dossier, cliquez avec le bouton droit de la souris dans l'emplacement souhaité, sélectionnez "Nouveau", puis "Dossier". Pour créer un nouveau fichier, vous pouvez utiliser un éditeur de texte ou un logiciel approprié, puis enregistrer le fichier dans l'emplacement souhaité.
                .Modification : Pour modifier le nom d'un fichier ou d'un dossier, cliquez avec le bouton droit de la souris sur l'élément et sélectionnez "Renommer". Tapez ensuite le nouveau nom et appuyez sur Entrée.
                .Déplacement : Pour déplacer un fichier ou un dossier vers un nouvel emplacement, vous pouvez le faire glisser et le déposer dans le dossier de destination.
                .Suppression : Pour supprimer un fichier ou un dossier, sélectionnez-le et appuyez sur la touche Suppr ou cliquez avec le bouton droit de la souris et sélectionnez "Supprimer". Vous pouvez également déplacer les éléments dans la Corbeille pour une suppression temporaire.</p>
                <video width="500" height="400" controls>
        <source src={gestion1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
                <video width="500" height="400" controls>
        <source src={gestion2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <hr />
                <h2>Techniques avancées de gestion des fichiers et des dossiers sur Windows</h2>
                <p>Recherche : Utilisez la barre de recherche en haut de l'Explorateur de fichiers pour rechercher des fichiers et des dossiers en fonction de leur nom, de leur type, de leur date de modification, etc.
                Gestion des versions : Windows offre des fonctionnalités de gestion des versions pour restaurer les versions précédentes de fichiers. Cela peut être utile en cas de modification accidentelle ou de perte de données.</p>
                <video width="520" height="440" controls>
        <source src={gestion3} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <hr />
                <h2>Compressions et archivages</h2>
                <p>Outre la création de dossiers, Windows propose également des outils intégrés pour compresser et archiver des fichiers et des dossiers afin d'économiser de l'espace de stockage et de faciliter le partage. L'utilitaire de compression de fichiers intégré, appelé "Explorateur de fichiers", permet de créer des archives au format ZIP en sélectionnant les fichiers et dossiers souhaités, puis en cliquant avec le bouton droit de la souris et en choisissant "Envoyer vers" - "Dossier compressé (format ZIP)". Les archives ZIP peuvent ensuite être extraites en double-cliquant dessus ou en utilisant l'option "Extraire tout".</p>
                <video width="520" height="440" controls>
        <source src={gestion4} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <hr />
                <h2>Utilisation de raccourcis et de liens symboliques</h2>
                <p>Windows permet également de créer des raccourcis vers des fichiers et des dossiers pour un accès rapide. Pour créer un raccourci, faites un clic droit sur le fichier ou le dossier, puis sélectionnez "Créer un raccourci". Le raccourci peut ensuite être déplacé vers un emplacement pratique, tel que le Bureau ou un dossier fréquemment utilisé.</p>
                <p>En outre, Windows prend en charge les liens symboliques, qui sont des points de référence vers des fichiers ou des dossiers situés ailleurs sur le système de fichiers. Les liens symboliques peuvent être créés à l'aide de l'invite de commandes avec la commande mklink. Par exemple, pour créer un lien symbolique vers un dossier nommé "DossierSource" dans un emplacement appelé "EmplacementCible", vous pouvez utiliser la commande suivante : mklink /D EmplacementCible DossierSource.</p>
                <h2>Gestion des permissions et des attributs</h2>
                <p>Enfin, Windows offre des fonctionnalités avancées de gestion des permissions et des attributs pour contrôler l'accès aux fichiers et aux dossiers. Vous pouvez modifier les autorisations de fichier en faisant un clic droit sur le fichier ou le dossier, puis en sélectionnant "Propriétés" - "Sécurité". De là, vous pouvez ajouter ou supprimer des utilisateurs et des groupes et définir les autorisations appropriées pour chacun.</p>
               <p>Les attributs de fichier, tels que "lecture seule", "caché" et "archive", peuvent également être modifiés dans la boîte de dialogue "Propriétés" d'un fichier ou d'un dossier.</p>
               <h2>Conclusion</h2>
               <p>En utilisant ces techniques avancées pour organiser, sauvegarder et récupérer vos fichiers sur Windows, vous pouvez maintenir un environnement de travail efficace et sécurisé. En comprenant ces fonctionnalités, vous serez en mesure de gérer efficacement vos données et d'optimiser votre productivité sur votre ordinateur Windows.
                </p>
                <QuizE/>
            </div>
         
        </div>
        </>
    );
};

export default Gestion;