import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import pc from "../../../media/pc.jpg";
import circuit from "../../../media/circuit.jpg";
import ram from "../../../media/ram.jpg";
import disque_dur from "../../../media/disque_dur.jpg";
import carte_mere from "../../../media/carte_mere.jpg";
import carte_graphique from "../../../media/carte_graphique.jpg";
import ventilateur from "../../../media/ventilateur.jpg";
import peripheriques from "../../../media/peripheriques.jpg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import QuizA from "../../../composants/quizA";
import Footer from "../../../composants/footer";
const Composition = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  
  return (
    <>
      <Helmet>
        <title>Composition - Clic de souris</title>
      </Helmet>
      
      <div className="composition-container">
        <div className="container">
          <br />
          <br />
          <br />
          <br />
          <h1> La Composition d'un Ordinateur :</h1>
          <br />

          <h3>Comprendre les Rouages de la Technologie</h3>
          <br />
          <br />
          <p>
            L'ordinateur, cette machine omniprésente dans notre quotidien, est
            un assemblage complexe de composants travaillant de concert pour
            accomplir une multitude de tâches. Comprendre la composition de cet
            appareil fascinant peut éclairer notre usage quotidien et stimuler
            notre curiosité technologique.
            
          </p><img src={pc} alt="pc avec tour" />
          <br />
          <h2>1. Le Cerveau : Processeur Central (CPU)</h2>
          <p>
            Imaginez le processeur comme le cerveau de l'ordinateur. C'est ici
            que toutes les opérations sont exécutées. Plus la puissance du
            processeur est élevée, plus l'ordinateur peut effectuer de tâches
            complexes simultanément.
            
          </p>
<img src={circuit} alt="circuit imprimé" />
          <br />
          <h2>2. La Mémoire Vive (RAM)</h2>
          <p>
            La RAM (Random Access Memory) est la mémoire à court terme de
            l'ordinateur. Elle stocke temporairement les données en cours
            d'utilisation, permettant un accès rapide aux informations
            nécessaires au processeur. Plus il y a de RAM, plus l'ordinateur
            peut gérer de données simultanément.
           
          </p>
 <img src={ram} alt="mémoire ram" />
          <br />
          <h2>3. Le Stockage : Disque Dur (HDD) ou Disque SSD</h2>
          <p>
            Le stockage est l'endroit où les données sont sauvegardées de
            manière permanente. Les disques durs traditionnels (HDD) utilisent
            des disques magnétiques en rotation, tandis que les disques SSD
            (Solid State Drive) sont plus rapides, car ils n'ont pas de pièces
            mobiles. Ils sont comme la mémoire à long terme de l'ordinateur.
            
          </p><img src={disque_dur} alt="disque dur hdd" />
          <br />
          <h2>4. La Carte Mère</h2>
          <p>
            La carte mère est la plaque de circuit qui relie tous les
            composants. Elle permet la communication entre le processeur, la
            mémoire, le stockage et d'autres périphériques. Elle est l'épine
            dorsale de l'ordinateur.
            
          </p><img src={carte_mere} alt="carte mère d'un ordinateur" />
          <br />
          <h2>5. La Carte Graphique (GPU)</h2>
          <p>
            La carte graphique gère les tâches liées à l'affichage et à la
            vidéo. Pour les jeux, la conception graphique et les tâches
            gourmandes en graphiques, une carte graphique dédiée est
            essentielle.
            
          </p><img src={carte_graphique} alt="carte graphique pour ordinateur" />
          <br />
          <h2>6. L'Alimentation et le Refroidissement</h2>
          <p>
            Comme tout moteur, un ordinateur a besoin d'énergie. L'alimentation
            convertit l'électricité en une forme utilisable par l'ordinateur. Le
            refroidissement est crucial pour éviter que les composants ne
            surchauffent. Les ventilateurs et les dissipateurs de chaleur jouent
            ce rôle vital.
            
          </p><img
              src={ventilateur}
              alt="ordinateur ouvert ou l'on voit son ventilateur"
            />
          <br />
          <h2>7. Les Périphériques</h2>
          <p>
            En plus de ces composants principaux, les périphériques tels que le
            clavier, la souris, l'écran et l'imprimante sont les interfaces
            entre l'homme et la machine.
            
          </p><img src={peripheriques} alt="ordinateur avec ses périphériques" />
          <br />
          <br />
          <p>
            En somme, l'ordinateur est une orchestration remarquable de
            technologies diverses. Comprendre ses composants fondamentaux offre
            une perspective précieuse sur la manière dont ces machines complexes
            fonctionnent pour rendre nos vies plus efficaces et connectées.
          </p>
          <br />
          <br />
        </div>
        <QuizA/>
      </div>
    </>
  );
};

export default Composition;
