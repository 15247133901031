import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import clavier from "../../../media/clavier.jpg";
import clavier2 from "../../../media/clavier2.jpg";
import { Helmet } from "react-helmet-async";
import "../../../styles/clavier.scss";
import TypingGame from "../../../composants/Jeux/TypingGame";

const Clavier = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    // Vérifier l'état d'abonnement de l'utilisateur
    const checkSubscription = async () => {
      try {
        const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
        setIsSubscribed(response.data.isSubscribed);
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'abonnement :', error);
      }
    };

    checkSubscription();
  }, [userId]);
  
  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>Clavier - Clic de souris</title>
      </Helmet>
      <div className="clavier-container">
        <br />
        <br />
        <br />
        <br />
       
        <div className="container">
          <h1>Le Rôle et le Fonctionnement des Claviers d'Ordinateur</h1>
          <br />
          <br />
          <br />
          <p>
            Le clavier d'ordinateur est un périphérique d'entrée fondamental qui
            permet aux utilisateurs de saisir du texte, d'effectuer des
            commandes et d'interagir avec leur ordinateur. Que vous utilisiez un
            clavier externe avec un ordinateur de bureau ou le clavier intégré
            d'un ordinateur portable, la structure de base et le fonctionnement
            demeurent similaires.
          </p>
          <br />
          <br />
          <h2> Structure de Base du Clavier :</h2>
          <br />
          <br />
          <img
            src={clavier}
            alt="clavier d'ordinateur posé sur une table en marbre"
          />
          <br />
          <br />
          <br />
          <h3> Clavier d'Ordinateur avec Tour :</h3>
          <br />
          <div>
            <p>
              <span className="span">1.Touches Alphanumériques : </span>
              Constituent la rangée principale de lettres et de chiffres. C'est
              la zone où la plupart de la saisie de texte a lieu.
              <br />
              <br />
              <span className="span">2.Touches de Fonction (F1-F12) : </span>
              Situées en haut du clavier, ces touches sont programmables et
              peuvent être utilisées pour effectuer diverses actions en fonction
              du logiciel ou de l'application.
              <br /> <br />
              <span className="span">3.Touches de Modification : </span>
              <br />
              <span className="span">.Shift : </span> Permet de saisir des
              majuscules et d'accéder à des caractères spéciaux.
              <br />
              <span className="span">.Ctrl (Control) : </span> Utilisée en
              combinaison avec d'autres touches pour effectuer des commandes
              spéciales. <br />
              <br />
              <span className="span">.Alt (Alternate) : </span>Utilisée pour
              accéder aux caractères alternatifs et aux fonctionnalités
              spéciales.
              <br /> <br />
              <span className="span">4.Touches de Navigation : </span>
              <br />
              <span className="span">Flèches : </span> Déplacent le curseur dans
              différentes directions.
              <br />
              <span className="span">Page Up/Page Down : </span> Fait défiler le
              contenu vers le haut ou vers le bas.
              <br />
              <span className="span">Home/End : </span> Déplace le curseur au
              début ou à la fin d'une ligne.
              <br /> <br />
              <span className="span">5.Section Numérique : </span>Située à
              droite du clavier principal, cette section contient un pavé
              numérique indépendant.
              <br /> <br />
              <span className="span">6.Touches d'Édition : </span>
              <br /> <br />
              <span className="span">.Entrée (Enter) : </span> Termine une ligne
              de texte ou valide une commande.
              <br />
              <span className="span">
                .Supprimer (Delete) et Retour Arrière (Backspace) :
              </span>{" "}
              Suppriment du texte.</p>
              <br />
              <br />
              <p>
              <span className="span">Clavier d'Ordinateur Portable : </span>
              <br /> <br />
              Les claviers d'ordinateurs portables sont compacts mais conservent
              généralement la plupart des éléments essentiels.
              <br />
              <br />
              <br />
              <img src={clavier2} alt="" />
              <br />
              <br />
              <br />
              <p><span className="span">1.Touches Alphanumériques : </span>{" "}
              Disposées de manière similaire à un clavier standard.
              <br /> <br />
              <span className="span">
                2.Touches de Fonction (F1-F12) :
              </span>{" "}
              Souvent combinées avec la touche Fn pour économiser de l'espace.
              <br /> <br />
              <span className="span">3.Touches de Modification :</span> Shift,
              Ctrl, et Alt sont présentes mais peuvent être intégrées à d'autres
              touches.
              <br /> <br />
              <span className="span">4.Touches de Navigation :</span> Intégrées
              à la rangée des lettres ou accessibles via la touche Fn.
              <br /> <br />
              <span className="span">5.Touches d'Édition :</span> Généralement
              présentes mais peuvent être réduites en taille.</p>
              <br />
              <br />
              <h2>Fonctionnement des Touches Spéciales :</h2>
              <br />
              <span className="span">1.Caps Lock :</span> Verrouille la
              majuscule.
              <br /> <br />
              <span className="span">2.Tab :</span> Déplace le curseur vers la
              droite dans un champ de texte.
              <br /> <br />
              <span className="span">3.Espace :</span> Insère un espace entre
              les mots.
              <br /> <br />
              <span className="span">
                4.Pavé Numérique (pour les claviers avec tour) :
              </span>{" "}
              Permet d'entrer des chiffres rapidement.
              <br />
              <br />
              <h2>Conclusion :</h2>
              <br />
              Comprendre la disposition et les fonctionnalités du clavier est
              essentiel pour une utilisation efficace de l'ordinateur. Que vous
              utilisiez un clavier avec tour ou un clavier intégré à un
              ordinateur portable, la familiarité avec les touches de base et
              leur fonctionnement vous rendra plus productif dans toutes vos
              activités informatiques.
            </p>
            <br />
            <br />
          </div>
       <TypingGame/>
        </div>
      
       
      </div>
    </>
  );
};

export default Clavier;
