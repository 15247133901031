import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import "../styles/cours.scss";
import Composition from "./Cours/Gratuit/composition";
import Peripheriques from "./Cours/Gratuit/peripheriques";
import Souris from "./Cours/Gratuit/souris";
import Clavier from "./Cours/Gratuit/clavier";
import Fenetre from "./Cours/Gratuit/fenetre";
import DemarreProg from "./Cours/Gratuit/DemarreProg";
import CopieColle from "./Cours/Gratuit/copieColle";
import Web from "./Cours/Gratuit/web";
import CreerMail from "./Cours/Gratuit/CreerMail";
import Tâches from "./Cours/Gratuit/Tâches";
import Gestion from "./Cours/Premium/Gestion";
import Securite from "./Cours/Premium/Sécurité"
import GoogleDoc from "./Cours/Premium/GoogleDoc";
import GoogleSheets from "./Cours/Premium/GoogleSheets";
import Dictionnaire from "./Cours/Gratuit/dictionnaire";
import Lexique from "./Cours/Gratuit/lexique";
const Cours = () => {
    const { userId } = useParams();
    const [isSubscribed, setIsSubscribed] = useState(false);
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeSection, setActiveSection] = useState('cours');
  const [choix, setChoix] = useState("");
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Récupérer le statut d'abonnement de l'utilisateur
        const subscriptionResponse = await axios.get(`https://www.clicdesouris.fr/subscription/${userId}`);
        const { isSubscribed } = subscriptionResponse.data;
        setIsSubscribed(isSubscribed);

  // Récupérer le rôle de l'utilisateur
  const isAdminResponse = await fetch(`https://www.clicdesouris.fr/api/users/${userId}`);
  const userData = await isAdminResponse.json();
  setIsAdmin(userData.role === "admin");
} catch (error) {
  console.error("Erreur lors de la récupération des données :", error);
}
};
    fetchData();
  }, [userId]);

  useEffect(() => {
  const checkSubscription = async () => {
    try {
      const response = await axios.get(`https://www.clicdesouris.fr/subscription/${userId}`);
      const { isSubscribed } = response.data; // Supposons que votre API renvoie un objet avec une propriété isSubscribed
      setIsSubscribed(isSubscribed);
    } catch (error) {
      // Gérer les erreurs de requête
      console.error('Erreur lors de la vérification de l\'abonnement :', error);
    }
  };
  checkSubscription();
  },[userId])

  

  const handleSubscription = async () => {
    try {
      // Envoyer une requête au serveur pour mettre à jour l'abonnement de l'utilisateur
      const response = await axios.post(`https://www.clicdesouris.fr/subscription/${userId}`, {
        userId: userId, // Supposons que userId est l'ID de l'utilisateur
        subscriptionType: 'monthly' // Supposons que l'utilisateur s'abonne mensuellement
      });
  
      // Vérifier si la requête a réussi
      if (response.status === 200) {
        // Mettre à jour l'état d'abonnement de l'utilisateur
        setIsSubscribed(true);
        // Autres logiques liées à l'abonnement (par exemple, afficher un message de confirmation)
        console.log('L\'utilisateur est maintenant abonné !');
      } else {
        console.error('La requête d\'abonnement a échoué.');
        // Gérer les erreurs de la requête d'abonnement
      }
    } catch (error) {
      console.error('Erreur lors de l\'abonnement :', error);
      // Gérer les erreurs de l'abonnement
    }
  };
  
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      try {
        const response = await axios.get(`https://www.clicdesouris.fr/subscription/${userId}`);
        const { isSubscribed } = response.data;
        setIsSubscribed(isSubscribed);
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'état d\'abonnement :', error);
      }
    };
  
    fetchSubscriptionStatus();
  }, [userId]);

  
    return (
        <>
          <Helmet>
            <title>Cours - Clic de souris</title>
          </Helmet>
    <br /><br />
            <div className="container-cours-general">
            {activeSection === 'cours' && (
            <div className="content-cours">
              <h2>Cours</h2>
              <h3>
                Ces cours s'adressent à des débutants (Séniors, Enfants), ou des personnes qui n'ont
                jamais touché à un ordinateur !
              </h3>
              <br /> <h4>Pour les autres ça peut servir de rappel...</h4>
              <p>
                <button onClick={() => setChoix("Lexique")}>Lexique</button>
              </p>
              <p>
                <button onClick={() => setChoix("Dictionnaire")}>
                  Le dictionnaire 
                </button>
              </p>
              <p>
                <button  onClick={() => setChoix("Composition")}>Composition d'un ordinateur</button>
              </p>
              <p>
                <button onClick={() => setChoix("Peripheriques")}>Les périphériques d'un ordinateur</button>
              </p>
              <p>
                <button onClick={() => setChoix("Souris")}>Connaître sa souris</button>
              </p>
              <p>
                <button onClick={() => setChoix("Clavier")}>Connaître son clavier</button>
              </p>
              <p>
                <button onClick={() => setChoix("Fenetre")}>
                  Se repérer dans une fenêtre Windows
                </button>
              </p>
              <p>
                <button onClick={() => setChoix("DemarreProg")}>
                  Démarrer un programme ou une application
                </button>
              </p>
              <p>
                <button onClick={() => setChoix("CopieColle")}>
                  Copier, coller et déplacer un élément
                </button>
              </p>
              <p>
                <button onClick={() => setChoix("Web")}>Démarrer une page web </button>
              </p>
              <p>
                <button onClick={() => setChoix("CreerMail")}>
                  Apprendre à créer une adresse électronique
                </button>
              </p>
              <p>
                <button onClick={() => setChoix("Tâches")}>
                 La barre des tâches de Windows
                </button>
              </p>
            </div>
            )}
{/* <h2>Cours Premium</h2>
             
            <Link to={`/abonnement/${userId}`}>
 <button onClick={handleSubscription}>
      S'abonner
    </button></Link>
            <div className="content-payant">
              
              {isSubscribed ? (
    <>
              
              <p>
                <button onClick={() => setChoix("Gestion")}>
                 Gestions des fichiers et dossiers
                </button>
              </p> 
              <p>
                <button onClick={() => setChoix("Securite")}>
                 Protégez vous 
                </button>
              </p> 
              <p>
                <button onClick={() => setChoix("GoogleDoc")}>
                 DOC de Google (Traitement de texte) 
                </button>
              </p> 
              <p>
                <button onClick={() => setChoix("GoogleSheets")}>
                 Sheets de Google (Feuille de calcul) 
                </button>
              </p> 
            </>
            ) : (
              <p>Veuillez vous abonner pour accéder aux cours premium.</p>
            )}
            </div> */}
           
             <div className="choix">
      {choix === "Lexique" && <Lexique />}
      {choix === "Composition" && <Composition />}
      {choix === "Peripheriques" && <Peripheriques />}
      {choix === "Souris" && <Souris />}
      {choix === "Clavier" && <Clavier />}
      {choix === "Fenetre" && <Fenetre />}
      {choix === "DemarreProg" && <DemarreProg />}
      {choix === "CopieColle" && <CopieColle />}
      {choix === "Web" && <Web />}
      {choix === "CreerMail" && <CreerMail />}
      {choix === "Tâches" && <Tâches />}
      {choix === "Dictionnaire" && <Dictionnaire />}
      {choix === "Gestion" && <Gestion />}
      {choix === "Securite" && <Securite />}
      {choix === "GoogleDoc" && <GoogleDoc />}
      {choix === "GoogleSheets" && <GoogleSheets />}
       </div>
          </div>
        </>
      );
};

export default Cours;