import React, { useState } from 'react';
import QRCode from 'qrcode.react';

function QRCodeGenerator() {
  const [text, setText] = useState('');

  const handleChange = (event) => {
    setText(event.target.value);
  };

  return (
    <div className='srcode-container'>
      <h2>Générateur de Code QR</h2>
      <label htmlFor="text">Texte / URL:</label>
      <input
        type="text"
        id="text"
        value={text}
        onChange={handleChange}
        placeholder="Entrez le texte ou l'URL ici"
      />
      <br />
      {text && (
        <div>
          <h3>Résultat:</h3>
          <QRCode value={text} />
        </div>
      )}
      <div className='explication'>
       <h3> Comment générer et lire son QrCode :</h3>
       
        <h4>Pour générer un code QR à partir d'un mot ou d'une phrase ;</h4>
        <ul>
          <li>Tapez un mot ou une phrase à l'endroit prévu.</li>
          <li>Vous verrez le résultat de votre Qrcode (Dessin).</li>
          <li>Installez une application sur votre téléphone capable de lire les QrCodes. </li>
          <li>Démarrez est scanner le QrCode généré précédemment.</li>
          <li>Vous verrez apparaître la phrase ou le mot que vous aviez indiqué.</li>
        </ul>
        <h4>Pour générer un code QR à partir d'un site ou de votre réseau social.</h4>
        <ul>
          <li>Copiez-Collez l'adresse URL de votre site ou réseau à l'endroit prévu.</li>
          <li>Vous verrez le résultat de votre Qrcode (Dessin).</li>
          <li>Installez une application sur votre téléphone capable de lire les Qrcodes.</li>
          <li>Scannez le QrCode généré par le générateur.</li>
          <li>Cliquez sur le lien que l'application vous propose.</li>
          <li>Vous être envoyé directement sur votre site ou votre réseau social.</li>
        </ul>
        <p>Grâce au générateur que QrCode vous pourrez maintenant rendre plus "fun" la publicité pour vos sites ou réseaux sociaux!</p>
       
      </div>
    </div>
    
  );
}

export default QRCodeGenerator;
