import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, BrowserRouter } from "react-router-dom";
import Accueil from "./accueil.jsx";
import Nav from "./composants/nav.jsx";
import Footer from "./composants/footer.jsx";
import Apropos from "./pages/apropos.jsx";
import "./styles/index.scss";
import { HelmetProvider } from "react-helmet-async";
import Service from "./pages/service.jsx";
import Tarifs from "./pages/tarifs.jsx";
import Contact from "./pages/contact.jsx";
import Inscription from "./pages/inscription.jsx";
import Profil from "./pages/profil.jsx";
import {AuthProvider} from './composants/context'; 
import { createRoot } from "react-dom/client";
import RGPD from "./pages/rgpd.jsx";
import Don from "./pages/don.jsx";
import "bootstrap/dist/css/bootstrap.min.css";


const AuthWrapper = () => {
 
  
  return (

 <BrowserRouter>
  <AuthProvider>
    <HelmetProvider>
      <Routes>
        <Route exact path="/" element={<Accueil />}/>
        <Route path="/accueil" element={<Accueil />}/>
        <Route path="/profil" element={<Profil />}/>
        <Route path="/nav" Component={<Nav />} />
        <Route path="/footer" Component={<Footer />} />
        <Route path="/apropos" element={<Apropos />} />
        <Route path="/service" element={<Service />} /> 
        <Route path="/tarifs" element={<Tarifs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/inscription" element={<Inscription />} />
        <Route path="/rgpd" element={<RGPD />} />
        <Route path="/don/" element={<Don />} />
</Routes>
    </HelmetProvider>
    </AuthProvider>
  
  </BrowserRouter>
);
  };
createRoot(document.getElementById("root")).render(<AuthWrapper />);