import React, { useState } from "react";

const QuizB = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 1",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1.Quel périphérique informatique est principalement utilisé pour la saisie de texte ??",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "La Webcam",
          "Le Clavier",
          "Le Microphone",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Le Clavier était la bonne réponse. C'est grâce au clavier que l'on peut écrire du texte.",
        "point": 20
      },
      {
        "question": "2. Quel périphérique permet de produire des copies papier de documents numériques ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "La Webcam",
          "l'Imprimante",
          "les Haut-parleurs",

        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "L'imprimante était bien la bonne réponse ! Même si, heureusement, elle est de moins en moins utilisée, l'imprimante reste l'outil principal pour imprimer les documents. ",
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Quel périphérique est utilisé pour écouter de la musique ou regarder des vidéos sur un ordinateur ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Joystick",
          "Moniteur",
          "Haut-parleurs ou Casques",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le haut-parleur ou le casque.",
        "point": 20
      },
      {
        "question": "4. Quel périphérique est utilisé pour capturer des appels vidéo et des conférences en ligne ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Webcam",
          "Lecteur de cartes mémoire",
          "Disque dur externe",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est la Webcam.",
        "point": 20
      },
      {
        "question": "5. Quel périphérique est essentiel pour les appels vocaux et les enregistrements audio ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Moniteur",
          "Clavier",
          "Microphone",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le microphone.",
        "point": 20
      },
      {
        "question": "6. Quel périphérique est utilisé pour faciliter le transfert de données depuis des cartes mémoire ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Disque dur externe",
          "Webcam",
          "Lecteur de cartes mémoire",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le lecteur de cartes mémoire.",
        "point": 20
      },
      {
        "question": "7. Quel périphérique offre un espace de stockage supplémentaire pour les fichiers ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Disque dur externe",
          "Moniteur",
          "Tablette graphique",
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le disque dur externe",
        "point": 20
      },
      {
        "question": "8. Quel périphérique utilise la technologie sans fil pour se connecter à l'ordinateur ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Souris et Clavier Sans Fil",
          "Hub USB",
          "Joystick et Manettes de Jeu",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est la souris et le clavier sans fil.",
        "point": 20
      },
      {
        "question": "9. Quel périphérique est principalement utilisé par les professionnels de la création pour dessiner et concevoir des graphiques ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Moniteur",
          "Webcam",
          "Tablette graphique",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est la tablette graphique.",
        "point": 20
      },
      {
        "question": "10. Quel périphérique permet d'étendre le nombre de ports USB disponibles sur l'ordinateur ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Hub USB",
          "Microphone",
          "Imprimante",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Hub USB.",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizA">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizB;
