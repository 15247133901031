import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import twitter from "../../media/twitter.png";
const Twitter = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`https://www.clicdesouris.fr/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);
  return (
    <div className="twitter-container">
      <img src={twitter} alt="logo X pour twitter" />
      <h1>Introduction à Twitter</h1>
      <p>
        Twitter a été créé par Jack Dorsey, Noah Glass, Biz Stone et Evan
        Williams. La date de création de Twitter est le 21 mars 2006.
        L'Entreprise est rachetée entre autre par Elon Musk en octobre 2022 qui
        changera de nom pour "X".{" "}
      </p>
      <h3>1. Création d'un compte :</h3>
      <p>
        .Rendez-vous sur le site web de Twitter (www.twitter.com). .Cliquez sur
        le bouton "S'inscrire". .Remplissez le formulaire d'inscription avec vos
        informations personnelles : nom complet, numéro de téléphone ou adresse
        e-mail, et date de naissance. .Choisissez un nom d'utilisateur unique.
        .Sélectionnez un mot de passe sécurisé. .Cliquez sur le bouton
        "S'inscrire" pour créer votre compte.
      </p>
      <h3>2. Personnalisation du profil :</h3>
      <p>
        Après vous être connecté, cliquez sur votre photo de profil dans le coin
        supérieur droit. Sélectionnez "Profil". Ajoutez une photo de profil et
        une photo de couverture. Rédigez une biographie concise pour décrire qui
        vous êtes.
      </p>
      <h3>3. Trouver des personnes à suivre :</h3>
      <p>
        Utilisez la barre de recherche pour trouver des personnes, des
        entreprises ou des organisations que vous souhaitez suivre. Cliquez sur
        le bouton "Suivre" à côté du nom de l'utilisateur pour les suivre.
      </p>
      <h3>4. Publier un tweet :</h3>
      <p>
        Sur votre page d'accueil, cliquez sur le bouton "Tweet" dans la zone de
        rédaction. Tapez votre message (280 caractères maximum). Vous pouvez
        ajouter des photos, des vidéos ou des GIFs en cliquant sur les icônes
        correspondantes. Cliquez sur "Tweeter" pour publier votre tweet.
      </p>
      <h3>5. Retweeter et aimer des tweets :</h3>
      <p>
        Sous chaque tweet, vous trouverez des icônes pour retweeter, aimer et
        commenter. Cliquez sur l'icône de retweet pour partager le tweet avec
        vos abonnés. Cliquez sur l'icône de cœur pour aimer un tweet.
      </p>
      <h3>6. Utilisation des hashtags et des mentions :</h3>
      <p>
        Utilisez des hashtags (#) pour catégoriser votre tweet et le rendre
        visible pour les autres utilisateurs intéressés par ce sujet. Mentionnez
        d'autres utilisateurs en utilisant le symbole "@" suivi de leur nom
        d'utilisateur pour les inclure dans votre tweet.
      </p>
    </div>
  );
};

export default Twitter;
