import React from "react";
import instagram from "../../media/instagram.png";
const Instagram = () => {
  return (
    <div className="insta-container">
      <img src={instagram} alt="log instagram" />
      <h1>Introduction à Instagram</h1>
      <p>
        Instagram est une plateforme de partage d'images et de vidéos, créée en
        2010 par Kevin Systrom et Mike Krieger.
      </p>
      <h3>1. Téléchargement de l'application :</h3>
      <p>
        Rendez-vous sur l'App Store (pour les utilisateurs iOS) ou sur Google
        Play Store (pour les utilisateurs Android). Recherchez l'application
        Instagram et téléchargez-la sur votre appareil.
      </p>
      <h3>2. Création d'un compte :</h3>
      <p>
        Ouvrez l'application Instagram. Cliquez sur "Inscription" pour créer un
        nouveau compte. Remplissez le formulaire d'inscription avec votre
        adresse e-mail ou votre numéro de téléphone, votre nom complet et votre
        date de naissance. Choisissez un nom d'utilisateur unique et un mot de
        passe sécurisé. Cliquez sur "S'inscrire" pour créer votre compte.
      </p>
      <h3>3. Personnalisation du profil :</h3>
      <p>
        Après vous être connecté, cliquez sur l'icône de profil en bas à droite.
        Cliquez sur "Modifier le profil" pour ajouter une photo de profil, une
        biographie et un site web (le cas échéant).
      </p>
      <h3>4. Recherche de personnes à suivre :</h3>
      <p>
        Utilisez la barre de recherche pour trouver des personnes, des marques
        ou des entreprises que vous souhaitez suivre. Cliquez sur le bouton
        "Suivre" à côté du nom de l'utilisateur pour les suivre.
      </p>
      <h3>5. Publication de photos et de vidéos :</h3>
      <p>
        Cliquez sur l'icône "+" en bas de l'écran pour publier une nouvelle
        photo ou vidéo depuis votre galerie ou en prenant une photo directement
        depuis l'application. Ajoutez des filtres, des légendes et des tags
        (identifications) avant de publier.
      </p>
      <h3>6. Utilisation des stories et des messages directs :</h3>
      <p>
        Appuyez sur votre photo de profil dans le coin supérieur gauche pour
        accéder à votre story. Appuyez sur l'icône de messagerie en haut à
        droite pour envoyer des messages directs à d'autres utilisateurs.
      </p>
      <h3>7. Interagir avec d'autres utilisateurs :</h3>
      <p>
        Aimez et commentez les photos et vidéos d'autres utilisateurs pour
        interagir avec eux. Utilisez les hashtags (#) pour rendre votre contenu
        découvrable par d'autres utilisateurs intéressés par des sujets
        similaires.
      </p>
      <p>
        Ces instructions devraient aider vos utilisateurs à s'inscrire et à
        utiliser les principales fonctionnalités de Twitter et Instagram. Vous
        pouvez les personnaliser davantage en fonction de vos besoins
        spécifiques.
      </p>
    </div>
  );
};

export default Instagram;
