import React, { useState, useEffect } from 'react';
// import './TypingGame.css'; // Fichier de style CSS pour personnaliser l'apparence du jeu

const TypingGame = () => {
  const [word, setWord] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(10);
  const [gameOver, setGameOver] = useState(false);
  const [finalScore, setFinalScore] = useState(0);
  const words = ['bonjour', 'clavier', 'souris', 'ordinateur', 'facebook', 'internet', 'webcam', 'casque', 'ecran', 'virus', 'programme'];

  useEffect(() => {
    if (!gameOver && timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (timeLeft === 0) {
      setGameOver(true);
      setFinalScore(score);
    }
  }, [timeLeft, score, gameOver]);

  const getRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * words.length);
    return words[randomIndex];
  };

  const startGame = () => {
    setWord(getRandomWord());
    setScore(0);
    setTimeLeft(30);
    setGameOver(false);
    setFinalScore(0);
    setInputValue('');
  };

  const handleChange = (event) => {
    if (!gameOver) {
      setInputValue(event.target.value);
      if (event.target.value === word) {
        setScore(score + 1);
        setWord(getRandomWord());
        setInputValue('');
      }
    }
  };
  return (
    <div className="typing-game">
      <h2>Typing Game</h2>
      <h3>Tapez le plus de mot en 30 secondes !</h3>
      <p>Tape le mot du dessous :</p>
      <div className="word-display">{word}</div>
      <input type="text" value={inputValue} onChange={handleChange} autoFocus={!gameOver} disabled={gameOver} />
      <div className="info">
        <p>Score: {score}</p>
        <p>Temps restant : {timeLeft} seconds</p>
      </div>
      {gameOver && (
        <div className="game-over">
          <p>Game Over! Votre score final est  {finalScore}</p>
          <button onClick={startGame}>Start</button>
        </div>
      )}
    </div>
  );
};

export default TypingGame;
