import React, { useState, useEffect } from "react";
import Nav from "./composants/nav";
import { Helmet } from "react-helmet";
import temoin1 from "./media/temoin1.png";
import temoin2 from "./media/temoin2.png";
import temoin3 from "./media/temoin3.png";
import temoin4 from "./media/temoin4.png";
import banniere1 from "./media/banniere1.jpg";
import banniere2 from "./media/banniere2.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";


//rgb(1,128,142) couleur dominante  couleur btn vert = rgb(1, 142, 50) couleur btn rouge = rgb(142, 1, 22) bleu = rgb(1, 22, 142)
const Accueil = ({isConnected}) => {
  const { userId } = useParams();
  const [scrolled, setScrolled] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [faqVisibility, setFaqVisibility] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const toggleFaqVisibility = (index) => {
    const updatedVisibility = [...faqVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setFaqVisibility(updatedVisibility);
  };

  const banners = [
    {
      text: "La solution pour Apprendre à se servir d'un ordinateur                                                     ",
      description:
        " Découvrez le monde de l'informatique sans tracas avec une méthode simple et ludique ! Devenez un expert, même si vous partez de zéro. Offrez-vous votre formateur à domicile !",
      image: banniere1,
    },
    {
      text: "Lancez-vous dans le monde numérique !",
      description:
        "Vous cherchez des cours d'informatique sur mesure ?Vous avez des questions ou vous souhaitez prendre rendez-vous ?N'ayez pas peur de franchir le pas ! Explorez de nouvelles opportunités.",
      image: banniere2,
      actionButtonLabel: "Cliquez ici",
      onActionButtonClick: () => {
        // Ajoutez ici la logique à exécuter lorsque le bouton est cliqué
       Link("./contact");
      },
    },
    // Ajoutez autant de bannières que nécessaire
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [modalVisible, setModalVisible] = useState(false);

  const openModal = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  // pour récupérer l'ID de l'utilisateur
  useEffect(() => {
    if (userId) {
      // Effectuer une requête pour obtenir les données de l'utilisateur
      axios
        .get(`https://www.clicdesouris:5000/api/users/${userId}`)
        .then((response) => {
          // Traitement des données de réponse ici
          setData(response.data);
        })
        .catch((error) => {
          // Gestion des erreurs ici
          console.error("Erreur lors de la requête :", error);
        });
    }
  }, [userId]);
  
  return (
    <div className="bodyAccueil">
      <Helmet>
        <title>Clic de souris-Accueil</title>
      </Helmet>
      <Nav scrolled={scrolled} isConnected={isConnected} />
      <div className="container-accueil">
      {/* <Link to="https://www.youtube.com/@ClicDeSouris" target="_blanck"><img className="logoyoutube" src={youtube} alt="logo de youtube" /></Link>
      <Link to="https://www.facebook.com/" target="_blanck"><img className="logofacebook" src={facebook} alt="logo de facebook" /></Link>
      <Link to="https://www.discord.com/" target="_blanck"><img className="logodiscord" src={discord} alt="logo de discord" /></Link> */}
        <Slider {...settings}>
          {banners.map((banner, index) => (
            <div key={index} className="banniere">
              <div className="banniere-container">
                <div className="banniere-content">
                  <div className="banniere-text">
                    <p>{banner.text}</p>
                    <p>{banner.description}</p>
                    {/* {banner.actionButtonLabel && (
                      <button onClick={banner.onActionButtonClick}>
                        {banner.actionButtonLabel}
                      </button>
                    )} */}
                  </div>
                  {/* <img
                    className="imgbanniere"
                    src={banner.image}
                    alt={`Bannière ${index + 1}`}
                  /> */}
                </div>
              </div>
            </div>
          ))}
        </Slider>

        <section className="summary-box">
          <div className="summary-container">
            
            <div className="summary-pub">
              
                    <p className="summary-text">
                      Bonjour. <br />
                      <br />
                      De nos jours, la maîtrise de l'informatique est devenue
                      essentielle dans de nombreux aspects de notre vie
                      quotidienne.
                      <br />
                      Cependant, pour les personnes novices dans ce domaine,
                      l'apprentissage de l'informatique peut sembler intimidant.
                      C'est pourquoi je propose une solution pour les débutants
                      qui souhaitent acquérir des compétences informatiques de
                      base.
                      <br /> Que vous soyez un étudiant, un professionnel en
                      reconversion ou simplement quelqu'un qui souhaite se
                      familiariser avec les bases de l'informatique, les cours
                      sont adaptés à votre niveau de connaissance.
                      <br /> J'aborde plusieurs sujets tels que la navigation
                      sur Internet, l'utilisation des logiciels courants, la
                      sécurité en ligne, la gestion des fichiers et bien plus
                      encore.
                      <br />
                      Je vous guide pas à pas, en utilisant un langage clair et
                      accessible, pour vous aider à comprendre les concepts
                      fondamentaux de l'informatique.
                      <br />
                      Ne laissez pas votre manque de connaissances en
                      informatique vous retenir. Commencez dès aujourd'hui et
                      ouvrez-vous un monde de possibilités...
                    </p>
                    <p className="summary-text">A bientôt !</p>
                
            </div>{" "}
          </div>
        </section>
        {/* <div className="information-container">
          <h1>POURQUOI CREER UN COMPTE ?</h1>
          <h5>Gratuité :</h5>
          La création d'un compte sur CLIC DE SOURIS est entièrement gratuite. Pas de frais cachés, pas de coûts supplémentaires. 
          <h5>Accès aux cours d'informatique gratuits :</h5>
          Une fois inscrit, vous aurez accès à notre vaste bibliothèque de cours d'informatique gratuits. Que vous soyez débutant ou que vous souhaitiez approfondir vos connaissances, nos ressources sont conçues pour vous aider à développer vos compétences informatiques.
          <h5>Jeux ludiques gratuits :</h5>
          Envie de vous divertir tout en stimulant votre esprit ? Explorez notre sélection de jeux ludiques gratuits pour tous les âges et tous les niveaux.
          <h5>Informations pratiques pour les seniors :</h5> 
          En tant que plateforme axée sur l'inclusion, nous proposons également des informations pratiques spécialement conçues pour les seniors. De la santé et du bien-être aux conseils pour rester actif et engagé, notre contenu est adapté à vos besoins.
          <h4>Ce n'est que le début !</h4>
          Nous travaillons sans relâche pour améliorer votre expérience sur CLIC DE SOURIS. Restez à l'écoute car de nouvelles fonctionnalités passionnantes sont en préparation. Des surprises vous attendent !

Prêt à commencer ? Créez votre compte dès maintenant et rejoignez notre communauté en pleine croissance sur CLIC DE SOURIS !
        </div> */}
        <div className="faq">
          <section className="faq-section">
            <h2 className="faq-heading">FAQ</h2>

            <div className="faq-item">
              <h3 className="faq-question">
                Quels sont les niveaux de cours proposés ?
              </h3>
              <p className={`faq-answer ${faqVisibility[0] ? "visible" : ""}`}>
                Je propose des cours adaptés à tous les niveaux, des débutants
                complets aux utilisateurs avancés. Je m'adapter à votre niveau
                de connaissances et vous propose un apprentissage personnalisé.
              </p>
              <button
                className="faq-toggle"
                onClick={() => toggleFaqVisibility(0)}
              >
                <span>&#x25BC;</span>
              </button>
            </div>
            <div className="faq-item">
              <h3 className="faq-question">
                Quels sont les horaires disponibles pour les cours ?
              </h3>
              <p className={`faq-answer ${faqVisibility[1] ? "visible" : ""}`}>
                Je vous propose des horaires flexibles qui s'adaptent à votre
                emploi du temps. Vous pouvez prendre rendez-vous pour des cours
                en fonction de vos disponibilités. Contactez-moi pour discuter
                des horaires qui vous conviennent le mieux.
              </p>
              <button
                className="faq-toggle"
                onClick={() => toggleFaqVisibility(1)}
              >
                <span>&#x25BC;</span>
              </button>
            </div>
            <div className="faq-item">
              <h3 className="faq-question">
                Quels sont les prérequis pour suivre vos cours d'informatique ?
              </h3>
              <p className={`faq-answer ${faqVisibility[2] ? "visible" : ""}`}>
                Aucun prérequis n'est nécessaire. Mes cours s'adressent aux
                débutants complets et sont conçus pour vous guider étape par
                étape.
              </p>
              <button
                className="faq-toggle"
                onClick={() => toggleFaqVisibility(2)}
              >
                <span>&#x25BC;</span>
              </button>
            </div>
            <div className="faq-item">
              <h3 className="faq-question">
                Quelle est la durée des cours d'informatique ?
              </h3>
              <p className={`faq-answer ${faqVisibility[3] ? "visible" : ""}`}>
                La durée des cours varie en fonction de vos besoins et de vos
                objectifs. Nous pouvons convenir d'un planning personnalisé
                adapté à votre emploi du temps.
              </p>
              <button
                className="faq-toggle"
                onClick={() => toggleFaqVisibility(3)}
              >
                <span>&#x25BC;</span>
              </button>
            </div>

            <div className="faq-item">
              <h3 className="faq-question">
                Quels sujets sont couverts dans vos cours d'informatique ?
              </h3>
              <p className={`faq-answer ${faqVisibility[5] ? "visible" : ""}`}>
                Mes cours abordent divers sujets tels que la navigation sur
                Internet, l'utilisation des logiciels courants, la sécurité en
                ligne, la gestion des fichiers, la messagerie électronique, etc.
              </p>
              <button
                className="faq-toggle"
                onClick={() => toggleFaqVisibility(5)}
              >
                <span>&#x25BC;</span>
              </button>
            </div>
            <div className="faq-item">
              <h3 className="faq-question">
                Puis-je choisir les sujets spécifiques que je souhaite apprendre
                ?
              </h3>
              <p className={`faq-answer ${faqVisibility[6] ? "visible" : ""}`}>
                Absolument ! Les cours sont entièrement personnalisés en
                fonction de vos besoins et de vos intérêts. Nous pouvons
                discuter des sujets spécifiques que vous souhaitez aborder.
              </p>
              <button
                className="faq-toggle"
                onClick={() => toggleFaqVisibility(6)}
              >
                <span>&#x25BC;</span>
              </button>
            </div>
          </section>
        </div>
        <section className="testimonials">
          <h2 className="testimonial-heading">Témoignages</h2>
          <div className="testimonial-container">
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-avatar">
                  <img src={temoin1} alt="Avatar" />
                </div>
                <p className="testimonial-text">
                  "J'ai suivi les cours d'informatique de Frédéric pendant
                  quelques mois et je suis vraiment impressionné par ses
                  compétences pédagogiques. Il explique les concepts de manière
                  claire et concise, ce qui rend l'apprentissage de
                  l'informatique beaucoup plus facile pour moi. Je recommande
                  vivement ses cours !"
                </p>
                <p className="testimonial-author">- David</p>
              </div>
            </div>
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-avatar">
                  <img src={temoin4} alt="Avatar" />
                </div>
                <p className="testimonial-text">
                  "Grâce aux cours d'informatique de Frédéric, j'ai pu acquérir
                  les compétences nécessaires pour naviguer sur Internet en
                  toute confiance. Ses explications sont simples et pratiques,
                  ce qui m'a permis de progresser rapidement. Je le recommande à
                  tous ceux qui souhaitent se sentir plus à l'aise avec la
                  technologie."
                </p>
                <p className="testimonial-author">- Karine</p>
              </div>
            </div>
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-avatar">
                  <img src={temoin3} alt="Avatar" />
                </div>
                <p className="testimonial-text">
                  "Les cours d'informatique de Frédéric ont été une révélation
                  pour moi. Avant, je me sentais complètement dépassé par la
                  technologie, mais grâce à sa méthode d'enseignement patiente
                  et bien structurée, j'ai pu progresser à mon rythme.
                  Maintenant, je me sens beaucoup plus confiant et à l'aise avec
                  les ordinateurs."
                </p>
                <p className="testimonial-author">- Marie </p>
              </div>
            </div>
            <div className="testimonial">
              <div className="testimonial-content">
                <div className="testimonial-avatar">
                  <img src={temoin2} alt="Avatar" />
                </div>
                <p className="testimonial-text">
                  "Je recommande vivement les cours d'informatique de Frédéric.
                  Il est très compétent et sait comment expliquer les choses de
                  manière simple et compréhensible. J'ai pu apprendre des
                  astuces et des raccourcis très utiles qui ont considérablement
                  amélioré mon efficacité au travail. Merci Frédéric !"
                </p>
                <p className="testimonial-author">- Marc </p>
              </div>
            </div>
          </div>
        </section>
        <section className="certifications-section">
          <h2>Certifications</h2>
          <div className="certification">
            <div className="certification-details">
              <h3>Diplôme d'Etat de Développeur Web</h3>
              <p>Développeur Web et Web Mobile</p>
            </div>
          </div>
          
          <div className="certification">
            <div className="certification-image"></div>
            <div className="certification-details">
              <h3>
                Diplôme d'Etat CDA
              </h3>
              <p>Concepteur développeur d'Application</p>
            </div>
          </div><div className="certification">
            <div className="certification-image"></div>
            <div className="certification-details">
              <h3>Incubateur à l'AFPA</h3>
              
            </div>
          </div>
        </section>
      </div>
      <div className="footerAcc-container">
          
           <div className="footerAcc-nav">
        <ul className="footerAcc-list">
          <li>
            <Link to="/Apropos" >
              A propos
            </Link>
          </li>
          <li>
            <Link to="/Tarifs" >
              Tarifs
            </Link>
          </li>
          <li>
            <Link to="/Contact">
              Contact
            </Link>
          </li>
          <li>
            <Link to="/rgpd">
             Politique de confidentialité
            </Link>
          </li>
          {/* <li>
            <Link to="/mentions">
             Mentions Légales
            </Link>
          </li> */}
        </ul>
      </div> 
      <footer className="site-footerAcc">
    
      <div className="site-info">
        <p>Clic de souris &copy; 2024</p>
      </div>
    
  </footer>
        </div>
    </div>
  );
};

export default Accueil;
