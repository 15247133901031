import React, { useState } from "react";

const QuizE = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 2",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1.Quelle fonctionnalité de Google Docs permet aux utilisateurs de collaborer en temps réel sur un document via une visioconférence ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Google Sheets",
          "Google Meet",
          "Google Slides",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Google Meet était la bonne réponse.",
        "point": 20
      },
      {
        "question": "2. Comment créer un formulaire en ligne pour recueillir des réponses et des données auprès des utilisateurs dans Google Docs ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Allez dans 'Format' > 'Créer un formulaire'",
         "Allez dans 'Insertion' > 'Créer un formulaire'",
         "Allez dans 'Outils' > 'Créer un formulaire'",

        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": " La bonne réponse était : Allez dans 'Outils' > 'Créer un formulaire'",
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Quelle fonctionnalité de Google Docs permet de générer automatiquement une table des matières à partir des titres de section ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Insérer des tableaux",
          "Insérer des graphiques",
          "Insérer des tables des matières",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Insérer des tables des matières.",
        "point": 20
      },
      {
        "question": "4.Comment exporter un document Google Docs au format Microsoft Word (.docx) ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Allez dans 'Fichier' > 'Exporter' > 'Word'",
          "Allez dans 'Fichier' > 'Télécharger' > 'Microsoft Word (.docx)'",
          "Allez dans 'Fichier' > 'Partager' > 'Exporter vers Word'",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Allez dans 'Fichier' > 'Télécharger' > 'Microsoft Word (.docx)'.",
        "point": 20
      },
      {
        "question": "5. Quelle fonctionnalité de Google Docs permet de traduire un document dans différentes langues ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Outils de traduction",
          "Outils de vérification linguistique",
          " Outils de révision",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Outils de traduction",
        "point": 20
      },
      {
        "question": "6. Quelle application Google permet d'intégrer Google Docs avec Google Agenda pour planifier des réunions et des événements ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Google Sheets",
          "Google Meet",
          "Google Agenda",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Google Agenda.",
        "point": 20
      },
      {
        "question": "7. Comment insérer des commentaires et des suggestions dans un document Google Docs ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Cliquez sur 'Modifier' > 'Insérer un commentaire'",
          "Cliquez sur 'Insertion' > 'Commentaire'",
          "Cliquez avec le bouton droit de la souris et sélectionnez 'Commenter'",
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Cliquez avec le bouton droit de la souris et sélectionnez 'Commenter'",
        "point": 20
      },
      {
        "question": "8. Quelle fonctionnalité de Google Docs permet de créer des graphiques et des diagrammes à partir de données ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Outils de création graphique",
          "Outils de mise en forme",
          "Outils de recherche",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Outils de création graphique.",
        "point": 20
      },
      {
        "question": "9. Comment partager un document Google Docs avec d'autres utilisateurs pour une collaboration en temps réel ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Allez dans 'Modifier' > 'Partager'",
          "Allez dans 'Fichier' > 'Partager'",
          "Allez dans 'Insertion' > 'Partager'",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est  A) Allez dans 'Fichier' > 'Partager'.",
        "point": 20
      },
      {
        "question": "10. Quelle fonctionnalité de Google Docs permet d'utiliser des raccourcis clavier pour accélérer le flux de travail ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Outils de mise en forme",
          "Outils de recherche",
          "Outils de collaboration",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est  'Outils de collaboration'.",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizA">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizE;
