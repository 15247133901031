import React, { useState } from "react";

const QuizA = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 3",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1. Quel est l'autre nom du processeur central ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Le PC",
          "Le PUC",
          "Le CPU",
          "Le CP"
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Le CPU est l'autre nom du processeur. CPU veut dire : Central Processing Unit.",
        "point": 20
      },
      {
        "question": "2. Les RAMs servent à : ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "A stocker des données temporairement",
          "A stocker des données définitivement",
          "Refroidir l'ordinateur",
          "Relier les composants d'un ordinateur",
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Les deux disques dur pour une tour qui existent sont : ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "DDE et SSD",
          "HSD et SED",
          "SSD et HDS",
          "HDD et SSD",
        ],
        "correctAnswer": 3,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le disque dur HDD (Hard Disk Drive) et le SSD (Solid State Drive).",
        "point": 20
      },
      {
        "question": "4. Comment appelle-t-on le dispositif d'entrée qui permet de contrôler le curseur sur l'écran d'un ordinateur ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Souris",
          "Clavier",
          "Ecran tactile",
          "Stylet",
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est la souris.",
        "point": 20
      },
      {
        "question": "5. Quel est l'autre nom de la carte graphique ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "GUP",
          "GPU",
          "PUG",
          "UPG",
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est GPU. Graphics Processing Unit (unité de traitement graphique en français).",
        "point": 20
      },
      {
        "question": "6. Comment appelle-t-on les interfaces que l'on ajoute à l'ordinateur ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Accessoires",
          "USB",
          "Rallonges",
          "Périphériques",
        ],
        "correctAnswer": 3,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Périphériques.",
        "point": 20
      },
      {
        "question": "7. Quel est le principal système d'exploitation utilisé sur la plupart des ordinateurs personnels ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Windows",
          "macOS",
          "Linux",
          "Android",
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Windows. Windows : Environ 80% des ordinateurs personnels. macOS : Environ 15% des ordinateurs personnels. Linux : Environ 2-3% des ordinateurs personnels. Android : Environ 1% des ordinateurs personnels.",
        "point": 20
      },
      {
        "question": "8. Comment appelle-t-on le logiciel qui permet à un utilisateur d'interagir avec un ordinateur et d'exécuter des programmes ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Système d'exploitation",
          "Suite bureautique",
          "Navigateur Web",
          "Antivirus",
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le Système d'exploitation.",
        "point": 20
      },
      {
        "question": "9. Quelle est la fonction principale d'un périphérique de sortie sur un ordinateur ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Entrer des données dans l'ordinateur",
          "Afficher des informations à l'utilisateur",
          "Stocker des données de manière permanente",
          "Contrôler les opérateurs de calcul",
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Afficher des informations à l'utilisateur.",
        "point": 20
      },
      {
        "question": "10. Comment appelle-t-on l'interface graphique qui permet à un utilisateur d'interagir avec un système d'exploitation Windows ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Ecran d'accueil",
          "Panneau de configuration",
          "Tableau de bord",
          "Bureau",
        ],
        "correctAnswer": 3,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Bureau.",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizA">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizA;
