import React from "react";
import { Link } from "react-router-dom";
import bienVieillir from "../media/bienVieillir.png";
import pourBienVieillir from "../media/pourBienVieillir.png";
import infoRetraite from "../media/infoRetraite.png";
import lesPersonnesAgees from "../media/lesPersonnesAgees.png";
import amelie from "../media/amelie.png";
import pleinevie from "../media/pleinevie.png";
import bonjourSenior from "../media/bonjourSenior.png";
import disonsDemain from "../media/disonsDemain.png";
import nosBellesAnnées from "../media/nosBellesAnnées.png";
import elite from "../media/elite.png";
import seniorsService from "../media/seniorsService.png";
import Alphonse from "../media/Alphonse.png";
import emploiSenior from "../media/emploiSenior.png";
import travel from "../media/travel.png";
import seniorVacances from "../media/seniorVacances.png";
import logitude from "../media/logitude.png"
const Seniors = () => {
  return (
    <div className="senior-container">
      <div className="senior-content">
      <img
        className="img1"
        src={bienVieillir}
        alt="représentation en image du magazine Bein Vieillir"
      />
      <Link to="https://www.reponses-bien-vieillir.fr/" rel="noopener noreferrer" target="_blank">
        <h2>Bien Vieillir</h2>
      </Link>
      <h3>NOTRE MISSION</h3>
</div>
      <p>
        Le site tente de balayer l’ensemble des questions qui préoccupent ceux
        qui franchissent le cap des 50 ans, d’un point de vue social, médical,
        financier, juridique autant que psychologique. Nous vous proposons des
        trames de réflexion, avec des pistes et des astuces pour optimiser la
        vie, en bannissant toute injonction hygiéniste péremptoire (« il faut »,
        y’a qu’à »…).
      </p>
      <br />
      <hr />
      <img
        className="img2"
        src={pourBienVieillir}
        alt="représentation en image du site Pour bien Vieillir"
      />
      <Link to="https://www.pourbienvieillir.fr" rel="noopener noreferrer" target="_blank">
        <h2>Pour Bien Vieillir</h2>
      </Link>
      <p>
        Ce magazine est destiné aux seniors, c’est un outil de réflexion et
        d’information pour les personnes âgées qui veulent mieux s'épanouir. Le
        magazine de la jeunesse des seniors, pour les seniors.
      </p>
      <p>Découvrez des articles et des conseils sur mesure !</p>
      <br />
      <hr />
      <img
        className="img3"
        src={infoRetraite}
        alt="représentation en image du site Info Retraite"
      />
      <Link to="https://www.info-retraite.fr/portail-services/" rel="noopener noreferrer" target="_blank">
        <h2>Info Retraite</h2>
      </Link>
      <p>
        Créée par la loi de réforme des retraites du 20 janvier 2014, l’Union
        Retraite est un groupement d'intérêt public (GIP) qui réunit les
        organismes de retraite obligatoire, de base et complémentaire. L’Union
        Retraite est chargée du pilotage stratégique de l'ensemble des projets
        de coordination, de simplification et de mutualisation ayant pour objet
        de rendre plus simple et plus compréhensible le système de retraites
        pour les usagers. Elle continue également la mise en œuvre du droit à
        l’information retraite prévu par les lois de 2003 et 2010 (voir Le droit
        à l’information retraite), assuré jusqu’alors par le GIP Info Retraite.
      </p>
      <br />
      <hr />
      <img
        className="img4"
        src={lesPersonnesAgees}
        alt="représentation en image du site Pour les personnes agées"
      />
      <Link to="https://www.pour-les-personnes-agees.gouv.fr/" rel="noopener noreferrer" target="_blank">
        <h2>Pour Les Personnes Agées</h2>
      </Link>
      <p>Aide à l'autonomie des personnes âgées</p>
      <br />
      <hr />
      <img src={amelie} alt="représentation en image du site amélie.fr" />
      <Link to="http://www.ameli.fr/">
        <h2>Amélie.fr</h2>
      </Link>
      <p>
        Le site ameli.fr est le portail officiel de l'Assurance Maladie en
        France. Il est géré par la Caisse nationale d'assurance maladie (CNAM)
        et offre une gamme de services en ligne pour les assurés sociaux
        français. Sur ce site, les utilisateurs peuvent accéder à des
        informations sur leurs droits en matière de santé, gérer leur dossier
        administratif (comme la demande de carte vitale), suivre leurs
        remboursements de frais médicaux, trouver des professionnels de santé,
        et obtenir des conseils sur la prévention et la santé. C'est une
        ressource importante pour les citoyens français en matière de gestion de
        leur couverture maladie obligatoire.
      </p>
      <br />
      <hr />
      <img src={pleinevie} alt="représentation en image du site pleinevie.fr" />
      <Link to="https://www.pleinevie.fr/" rel="noopener noreferrer" target="_blank">
        <h2>Pleine Vie</h2>
      </Link>
      <p>
        Retraite : tout ce que vous devez savoir pour bien préparer et vivre
        cette nouvelle étape de votre vie. Sur pleinevie.fr, nous vous proposons
        des articles et des conseils pratiques pour vous aider à préparer votre
        retraite, à en tirer le meilleur parti et à profiter pleinement de cette
        nouvelle vie. Retrouvez des informations sur les différents régimes de
        retraite, les démarches à effectuer, les aides financières disponibles,
        la santé, les loisirs, etc. Pleinevie.fr, votre guide pour une retraite
        réussie.
      </p>
      <br />
      <hr />
      <img
        src={bonjourSenior}
        alt="représentation en image du site Bonjour Séniors"
      />
      <Link to="https://www.bonjoursenior.fr/" rel="noopener noreferrer" target="_blank">
        <h2>Bonjour Sénior</h2>
      </Link>
      <p>
        Engagés auprès des seniors et des aidants depuis 2019, Bonjour senior
        propose des solutions pour bien vieillir. Notre plateforme s’appuie sur
        une équipe commerciale, un service de téléconseil ainsi qu’une équipe
        éditoriale pour informer et accompagner les personnes âgées et leurs
        proches, à domicile comme en établissement. Grâce à son expertise du
        maintien à domicile et de l’hébergement spécialisé, Bonjour senior aide
        les seniors à comparer les prix et les solutions afin de réaliser leur
        projet et à gagner en autonomie. Qu’il s’agisse de remplacer une
        baignoire par une douche senior, d’installer un monte-escalier, de
        trouver une aide à domicile, de réaliser un bilan auditif ou encore de
        trouver une résidence senior, plus de 500 partenaires et artisans
        dispatchés sur l’ensemble du territoire sont à même de répondre à vos
        besoins. Actualités, guides pratiques, annuaires, produits et services
        dédiés aux seniors : grâce à Bonjour senior, restez informé sur les
        aides financières, les démarches administratives, le droit des aidants
        et bénéficiez de solutions concrètes pour mieux vieillir !
      </p>
      <br />
      <hr />
      <img
        src={disonsDemain}
        alt="représentation en image du site Disons Demain"
      />
      <Link to="https://www.disonsdemain.fr/" rel="noopener noreferrer" target="_blank">
        <h2>Disons Demain</h2>
      </Link>
      <h3>DisonsDemain : le site de rencontre pour les 50 ans et plus</h3>
      <p>
        Faites des rencontres sérieuses dès aujourd’hui DisonsDemain, c’est le
        site de rencontre sérieux recommandé par les célibataires de 50 ans et
        plus qui pensent que le plus important, c’est ce que l’on partage
        ensemble. En vous inscrivant sur DisonsDemain, vous accédez à un service
        de rencontre en ligne réservé aux célibataires de 50 ans et plus, bien
        décidés à écrire une nouvelle page de leur vie amoureuse. Grâce aux
        profils détaillés et à la recherche par critères, nous vous proposons
        des sélections personnalisées de célibataires qui partagent vos centres
        d’intérêt et votre état d’esprit. Découvrez également l’agenda de nos
        sorties et activités pour rencontrer des célibataires et partager des
        bons moments.
      </p>
      <br />
      <hr />
      <img
        src={nosBellesAnnées}
        alt="représentation en image du site Nos belles années"
      />
      <Link to="https://www.nosbellesannees.fr/" rel="noopener noreferrer" target="_blank">
        <h2>Nos Belles Années</h2>
      </Link>
      <p>
        L'inscription à Nos Belles Années est simple - Inscrivez-vous à la
        plateforme avec votre adresse email, complétez un test de personnalité
        et Lancez-vous dans le jeu de la séduction.
      </p>
      <br />
      <hr />
      <img src={elite} alt="représentation en image du site Elite Rencontre" />
      <Link to="https://celibataire.eliterencontre.fr" rel="noopener noreferrer" target="_blank">
        <h2>Elite Rencontre</h2>
      </Link>
      <p>
        Des célibataires qui vous correspondent EliteRencontre met uniquement en
        relation les célibataires à la recherche d’une relation durable. Le
        profil des célibataires proposés est hautement compatible au vôtre.
      </p>
      <p>
        La rencontre en ligne, après inscription, se fait de manière rapide et
        naturelle. Vous surfez sur le site et faites connaissance avec les
        profils qui vous sont proposés. Depuis chez vous ou sur votre mobile,
        vous faites les choix qui vous correspondent, jusqu'à nouer des liens et
        vous rencontrer
      </p>
      <br />
      <hr />

      <img
        src={seniorsService}
        alt="représentation en image du site Séniors à votre service !"
      />
      <Link to="https://www.seniorsavotreservice.com/" rel="noopener noreferrer" target="_blank">
        <h2>Séniors à votre Service</h2>
      </Link>
      <p>
        Seniors à votre Service met en relation candidats seniors et
        particuliers employeurs ayant besoin de services à domicile : bricolage,
        gardiennage de maisons, aides aux personnes âgées, mamies nounous … Mais
        c’est également un jobboard dédié au recrutement de seniors en
        entreprises. + de 30 rubriques métiers sont proposées ! Faites confiance
        à l’expérience, recrutez un senior actif !
      </p>
      <br />
      <hr />
      <img
        src={Alphonse}
        alt="représentation en image du site Les Talents d'Alphone"
      />
      <Link to="https://lestalentsdalphonse.com/" rel="noopener noreferrer" target="_blank">
        <h2>Les Talents d'Alphone</h2>
      </Link>
      <p>
        Alphonse, c'est la plateforme qui vous aide à comprendre vos droits,
        explorer vos options de départ et dessiner des projets de retraite qui
        vous ressemblent.
      </p>
      <br />
      <hr />
      <img
        src={emploiSenior}
        alt="représentation en image du site Emploi Sénior"
      />
      <Link to="https://www.emploisenior.net/" rel="noopener noreferrer" target="_blank">
        <h2>Emploi Sénior</h2>
      </Link>
      <p>
        Offres d'emploi pour seniors et retraités qui recherchent un complément
        de revenu ou à conserver un lien social en restant dans la vie active
      </p>
      <br />
      <hr />
      <img src={travel} alt="représentation en image du site Travel-age" />
      <Link to="https://www.travel-age.com/" rel="noopener noreferrer" target="_blank">
        <h2>Travel-age</h2>
      </Link>
      <h3>UNE AGENCE DE VOYAGE PAS COMME LES AUTRES</h3>
      <p>
        PARCE QUE LE VOYAGE DOIT ÊTRE ACCESSIBLE À TOUS, TRAVEL AGE ASSOCIE
        TOURISME ET AVANCÉE EN ÂGE, ET S'ADAPTE AUX BESOINS ET AU RYTHME DES
        PERSONNES LES PLUS ÂGÉES TRAVEL AGE propose des séjours touristiques
        accompagnés en France et à l’étranger pour les retraités, certains
        pouvant présenter une mobilité réduite ou une perte d’autonomie légère.
        Vous êtes une femme ou un homme âgé/e, vous souhaitez partir en vacances
        pour vous détendre, vous reposer ou encore découvrir de nouveaux
        horizons…
      </p>
      <br />
      <hr />
      <img
        src={seniorVacances}
        alt="représentation en  image du site seniors-en-vacances"
      />
      <Link to="https://www.ancv.com/seniors-en-vacances-sev" rel="noopener noreferrer" target="_blanck">
        <h2>Séniors en vacances</h2>
      </Link>
      <h3>Mieux comprendre Seniors en vacances</h3>
      <p>
        Le programme Seniors en vacances a pour objectif de contribuer aux
        politiques de prévention et de lutte contre la dépendance des personnes
        âgées.
      </p>
      <br />
      <hr />
      <img
        src={logitude}
        alt="représentation en image du site La Logitude"
      />
      <Link to="https://www.lalogitude.org/liens-utiles/" rel="noopener noreferrer" target="_blank">
        <h2>La Logitude</h2>
        </Link>
        <p>Liste non exhaustive d’associations, collectivités locales et journaux permettant aux seniors de percevoir par exemple une aide à domicile, de s’informer ou simplement de sortir entre seniors ou avec des plus jeunes ! Expos, randos… Cliquez sur les images pour accéder aux sites.</p>
      
    </div>
  );
};

export default Seniors;
