import React from 'react';
import Nav from "../composants/nav";
import { Link, useNavigate, useParams } from "react-router-dom";
import QRcode from "../media/QR code.png";
import logoPaypal from "../media/pngegg.png";

const Don = () => {
    const navigate = useNavigate();
 const { userId } = useParams();
 const handleReturn = () => {
    // Redirection vers la page de profil
 navigate(`/`, { replace: true });
  };
    return (
        <div>
        <Nav />
        <div className='don-container'>
            <div className="header">
         
        </div>
        <div className='don-content'>
            <h1>Don pour la cause!</h1><br />
           <h3>Cher(e) utilisateur(trice),</h3>
<br />
<p>Je suis ravis de vous offrir un accès gratuit à une partie du site dédiée à l'apprentissage de l'informatique pour les débutants. Mon objectif est de fournir des ressources éducatives et des outils pratiques pour aider les personnes débutantes à acquérir des compétences en informatique et à naviguer en toute confiance dans le monde numérique.<br /><br />

Je crois fermement en l'importance de l'accès libre et gratuit à l'éducation et à l'information pour tous. Cependant, maintenir et développer mon site demande des ressources financières, notamment pour l'hébergement, la maintenance, le développement de contenu et l'amélioration des fonctionnalités.<br /><br />

C'est pourquoi je sollicite votre soutien sous forme de dons volontaires. Votre contribution m'aidera à continuer à offrir un accès gratuit au site et à développer de nouveaux contenus et fonctionnalités pour mieux servir la communauté des  apprenants.<br /><br />

Vos dons me permettront de :<br /><br />

. Améliorer et enrichir le contenu éducatif avec des ressources supplémentaires, des cours avancés et des tutoriels interactifs.<br />
. Développer de nouvelles fonctionnalités et outils pour rendre l'apprentissage de l'informatique encore plus accessible et efficace.<br />
. Assurer la pérennité du site en couvrant les coûts d'hébergement, de maintenance et de développement.<br />
Je comprends que tout le monde n'est pas en mesure de faire un don, et c'est tout à fait compréhensible. Le contenu restera toujours accessible gratuitement à tous les utilisateurs, que vous choisissiez de faire un don ou non. <br />
<br />
Si vous êtes en mesure de soutenir ma mission et de contribuer financièrement à mon projet, je vous en serai extrêmement reconnaissant. <br />
<br />
 Chaque petit geste compte et m'aidera à continuer à fournir un accès éducatif à l'informatique pour les apprenants du monde entier. <br /><br />

Merci infiniment pour votre soutien et votre générosité. <br /><br />

Cordialement,<br /><br />

Frédéric.</p>
            </div>
        
        <div className='paypal-content'><h1>Faire un Don</h1>
      <p>Si vous appréciez mon travail, vous pouvez me soutenir en faisant un don :</p>
      <div className='paypal-image'>
      <Link to={"https://www.paypal.com/donate/?hosted_button_id=946WL2DSUZJBQ"}rel="noopener noreferrer" target="_blank">Cliquez sur le logo pour arriver à la page !
        <img className="logopaypal" src={logoPaypal}/></Link>
    Ou scanner le QRCode !<img className='Qrcode' src={QRcode} alt="QrCode de dons pour clic de souris" />
     </div>
     <div className='Retour'><button onClick={handleReturn}>Retour à l'accueil</button></div>
      </div>
  </div>
    
        </div>
    );
    
};
  
export default Don;