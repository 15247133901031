import React, { useState } from "react";
import axios from "axios";
import "../styles/formulaireAvatar.scss";
import man from "../media/man.png";
const FormulaireAvatar = ({ userId, onSuccess }) => {
  const [file, setFile] = useState();
  const [defaultAvatar, setDefaultAvatar] = useState("../media/man.png");
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const formdata = new FormData();
      formdata.append("img", file);

      // Effectuer la requête de téléchargement
      const response = await axios.post(
        `https://www.clicdesouris.fr/uploads/${userId}`,
        formdata
      );

      // Vérifier si le téléchargement a réussi
      if (response.data.Status === "Success") {
        console.log("Réussite pour l'avatar");
        onSuccess(); // Informer le composant parent que le téléchargement a réussi
      } else {
        console.log("Échec pour l'avatar");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container-avatar">
      <div className="content-avatar">
        <h3>Choisir votre photo de profil</h3>
        <input
          className="btn btn-dark"
          type="file"
          id="profilePicture"
          name="profilePicture"
          accept="image/*"
          onChange={handleFile}
        />
        <div className="avatar-preview">
          {file ? (
            <img src={URL.createObjectURL(file)} alt="Avatar Preview" />
          ) : (
            <img src={man} alt="Default Avatar" />
          )}
        </div>
        <button onClick={handleUpload}>OK</button>
      </div>
    </div>
  );
};

export default FormulaireAvatar;
