import React, { useState } from 'react';
import '../styles/fileExplorer.scss';

function App() {
  const [copiedText, setCopiedText] = useState('');
  const [movedText, setMovedText] = useState('');

  const handleCopy = () => {
    const textToCopy = document.getElementById('sourceText').innerText;
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopiedText(textToCopy);
        alert('Text copied successfully!');
      })
      .catch(error => console.error('Error copying text: ', error));
  };

  const handlePaste = () => {
    navigator.clipboard.readText()
      .then(text => {
        setMovedText(text);
        alert('Text pasted successfully!');
      })
      .catch(error => console.error('Error pasting text: ', error));
  };

  return (
    <div className="App">
      <div className="container-file">
        <div className="row">
          <div className="col">
            <h3> Texte Source</h3>
            <div id="sourceText" className="text-block">
              Aprés m'avoir copié, tu vas me coller ! 
            </div>
            <button onClick={handleCopy}>Copie</button>
          </div>
          <div className="col">
            <h3>Destination</h3>
            <div id="destinationText" className="text-block">
              {copiedText}
            </div>
            <button onClick={handlePaste}>Colle</button>
          </div>
          <div className="col">
            <h3>Texte déplacé</h3>
            <div className="text-block">
              {movedText}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
