import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import wifi from "../../../media/wifi.png";
import navweb from "../../../media/navweb.png";
import url from "../../../media/url.png";
import search from "../../../media/search.png";
import multifenetres from "../../../media/multifenetres.png";
import QuizC from "../../../composants/quizC";
const Web = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className="web-container">
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <h1> Comment Accéder au Web </h1> <br />
        <p>
          <h2> Définition d'Internet : </h2>
          <br />
          <p>
            Internet est un réseau mondial de communication qui permet l'accès à
            des informations, des services, et la communication en ligne.
            <br /> <br />
            Naviguer sur le web est une compétence fondamentale qui vous permet
            d'accéder à une variété d'informations en ligne. Voici un guide
            étape par étape pour vous aider à naviguer sur Internet :{" "}
          </p>
          <br /> <br />
          <span className="spam">
            <h3>
              {" "}
              Étape 1 : Allumer votre ordinateur et se connecter à Internet
            </h3>
          </span>{" "}
          <br /> <br />
          Assurez-vous que votre ordinateur est allumé et connecté à Internet.
          Si vous utilisez un ordinateur portable, vérifiez que votre connexion
          Wi-Fi est activée. <br /> <br />
          <img className="wifi" src={wifi} alt="representation du logo wifi" />
          <br /> <br />
          <h3>
            <span className="spam">Étape 2 : Ouvrir un navigateur web</span>
          </h3>
          <br /> <br />
          Un navigateur web est le logiciel qui vous permet d'accéder à
          Internet. Certains des navigateurs les plus couramment utilisés
          incluent Google Chrome, Mozilla Firefox, Microsoft Edge et Safari.
          Double-cliquez sur l'icône du navigateur de votre choix pour l'ouvrir.
          <br /> <br />
          <img
            className="navweb"
            src={navweb}
            alt="representation du navigateur internet google chrome"
          />
          <br /> <br />
          <h3>
            {" "}
            <span className="spam">Étape 3 : Utiliser la barre d'adresse</span>
          </h3>
          <br /> <br />
          Une fois que le navigateur est ouvert, vous verrez une barre d'adresse
          en haut de la fenêtre du navigateur. C'est là que vous pouvez saisir
          l'adresse d'un site web que vous souhaitez visiter. Par exemple, pour
          accéder à Google, tapez "www.google.com" dans la barre d'adresse.
          <br /> <br />
          <img
            className="url"
            src={url}
            alt="reperesentation d'un URL dans google chrome"
          />
          <br /> <br />
          <h3>
            <span className="spam">Étape 4 : Effectuer une recherche</span>
          </h3>
          <br /> <br />
          Si vous ne connaissez pas l'adresse d'un site web spécifique, vous
          pouvez utiliser un moteur de recherche. La plupart des navigateurs ont
          une barre de recherche intégrée directement dans la page d'accueil.
          Saisissez simplement les mots-clés de votre recherche et appuyez sur
          "Entrée". Les résultats de la recherche apparaîtront, et vous pourrez
          choisir le site qui correspond le mieux à vos besoins.
          <br /> <br />
          <img
            className="search"
            src={search}
            alt="representation barre de recherche google chrome"
          />
          <br /> <br />
          <h3>
            <span className="spam">Étape 5 : Naviguer sur un site web</span>
          </h3>
          <br /> <br />
          Une fois sur un site web, vous pouvez naviguer en cliquant sur des
          liens, des boutons ou des onglets. Les liens hypertextes sont souvent
          soulignés ou affichés d'une couleur différente. Pour revenir à la page
          précédente, utilisez le bouton "Retour" de votre navigateur.
          <br /> <br />
          <h3>
            {" "}
            <span className="spam">Étape 6 : Ouvrir plusieurs onglets</span>
          </h3>
          <br /> <br />
          Les navigateurs modernes vous permettent d'ouvrir plusieurs onglets en
          même temps. Cela vous permet de passer facilement entre différentes
          pages web sans avoir à fermer et rouvrir chaque fois le navigateur.
          <br /> <br />
          <img
            className="multifenetres"
            src={multifenetres}
            alt="representation de plusieurs fenetre sur google chrome"
          />
          <br /> <br />
          <h3>
            <span className="spam">Étape 7 : Gérer les signets</span>
          </h3>
          <br /> <br />
          Si vous trouvez un site que vous aimez et que vous souhaitez y revenir
          plus tard, vous pouvez le sauvegarder dans vos signets (favoris). Cela
          facilitera son accès ultérieur sans avoir à saisir l'adresse complète.
          <br /> <br />
          En suivant ces étapes simples, vous serez en mesure de naviguer sur le
          web et d'accéder à une multitude d'informations, de ressources et de
          services en ligne. N'oubliez pas d'être conscient de la sécurité en
          ligne en évitant les sites suspects et en maintenant vos logiciels à
          jour.
          <br /> <br />
        </p>
      </div>
      <QuizC />
    </div>
  );
};

export default Web;
