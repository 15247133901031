import React, { useState, useContext, useEffect, useRef } from "react";
import souris from "../media/logoClicdeSouris.png";
import { Link, useParams } from "react-router-dom";
import youtube from "../media/youtube.png";
import facebook from "../media/facebook3.png";
import discord from "../media/discord.png";

const isConnected = () => {
  const token = localStorage.getItem("token");
  return token !== null;
};

// const isNotConnected = () => {
//   return !isConnected();
// };

const Button = ({ disabled, children, to, title }) => {
  return (
    <button className="btnProfil" disabled={disabled} title={title}>
      {to ? <Link to={to} style={{ textDecoration: 'none'}}>{children}</Link> : children}
    </button>
  );
};

const Nav = ({ scrolled }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  
  // const hasToken = localStorage.getItem("token") !== null;
  const menuRef = useRef(null);
  const handleMenuClick = () => {
    setMenuOpen(prevState => !prevState);
  };

   // Gestion de la fermeture du menu lorsque l'utilisateur clique en dehors de celui-ci
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

 
  return (
    <div className="navigation">
      <div className="loginbar">
        <div className="reseauxSociaux">
          <Link to="https://www.youtube.com/channel/UCnDoUFSDQO9Fy1PJ_sqTlbQ" rel="noopener noreferrer" target="_blanck"><img className="logoyoutube" src={youtube} alt="logo de youtube" /></Link>
      <Link to="https://www.facebook.com/" rel="noopener noreferrer" target="_blanck"><img className="logofacebook" src={facebook} alt="logo de facebook" /></Link>
      <Link to="https://www.discord.com/" rel="noopener noreferrer" target="_blanck"><img className="logodiscord" src={discord} alt="logo de discord" /></Link></div>
       
       
      </div>
{/* {hasToken && ( */}
      <div className={`container-nav ${scrolled ? "scrolled" : ""}`}>
        <div className="logo">
          
          <Link to="/">
            <img
              className="imglogo"
              src={souris}
              alt="logo représentant une souris blanche d'ordinateur"
            />
          </Link>
        </div>
        <nav>
        <button
              className={`burger-menu ${isMenuOpen ? "active" : ""}`}
              onClick={handleMenuClick}
            >
              &#9776;
            </button> 
<div className="content-nav">
          
            
            <ul ref={menuRef} className={`menu ${isMenuOpen ? "show open" : ""}`}>

                <li>
                  <Link to={`/`}>Accueil</Link>
                </li>
                <li>
                  <Link to={`/apropos/`}>A propos</Link>
                </li>
                <li>
                  <Link to={`/service/`}>Services</Link>
                </li>
                <li>
                  <Link to={`/contact/`}>Contact</Link>
                </li>
               
                <li>
                <Link to={`/don/`}>Don</Link>
              </li>
             
              </ul>
               <div className="entrez">
<Link to="/profil" >Entrez !</Link> 
</div>
            
          
          </div>
        </nav>
      </div>

    </div>
  );
};

export default Nav;
