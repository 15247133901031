import React from "react";
import Nav from "../composants/nav";
import { Helmet } from "react-helmet-async";
import Footer from "../composants/footer";
import "../styles/apropos.scss";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
const Apropos = ({isConnected}) => {
  const { userId } = useParams();
  return (
    <>
      <Helmet>
        <title>Clic de souris - A propos</title>
      </Helmet>

      <section className="about-page">
        <div className="header">
          <Nav isConnected={isConnected}/>
        </div>
      
        <div className="container-apropos">
          
            <h2 className="titre">À propos de moi</h2>
            <p className="texte-containerapropos">
              Bonjour je m'appelle Frédéric. Je suis un formateur
              passionné avec une vaste expérience dans l'enseignement des
              compétences informatiques. Mon objectif est d'aider les séniors qui subissent la fracture numérique,
               mais aider aussi toute personne souhaitant améliorer
              ses compétences en informatique à travers des cours personnalisés
              et adaptés à leurs besoins. Je vous propose aussi de faire votre site web personnel ou professionnel. Je me déplace directement chez les
              clients dans un rayon de 10kms autour de Douai.
              <br /><br />
              à bientôt...
            </p>
          </div>
          
      </section>
    </>
  );
};

export default Apropos;
