import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import windows from "../../../media/Windows.png";
import { Helmet } from "react-helmet-async";

const DemarreProg = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/user/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>Démarrer un programme - Clic de souris</title>
      </Helmet>
      <div className="programme-container">
        <div className="container">
        <br />
          <br />
          <br />
          <br />
          <h1>
            {" "}
            Apprendre à démarrer un programme ou une application sur un
            ordinateur Windows.
          </h1>
          <p>
            <br />
            <br />
            <span className="spam">Étape 1. Accéder au Bureau </span> <br />
            Assurez-vous que vous êtes sur le Bureau de Windows. Si vous n'êtes
            pas sûr, regardez l'écran de votre ordinateur. Si vous voyez des
            icônes telles que "Poste de travail" ou "Corbeille", vous êtes sur
            le Bureau.
            <br />
            <br />{" "}
          </p>{" "}
          <img src={windows} alt="fenêtre windows 10" /> <br />
          <p>
            <span className="spam">Etape 2. Trouver l'Icône du Programme</span>{" "}
            <br />
            Localisez l'icône du programme que vous souhaitez démarrer. Les
            icônes peuvent être sur le Bureau, dans la barre des tâches en bas
            de l'écran, ou dans le menu "Démarrer". Sur le Bureau : Si l'icône
            est sur le Bureau, regardez les icônes présentes. Si vous trouvez
            l'icône du programme, passez à l'étape 3. Dans la Barre des Tâches :
            Recherchez la barre des tâches en bas de l'écran. Si l'icône du
            programme est épinglée à la barre des tâches, cliquez dessus pour
            démarrer le programme. Dans le Menu "Démarrer" : Cliquez sur le
            bouton "Démarrer" dans le coin inférieur gauche de l'écran.
            Recherchez le nom du programme dans la liste des applications et
            cliquez dessus pour le démarrer. <br />
            <br />
            <span className="spam">Etape 3. Double-Clic pour Démarrer </span>
            <br />
            Une fois que vous avez trouvé l'icône du programme, effectuez un
            double-clic gauche sur cette icône. Cela ouvrira le programme.{" "}
            <br />
            <br />
            <span className="spam">
              Etape 4. Attendez le Chargement Patientez quelques instants.
            </span>
            <br />
            Selon la puissance de votre ordinateur et la taille du programme,
            cela peut prendre quelques secondes. <br />
            <br />
            <span className="spam">Etape 5. Utilisez le Programme</span> <br />
            Une fois que le programme est ouvert, vous pouvez commencer à
            l'utiliser. Si c'est la première fois que vous utilisez ce
            programme, explorez son interface et suivez les instructions qui
            pourraient apparaître à l'écran. Conseils Additionnels Si vous ne
            trouvez pas l'icône du programme sur le Bureau ni dans la barre des
            tâches, essayez de chercher dans le menu "Démarrer" en utilisant la
            fonction de recherche. Pour épingler une icône à la barre des
            tâches, faites un clic droit sur l'icône du programme et choisissez
            "Épingler à la barre des tâches". Si le programme que vous cherchez
            n'est pas installé sur votre ordinateur, vous devrez peut-être
            l'installer avant de pouvoir le démarrer.
          </p>
        </div>
      </div>
    </>
  );
};

export default DemarreProg;
