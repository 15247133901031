import React, { useState, useEffect } from 'react';

const HangmanGame = () => {
  const [word, setWord] = useState('');
  const [guessedLetters, setGuessedLetters] = useState(new Set());
  const [incorrectGuesses, setIncorrectGuesses] = useState(0);
  const [points, setPoints] = useState(0);

  // Liste de mots possibles
  const wordList = ['BONJOUR', 'ORDINATEUR', 'HEUREUX', 'POSITIF', 'RETRAITE', 'RENCONTRE', 'SALUT', 'SOURIS', 'BALADE', 'GAGNER', 'PRINTEMPS', 'JARDINAGE', 'JOYEUX', 'CROISIERE', 'VACANCES', 'VITAMINE', 'SOLEIL', 'SOUVENIR', 'APERO', 'VOYAGER', 'CAMPING-CAR', 'CADEAU', 'EGLISE', 'TROTINETTE', 'CAMPAGNE', 'POSITIF', 'HARMONIE', 'PLAISIR', 'RADIEUX', 'RAYONNANT', 'LUMIERE', 'JUBILATION', 'DOUCEUR', 'VITALITE', 'ENTHOUSIASME', 'JUVILATION'];

  useEffect(() => {
    // Choix aléatoire d'un mot dans la liste
    const randomIndex = Math.floor(Math.random() * wordList.length);
    setWord(wordList[randomIndex]);
  }, []);

  const handleGuess = (letter) => {
    if (!guessedLetters.has(letter)) {
      setGuessedLetters(new Set(guessedLetters.add(letter)));
      if (!word.includes(letter)) {
        setIncorrectGuesses(incorrectGuesses + 1);
        setPoints(points - 1); // Perte de point pour une mauvaise supposition
      } else {
        setPoints(points + 1); // Gain de point pour une bonne supposition
      }
    }
  };

  const renderWord = () => {
    return word.split('').map((letter, index) => (
      <span key={index}>{guessedLetters.has(letter) ? letter : '_'}</span>
    ));
  };

  const renderHangman = () => {
    if (incorrectGuesses === 0) {
      return null; // Aucune erreur, pas de bonhomme
    }
  
    const parts = [
      incorrectGuesses >= 1 ? <div key="head" className="head"></div> : null,
      incorrectGuesses >= 2 ? <div key="body" className="body"></div> : null,
      incorrectGuesses >= 3 ? <div key="left-arm" className="left-arm"></div> : null,
      incorrectGuesses >= 4 ? <div key="right-arm" className="right-arm"></div> : null,
      incorrectGuesses >= 5 ? <div key="left-leg" className="left-leg"></div> : null,
      incorrectGuesses >= 6 ? <div key="right-leg" className="right-leg"></div> : null,
    ];
  
    return (
      <div className="hangman">
        {parts}
      </div>
    );
  };
  

  const renderAlphabet = () => {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map((letter, index) => (
      <button key={index} disabled={guessedLetters.has(letter)} onClick={() => handleGuess(letter)}>
        {letter}
      </button>
    ));
  };

  const handleRestart = () => {
    // Réinitialisation des états
    setGuessedLetters(new Set());
    setIncorrectGuesses(0);
    setPoints(0);

    // Choix aléatoire d'un nouveau mot
    const randomIndex = Math.floor(Math.random() * wordList.length);
    setWord(wordList[randomIndex]);
  };
  const isWordGuessed = () => {
    return word.split('').every(letter => guessedLetters.has(letter));
  };
  const isGameOver = () => {
    return incorrectGuesses >= 7; // Le jeu est terminé si le joueur a fait 6 mauvaises suppositions
  };
  return (
    <div className='hangmangame-container'>
 {isGameOver() && (
        <div className="message">
          Game Over. Le mot était {word}. Voulez-vous recommencer ?
          <button className="restart" onClick={handleRestart}>Restart</button>
        </div>
      )}
      <h1>Le pendu</h1>
      {!isGameOver() && (
      <div className='hangmangame-content'>
      <div className="word">
      {renderWord()} ({word.length} lettres)
    </div>
   
      
      <div className="alphabet">{renderAlphabet()}</div>
      <div className="points">Points: {points}</div>
      {isWordGuessed() && (
        <div className="message">
          Félicitations, vous avez trouvé le mot !
          
        </div>
      )}
      <button className="restart" onClick={handleRestart}>Restart</button>
      <div className="hangman">{renderHangman()}</div>
    </div>
    )}
    </div>
  );
};

export default HangmanGame;
