import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import mouse from "../../../media/mouse.jpg";
import curseur from "../../../media/curseur.jpg";
import souris from "../../../media/souris.jpg";
import molette from "../../../media/molette.jpg";
import pave from "../../../media/pave.jpg";

import MouseSimulator from "../../../composants/mouseSimulator";
const Souris = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className="souris-container">
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <h1>Le Rôle et le Fonctionnement des Souris d'Ordinateur</h1>
        <br />
        <br />
        <p>
          La souris d'ordinateur est un périphérique d'entrée essentiel qui
          facilite l'interaction entre l'utilisateur et son ordinateur. Elle
          existe sous différentes formes, notamment pour les ordinateurs
          portables et les ordinateurs avec tour. Dans ce cours, nous allons
          explorer le rôle fondamental de la souris ainsi que le fonctionnement
          de ses différents boutons.
        </p>
        <img
          src={mouse}
          alt="souris d'ordinateur avec fil, il y a une main dessus"
        />
        <br />
        <br />
        <h2>Rôle de la Souris :</h2>
        <p>
          La souris est conçue pour permettre aux utilisateurs de naviguer
          facilement à travers l'interface graphique de leur ordinateur. Son
          principal objectif est de fournir une méthode intuitive pour déplacer
          le pointeur à l'écran et sélectionner des éléments. Que vous utilisiez
          un ordinateur portable avec un pavé tactile intégré ou une souris
          externe avec un ordinateur de bureau, le principe de fonctionnement
          reste le même.
        </p>
        <h2>Composants de Base :</h2>
        <p>
          1.Pointeur : Le curseur ou pointeur à l'écran est déplacé en fonction
          des mouvements de la souris. Il permet de sélectionner des icônes, des
          fichiers et d'interagir avec des applications.
        </p>
        <img src={curseur} alt="curseur de souris sur fond noir" />
        <br />
        <br />
        <br />
        <p>
          2.Boutons Principaux : Les souris classiques ont généralement deux
          boutons principaux, le bouton gauche et le bouton droit. Ces boutons
          sont utilisés pour effectuer des actions telles que la sélection,
          l'ouverture de fichiers, et bien plus encore.
        </p>
        <img src={souris} alt="souris design noir sur fond blanc" />
        <br />
        <br />
        <br />
        <p>
          3.Bouton Molette : Certains modèles de souris sont équipés d'une
          molette située entre les deux boutons principaux. Cette molette peut
          être utilisée pour faire défiler verticalement ou horizontalement à
          travers des pages Web, des documents et d'autres contenus.
        </p>
        <img src={molette} alt="souris sans fil design blanche " />
        <br />
        <br />
        <br />
        <h2>Fonctionnement des Boutons :</h2>
        <p>Bouton Gauche :</p>
        <p>
          Clic Simple : Utilisé pour sélectionner des éléments tels que des
          icônes, des fichiers ou des options dans un menu.
        </p>

        <p>
          Double-Clic : Souvent utilisé pour ouvrir des fichiers ou des
          dossiers.
        </p>
        <br />
        <br />

        <p>Bouton Droit : </p>
        <p>
          {" "}
          Clic Droit Simple : Ouvre un menu contextuel avec des options
          spécifiques à l'élément sélectionné.
        </p>

        <br />
        <p>Molette :</p>
        <p>
          Rotation vers le Haut/Bas : Fait défiler le contenu verticalement, par
          exemple, dans une page Web ou un document. Clic Molette : Souvent
          utilisé pour ouvrir des liens dans un nouvel onglet.
        </p>
        <h2>Souris d'Ordinateur Portable :</h2>
        <p>
          Les ordinateurs portables intègrent souvent un pavé tactile en lieu et
          place d'une souris externe. Le pavé tactile peut être utilisé pour
          déplacer le pointeur en glissant le doigt sur sa surface. Il peut
          également avoir des zones dédiées pour simuler les clics gauche et
          droit, ainsi qu'une fonction de pincement pour effectuer un zoom.
        </p>
        <img src={pave} alt="pavé tactile ordinateur portable" />
        <br />
        <br />
        <h2>Conclusion :</h2>
        <p>
          En résumé, la souris d'ordinateur est un outil central pour interagir
          avec les ordinateurs. La diversité des modèles et des fonctionnalités,
          du traditionnel modèle à deux boutons aux souris ergonomiques
          avancées, permet aux utilisateurs de personnaliser leur expérience.
          Comprendre le fonctionnement des boutons et de la molette vous
          permettra d'exploiter pleinement le potentiel de cet outil.
        </p>
      </div>
      <MouseSimulator/>
    </div>
  );
};

export default Souris;
