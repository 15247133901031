import React, { useState } from 'react';

const TicTacToe = () => {
  const emptyBoard = Array(9).fill(null);
  const [board, setBoard] = useState(emptyBoard);
  const [xIsNext, setXIsNext] = useState(true);
  const [winner, setWinner] = useState(null);

  const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return squares[a];
      }
    }
    return null;
  };

  const handleClick = (index) => {
    if (winner || board[index]) return;

    const newBoard = board.slice();
    newBoard[index] = xIsNext ? 'X' : 'O';
    setBoard(newBoard);
    setXIsNext(!xIsNext);
    setWinner(calculateWinner(newBoard));
  };

  const handleRestart = () => {
    setBoard(emptyBoard);
    setXIsNext(true);
    setWinner(null);
  };

  const renderSquare = (index, winnerline) => {
    const isWinnerSquare = winnerLine && winnerLine.includes(index);
    return (
      <button className={`square ${board[index] === 'O' ?  'circle' : board[index] === 'X' ? 'cross' : ''}`} onClick={() => handleClick(index)}>
        {board[index]}
      </button>
    );
  };

  const getStatus = () => {
    if (winner) {
      return `Winner: ${winner}`;
    } else if (board.every((square) => square !== null)) {
      return 'Draw!';
    } else {
      return `Next player: ${xIsNext ? 'X' : 'O'}`;
    }
  };
  const getWinnerLine = () => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return { winner: board[a], line: lines[i] };
      }
    }
    return null;
  };

  const winnerInfo = getWinnerLine();
  const winnerLine = winnerInfo ? winnerInfo.line : null;
  return (
    <div className='ticTac-container'>
      <h1>Tic-Tac-Toe</h1>
      <div className="status">{getStatus()}</div>
      <div className="game-board">
       
      <div className="board-row">
          {renderSquare(0, winnerLine)}
          {renderSquare(1, winnerLine)}
          {renderSquare(2, winnerLine)}
        </div>
        <div className="board-row">
          {renderSquare(3, winnerLine)}
          {renderSquare(4, winnerLine)}
          {renderSquare(5, winnerLine)}
        </div>
        <div className="board-row">
          {renderSquare(6, winnerLine)}
          {renderSquare(7, winnerLine)}
          {renderSquare(8, winnerLine)}
        </div>
        
      </div>
      <button onClick={handleRestart}>Restart</button>
    </div>
  );
};

export default TicTacToe;
