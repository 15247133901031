import React, { useState } from "react";

const QuizC = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 1",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1. Où est généralement située la barre des tâches dans Windows ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En haut de l'écran",
          "À gauche de l'écran",
          "En bas de l'écran",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est en bas de l'écran.",
        "point": 20
      },
      {
        "question": "2. Qu'est-ce que le bouton Démarrer sur la barre des tâches permet d'accéder ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Aux paramètres du système",
          "À une liste des programmes installés",
          "Au menu Démarrer",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse était Au menu démarrer." ,
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Comment pouvez-vous épingler un programme à la barre des tâches ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En cliquant dessus avec le bouton droit de la souris et en sélectionnant Épingler à la barre des tâches",
          "En double-cliquant sur l'icône du programme",
          "En le glissant-déposant sur la barre des tâches",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est En cliquant dessus avec le bouton droit de la souris et en sélectionnant Épingler à la barre des tâches.",
        "point": 20
      },
      {
        "question": "4. Quelle fonctionnalité de la barre des tâches vous permet de rechercher des fichiers et des applications ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Barre de navigation",
          "Barre de recherche",
          "Centre de notifications",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est La barre de recherche.",
        "point": 20
      },
      {
        "question": "5. Comment pouvez-vous personnaliser la taille des icônes sur la barre des tâches ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En faisant un clic droit sur un espace vide de la barre des tâches et en sélectionnant _Propriétés-, puis en modifiant la taille des icônes dans les paramètres ",
          "En appuyant simultanément sur les touches Ctrl + Alt + Suppr",
          " En redimensionnant la barre des tâches avec la souris",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est En faisant un clic droit sur un espace vide de la barre des tâches et en sélectionnant _Propriétés-, puis en modifiant la taille des icônes dans les paramètres.",
        "point": 20
      },
      {
        "question": "6. Quelle option permet de masquer automatiquement la barre des tâches lorsque vous ne l'utilisez pas ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Masquer automatiquement la barre des tâches",
          "Réduire la barre des tâches",
          "Cacher la barre des tâches",
         
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Masquer automatiquement la barre des tâche.",
        "point": 20
      },
      {
        "question": "7. Comment accédez-vous au Centre de Notifications à partir de la barre des tâches ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En double-cliquant sur l'icône de notification", 
          "En faisant un clic droit sur l'icône de notification et en sélectionnant -Centre de Notifications-",
          "En cliquant sur l'icône de notification à droite de la barre des tâches",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est cliquant sur l'icône de notification à droite de la barre des tâches.",
        "point": 20
      },
      {
        "question": "8. Que pouvez-vous faire en cliquant sur l'icône de l'heure dans la barre des tâches ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Ouvrir le menu Démarrer",
          "Accéder au Centre de Notifications",
          "Afficher le calendrier et les paramètres de l'heure",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Afficher le calendrier et les paramètres de l'heure.",
        "point": 20
      },
      {
        "question": "9. Quelle est la fonction de la zone de notification de la barre des tâches ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Afficher les notifications système et les paramètres rapides",
          "Afficher les programmes ouverts",
          "Afficher les raccourcis vers les fichiers et les dossiers fréquemment utilisés",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Afficher les notifications système et les paramètres rapides.",
        "point": 20
      },
      {
        "question": "10. Comment déplacer la barre des tâches vers un autre emplacement sur l'écran ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
         "En maintenant la touche Shift enfoncée tout en cliquant sur la barre des tâches et en la faisant glisser vers l'emplacement souhaité",
         "En faisant un clic droit sur un espace vide de la barre des tâches, en sélectionnant -Propriétés-, puis en modifiant l'option -Position de la barre des tâches- ",
         "En utilisant la combinaison de touches Windows + Flèche vers le haut / bas / gauche / droite",
          
         
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est En faisant un clic droit sur un espace vide de la barre des tâches, en sélectionnant -Propriétés-, puis en modifiant l'option -Position de la barre des tâches- .",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizC">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizC;
