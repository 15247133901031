import React, { useState } from "react";
import { Link } from "react-router-dom";
import Nav from "../composants/nav";
import Footer from "../composants/footer";

const RGPD = () => {
  const [accepterRgpd, setAccepterRgpd] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!accepterRgpd) {
      alert("Veuillez accepter les conditions RGPD pour continuer.");
      return;
    }

    // Effectuer l'inscription ou rediriger vers la page de profil
    // Exemple : history.push('/profil');
    window.location.href = '/${userId}';
  };

  return (
    <>
    <div className="header">
          <Nav />
        </div>
    <div className="rgpd-container">
      
      <div className="rgpd-content">
        <div className="text-container">
        <h1>Politique de confidentialité</h1>

        <h2>Protection des données personnelles</h2>
        <p>
          Clic de Souris est attentive à la protection des données
          personnelles et au respect de la vie privée. La présente politique de
          protection des données personnelles vous informe des traitements mis
          en place par Clic de Souris pour le présent site. Clic de Souris
          est responsable de traitement, au sens de la réglementation relative
          aux données à caractère personnel, et notamment du Règlement (UE)
          2016/679 relatif à la protection des personnes physiques à l’égard du
          traitement des données à caractère personnel et à la libre circulation
          de ces données (ci-après le « RGPD »).
          <h2>
            Quand et pourquoi recueillons-nous des informations à votre sujet ?
          </h2>
          Nous recevons des informations de votre part pour permettre leur bonne
          transmission aux destinataires que vous voulez rencontrer sur notre
          plateforme. Ces informations permettent à la fois de vérifier
          l'authenticité des données transmises et d'assurer leur bon aiguillage
          sur notre logiciel. Certains éléments d'information sont obligatoires
          pour accéder aux services. Le caractère obligatoire de ces éléments
          est précisé dans les formulaires pour chaque question, par un
          astérisque ou par une mention "obligatoire".
          <h2>Quelles informations recueillons-nous ?</h2>
          Les données personnelles que nous recueillons peuvent inclure votre
          nom, votre adresse électronique, des images présentant votre profil,
           votre ville et votre région, votre utilisation des services que nous proposons.
            Certaines informations et certains commentaires ou contenus (par exemple,
          photographies, description de profil) que vous
          fournissez de façon optionnelle peuvent, sous votre responsabilité et
          à votre initiative, révéler des données sensibles comme votre origine
          ethnique, votre nationalité ou votre religion. En fournissant ces
          informations optionnelles, vous assumez l'entière responsabilité de la
          diffusion de ces informations sensibles sur notre plateforme.
          <h2>Comment traitons-nous ces données ?</h2>
         <p> Nous utilisons vos données pour les finalités suivantes :</p>
          <p>La création, la gestion et le suivi de votre compte sur notre site.</p>
          <p> Base légale : exécution d’un contrat ou de mesures précontractuelles.</p> 
          <p>La gestion de
          vos demandes de contact ou d’information sur nos services.</p>
          <p> Base légale : exécution d’un contrat ou de mesures précontractuelles ;</p>
          <p> votre consentement lorsque celui-ci est requis. Assurer le bon
          fonctionnement, la sécurité et l’amélioration de notre site internet,
          de ses services et de ses fonctionnalités ; Elaborer des statistiques
          commerciales.</p>
          <p> Base légale : notre intérêt légitime à garantir la
          qualité de notre site internet ;</p>
          <p> votre consentement lorsque celui-ci
          est requis. Lutter contre la fraude et respecter nos obligations
          légales.</p> 
          <p>Base légale : obligations légales Les données sont transmises
          à notre plateforme de façon sécurisée grâce au procédé de cryptage
          https.</p>
         <p></p>  Nous faisons appel à diverses sociétés qui permettent de
          fournir nos services (hébergeur web, service d'envoi de mail,
          terminaux de paiement en ligne entre autres) qui peuvent recevoir,
          accueillir et traiter ces données. Selon leur sensibilité, différents
          procédés tels que le cryptage peuvent être appliqués aux données pour
          les protéger afin qu'elles ne soient pas accessibles ou lisibles. Par
          exemple, votre mot de passe est crypté à chaque utilisation, ce qui
          vous permet d'être seul à connaitre la combinaison de caractères que
          vous avez choisie. Pour prévenir la fraude et les impayés, tout
          incident, déclaration fausse ou irrégulière, pourra faire l'objet d'un
          traitement spécifique destiné à empêcher une utilisation frauduleuse
          de nos services.
          <h2>Destinataires et transfert de vos données personnelles</h2>
          Les données que nous collectons sont accessibles à nos prestataires de
          services, agissant en qualité de sous-traitants, afin de vous
          permettre de bénéficier de nos services et d’assurer les finalités
          visées ci-dessus. Nous pouvons être amenés à communiquer vos données
          personnelles à des sous-traitants situés hors de l’Union Européenne.
          En cas de transfert de ce type, nous garantissons que celui-ci est
          effectué : vers un pays assurant un niveau de protection adéquat,
          c’est-à-dire un niveau de protection équivalent à ce que le RGPD exige
          ; ou dans le cadre de clauses contractuelles types.
          <h2>Durées de conservation de vos données personnelles</h2>
          Vos données sont conservées sous une forme permettant votre
          identification uniquement pendant le temps nécessaire à
          l’accomplissement des finalités poursuivies décrites ci-dessus.
          Cependant, le traitement des données est possible pour fournir la
          preuve d’un droit ou d’un contrat. Ces données peuvent également être
          conservées dans l’objectif de respecter une obligation légale ou
          gardées dans des fichiers conformément aux règlements et lois
          applicables. Par exception : les données sont conservées pendant une
          durée de 3 ans à compter de la fin de la relation commerciale ou de
          notre dernier contact avec vous ; si vous avez effectué une demande
          d’exercice de l’un de vos droits sur vos données personnelles : vos
          données seront conservées pendant une durée de 1 an après votre
          demande ; Les données personnelles issues des cookies assurant
          l'analyse de la navigation sur notre site internet sont conservées
          pendant une durée de 6 mois ; Les données personnelles issues des
          cookies nécessaires au fonctionnement et à la mesure d’audience de
          notre site internet sont conservées 25 mois.
          <h2>Vos droits</h2>
          Conformément à la réglementation applicable, vous disposez des droits
          suivants :
          <h4> Droit d’accès :</h4>
          <br /> Vous pouvez accéder à l’ensemble des
          informations vous concernant, connaître l’origine des informations
          vous concernant, obtenir la copie de vos données (les frais n’excédant
          pas le coût de reproduction peuvent vous être demandés), exiger que
          vos données soient selon les cas, rectifiées, complétées, mises à jour
          ou supprimées. <br /> 
          <h4>Droit de rectification : </h4><br />
           Ce droit vous permet d’obtenir
          la rectification des données vous concernant lorsqu’elles sont
          inexactes, ou que les données incomplètes vous concernant soient
          complétées.
          <h4>Droit d’effacement :</h4> <br />
           Ce droit vous permet d’obtenir
          l’effacement des données vous concernant, sous certaines conditions
          telles que prévues par l’article 17 du RGPD.
          <h4>Droit à la limitation du
          traitement :</h4> <br />
           Vous avez le droit d’obtenir la limitation du traitement
          lorsque l’une des conditions prévues à l’article 18 du RGPD
          s’applique.
          <h4>Droit d’opposition :</h4> <br />
           Vous pouvez vous opposer à tout
          moment à l’utilisation de certaines de vos données pour des raisons
          tenant à votre situation particulière.
          <h4>Droit à la portabilité de vos
          données :</h4><br />
            Vous avez le droit de récupérer une copie des données
          personnelles vous concernant ou d’obtenir leur transfert vers un autre
          organisme.
          <h4>Droit d’organiser le sort de vos données personnelles après
          la mort :</h4><br />
            Vous avez le droit de nous indiquer vos directives relatives
          à la conservation, à l’effacement et à la communication de vos données
          après votre décès, notamment le droit de choisir que nous
          communiquions (ou non) vos données à un tiers que vous aurez
          préalablement désigné. En cas de décès et à défaut d’instructions de
          votre part, nous nous engageons à détruire vos données, sauf si leur
          conservation s’avère nécessaire à des fins probatoires ou pour
          répondre à une obligation légale.
          <h4> Droit d’introduire une réclamation
          auprès de la Commission Nationale Informatique et Libertés (CNIL) :</h4>
          Vous pouvez saisir la CNIL si vous estimez que vos droits de ne sont
          pas respectés. Si vous souhaitez exercer l’un de vos droits, ou si
          vous avez une question relative à la présente politique vous contacter
          notre délégué à la protection des données à caractère personnel par
          e-mail à l’adresse : clicdesouris@gmail.com ou par courrier à Clic de Souris,
          91 Cité Grammont, 59128 Flers-en-escrebieux, en indiquant
          vos coordonnées (nom, prénom, adresse), et un motif légitime lorsque
          cela est exigé par la loi (notamment si vous souhaitez exercer votre
          droit d’opposition au traitement).
          <h2>Transparence et liberté de choix</h2>
          Pour vous permettre de choisir les services et traitements qui vous
          conviennent (lorsque cela est possible), nous vous prévenons
          explicitement des traitements dont ils peuvent faire l'objet avant de
          poursuivre votre utilisation des services. Nous affichons notamment
          des bandeaux d'alerte, des boutons ou coche pour que vous donniez
          explicitement votre consentement. En validant ces formulaires ou en
          continuant à utiliser nos services, vous consentez activement à
          transmettre des informations utilisées dans le cadre de traitements
          automatisés. Vous pouvez à tout moment modifier vos préférences
          d'utilisation de nos services via les formulaires spécifiques. Pour
          les données nécessitant une authentification renforcée, nous pouvons
          vous demander de fournir des documents justifiant leur modification
          afin de prévenir la modification abusive ou frauduleuse des données
          authentifiées.
          <h2>Suppression des données personnelles</h2>
          Vous pouvez à tout moment supprimer vos données personnelles : <br />  Depuis
          votre compte : rendez-vous sur votre espace personnel et cliquez sur
          "Clôturer le compte" en bas à gauche de votre écran. <br /> En utilisant les formulaires de contact du site. En envoyant un mail à
          clicdesouris@gmail.com. <br />
           Chaque demande de suppression des données sera
          traitée dans les secondes qui suivent sa réception. Toutes les données
          seront supprimées de nos serveurs et irrécupérables.
          <h2>Cookies</h2>
          Des informations spécifiques peuvent être automatiquement collectées,
          avec votre accord préalable lorsqu’il est requis, lors de l'usage
          normal de notre site ou par l'utilisation de cookies (un cookie est un
          petit fichier, souvent anonyme, contenant des données, notamment un
          identifiant unique, transmis par le serveur d'un site Internet au
          navigateur de l’internaute et stocké sur le disque dur de son
          ordinateur). Certains cookies nécessaires au bon fonctionnement du
          site sont obligatoires et ne peuvent être désactivés. Les données
          collectées sont notamment : vos adresses IP, données de connexion,
          types et versions de navigateurs internet utilisés, types et versions
          des plugins de votre navigateur, systèmes et plateformes
          d’exploitation, données concernant votre parcours de navigation sur le
          site, notamment votre parcours sur les différentes pages de notre site
          internet, les contenus que vous consultez, les termes de recherches
          utilisés, la durée de consultation de certaines pages, les
          interactions avec la page. Les cookies suivants sont utilisés : Les
          cookies de fonctionnalités et les cookies nécessaires au
          fonctionnement de notre site. Ces cookies sont exclusivement déposés
          par Clic de souris et sont nécessaires au bon fonctionnement de notre site
          ainsi que pour accéder aux fonctionnalités de notre site destinées à
          assurer votre confort de navigation. Ils peuvent encore permettre
          l’adaptation de l’affichage de notre site aux préférences de votre
          terminal et des logiciels de visualisation installés sur votre
          terminal. Les cookies analytiques Notre site comporte des cookies
          analytiques dont la finalité est de connaître sa fréquentation, son
          utilisation et ses performances, et d'en améliorer son fonctionnement
          et son ergonomie (par exemple, les pages le plus souvent consultées,
          les recherches des visiteurs, etc…). Notre site utilise le cookie
          Google Analytics, outil d'analyse statistique de sites internet. Vous
          pouvez, à tout moment, refuser le suivi de votre navigation sur notre
          site. <br />
          <h4> Les cookies de réseaux sociaux </h4>
          Notre site utilise le cookie
          Facebook Connect afin de vous permettre de vous identifier à l’aide de
          votre compte Facebook.
          <h4> Les cookies publicitaires </h4>
          Notre site utilise le
          cookie Google Adwords/Google Ads. Ce cookie permet d’effectuer le
          suivi des conversions générées par notre annonce, et ajoute un cookie
          à l’ordinateur de l’internaute lorsque celui-ci clique sur notre
          annonce. Les cookies de notifications web push Nous utilisons un
          service d'envoi de notifications web pour vous tenir informé des
          nouveautés sur notre blog. Pour profiter de ce service, vous devez
          cliquer sur une demande d'autorisation gérée par votre navigateur lors
          de votre visite sur notre site. Les données de navigation que nous
          pourrions collecter et analyser dans le cadre de ce service sont
          anonymisées, conservées sur les serveurs de WonderPush pour une durée
          maximale de 90 jours et ne sont jamais partagées avec des tiers. Vous
          pouvez vous désabonner à tout moment pour stopper l'envoi des
          notifications web push. Vous pouvez à tout moment configurer votre
          navigateur de manière à ce que des cookies soient enregistrés dans
          votre terminal (opt-in) ou, au contraire, qu'ils soient rejetés
          (opt-out). Certains navigateurs vous permettent de définir des règles
          pour gérer les cookies site par site. Pour la gestion des cookies, la
          configuration de chaque navigateur est différente. Elle est décrite
          dans le menu d'aide de votre navigateur, qui vous permettra de savoir
          de quelle manière modifier vos souhaits : Internet Explorer Safari
          Chrome Firefox Pour désactiver le ciblage publicitaire de vos
          appareils mobiles, vous pouvez suivre les procédures suivantes : sur
          iOS, rendez-vous sur les réglages de confidentialité, accédez à
          l’option « publicité » et activez le "suivi publicitaire limité" ; sur
          Android, rendez-vous dans l’application "paramètres Google", activez
          l’option « désactiver les annonces par centre d’intérêt » et
          "réinitialiser l’identifiant publicitaire".
          <h2>Modification</h2>
          La présente politique de confidentialité peut être amenée à changer.
          Toute diminution de vos droits dans le cadre de cette politique ne
          saurait être appliquée sans votre consentement exprès. Toute
          modification des règles de confidentialité sera indiquée sur cette
          page et dans le cas de modifications notoires, nous publierons un
          avertissement et pourrons vous en notifier par email au besoin.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
      {/* Autres champs du formulaire */}
      
      
      <input
        type="checkbox"
        id="accepterRgpd"
        checked={accepterRgpd}
        onChange={() => setAccepterRgpd(!accepterRgpd)}
        required
      />
      <label htmlFor="accepterRgpd">J'ai lu et accepte les conditions RGPD</label>
      <button type="submit">Continuer</button>
    </form>
      </div>
    </div></>
  );
};

export default RGPD;
