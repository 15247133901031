import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import Nav from "../composants/nav";
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('success');
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      setAlertVariant('danger');
      setAlertMessage('Veuillez vérifier le reCAPTCHA.');
      setShowAlert(true);
      return;
    }

    try {
        const response = await axios.post('https://clicdesouris.fr/send', 
          {
            ...formData,
            recaptchaToken
          }, 
          {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status === 200 && response.data.success) {
            setAlertVariant('success');
            setAlertMessage('Message envoyé avec succès!');
            setShowAlert(true);
            e.target.reset();
        } else {
            setAlertVariant('danger');
            setAlertMessage('Une erreur est survenue, veuillez réessayer.');
            setShowAlert(true);
        }
    } catch (error) {
        console.error('Erreur lors de l\'envoi du message:', error);
        setAlertVariant('danger');
        setAlertMessage('Une erreur est survenue, veuillez réessayer.');
        setShowAlert(true);
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <>
    <Nav/>
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <h2>Contactez-moi</h2>
          {showAlert && <Alert variant={alertVariant}>{alertMessage}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>Nom</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Entrez votre nom"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Entrez votre email"
                required
              />
            </Form.Group>

            <Form.Group controlId="formMessage" className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                value={formData.message}
                onChange={handleChange}
                rows={5}
                placeholder="Entrez votre message"
                required
              />
            </Form.Group>

            <ReCAPTCHA
              sitekey="6Lf8de0pAAAAAFd1lRL7SGMcH6YnEVbHfyCiW01q"
              onChange={handleRecaptchaChange}
            />

            <Button variant="primary" type="submit">
              Envoyer
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default Contact;
