import React from "react";
import "../styles/tarifs.scss";
import { Helmet } from "react-helmet-async";
import Nav from "../composants/nav";
import Footer from "../composants/footer";
const Tarifs = ({ isConnected }) => {
  const tarifs = [
    { duree: "1h30", tarif: 30 },
    { duree: "3h00", tarif: 50 },

    // Ajoutez d'autres options de durée et tarifs ici
  ];

  return (
    <>
      <Helmet>
        <title>Tarifs - Clic de souris</title>
      </Helmet>
      <section className="tarif-page">
        <div className="header">
          <Nav isConnected={isConnected} />
        </div>
        <div className="container-tarif">
          <div className="content-tarif">
            <h2>Tarifs pour le déplacement à domicile</h2>
            <p>Voici les tarifs en fonction de la durée des cours :</p>
            <ul>
              {tarifs.map((option) => (
                <li key={option.duree}>
                  <strong>{option.duree} :</strong> {option.tarif} euros
                </li>
              ))}
            </ul>
            {/* <h2>Tarifs pour l'inscription au site</h2>
            <p>Voici les tarifs pour l'abonnement au site selon la durée</p>
            <p>Abonnement par mois -100% du contenu en illimité- 5 Euros.</p>
            <p>
              Abonnement par an 50 euros au lieu de 60 euros. Le montant est
              réduit de 10€.
            </p>*/}
            <p>
              * Les tarifs peuvent être modulés en fonction du type de
              réservation et le nombre de personnes : pour une Association,
              MAPAD ect...
            </p> 
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Tarifs;
