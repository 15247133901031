import React, { useState } from 'react';


const Sudoku = () => {
    const initialGrid = [
        [5, 3, 0, 0, 7, 0, 0, 0, 0],
        [6, 0, 0, 1, 9, 5, 0, 0, 0],
        [0, 9, 8, 0, 0, 0, 0, 6, 0],
        [8, 0, 0, 0, 6, 0, 0, 0, 3],
        [4, 0, 0, 8, 0, 3, 0, 0, 1],
        [7, 0, 0, 0, 2, 0, 0, 0, 6],
        [0, 6, 0, 0, 0, 0, 2, 8, 0],
        [0, 0, 0, 4, 1, 9, 0, 0, 5],
        [0, 0, 0, 0, 8, 0, 0, 7, 9]
    ];

    const [grid, setGrid] = useState(initialGrid);
    const [selectedCell, setSelectedCell] = useState({ row: null, col: null });

    const handleCellClick = (row, col) => {
        setSelectedCell({ row, col });
    };

    const handleNumberClick = (num) => {
        if (selectedCell.row !== null && selectedCell.col !== null) {
            const newGrid = [...grid];
            newGrid[selectedCell.row][selectedCell.col] = num;
            setGrid(newGrid);
        }
    };

    const isCellEditable = (row, col) => {
        if (selectedCell && selectedCell.row !== null && selectedCell.col !== null) {
            return initialGrid[row][col] === 0;
        }
        return false;
    };

    const isInputValid = (num) => {
        return !isNaN(num) && num >= 1 && num <= 9;
    };

    const renderGrid = () => {
        return (
             <div className='sudoku-container'>
            <div className="sudoku-grid">
              
                {grid.map((row, rowIndex) => (
                    <div key={rowIndex} className="sudoku-row">
                        {row.map((cell, colIndex) => (
                            <div
                                key={colIndex}
                                className={`sudoku-cell ${selectedCell.row === rowIndex && selectedCell.col === colIndex ? 'selected' : ''}`}
                                onClick={() => handleCellClick(rowIndex, colIndex)}
                            >
                                {cell === 0 ? '' : cell}
                            </div>
                        ))}
                    </div>
                ))}
            </div></div>
        );
    };

    const renderNumberButtons = () => {
        return (
            <div className="number-buttons">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
                    <button
                        key={num}
                        onClick={() => handleNumberClick(num)}
                        disabled={!isInputValid(num) || !isCellEditable(selectedCell.row, selectedCell.col)}
                    >
                        {num}
                    </button>
                ))}
            </div>
        );
    };

    return (
        <div className="sudoku">
            <h1>Sudoku</h1>
            <div className="sudoku-container">
                {renderGrid()}
                {renderNumberButtons()}
            </div>
        </div>
    );
};

export default Sudoku;
