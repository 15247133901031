//*** Mentionner Graphiste icone enveloppe //<a href="https://www.flaticon.com/fr/icones-gratuites/courrier" title="courrier icônes">Courrier icônes créées par Flat Icons - Flaticon</a>
import React from "react";
import { Link } from "react-router-dom";
import "../styles/footer.scss";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-nav">
        <ul className="footer-list">
          <li>
            <Link to="/Apropos">A propos</Link>
          </li>
          <li>
            <Link to="/Tarifs">Tarifs</Link>
          </li>

          <li>
            <Link to="/Contact">Contact</Link>
          </li>
          <li>
            <Link to="/rgpd">Politique de confidentialité</Link>
          </li>
          {/* <li>
            <Link to="/mentions">Mentions Légales</Link>
          </li> */}
        </ul>
      </div>
      <footer className="site-footer">
        <div className="site-info">
          <p>Clic de souris &copy; 2024</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
