import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet-async';

const Dictionnaire = () => {
    const { userId } = useParams();
  const [data, setData] = useState({});
   const [isSubscribed, setIsSubscribed] = useState (false);
   useEffect(() => {
      // Vérifier l'état d'abonnement de l'utilisateur
      const checkSubscription = async () => {
        try {
          const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
          setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
          console.error('Erreur lors de la vérification de l\'abonnement :', error);
        }
      };
  
      checkSubscription();
    }, [userId]);
    
    useEffect(() => {
      axios
        .get(`https://www.clicdesouris.fr/api/users/${userId}`)
        .then((response) => {
          // Traitement des données de réponse ici
          console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          // Gestion des erreurs ici
          console.error("Erreur lors de la requête :", error);
        });
    }, [userId]);
    return (
        <>
        <Helmet>
          <title>Dictionnaire - Clic de souris</title>
        </Helmet>
        <div  className="dictionnaire-container">
            <div className="dictionnaire-content">
            <h1>Dictionnaire Du Langage Informatique</h1>
            
            <p><strong>Algorithme :</strong> Une séquence d'instructions structurées qui décrivent comment effectuer une tâche ou résoudre un problème.</p>
            <p><strong>Analyse des vulnérabilités :</strong> Le processus d'identification, d'évaluation et de priorisation des vulnérabilités dans les systèmes informatiques, les réseaux et les applications, afin de mettre en œuvre des correctifs et des mesures de sécurité appropriés.</p>
            <p><strong>Analyse de données :</strong> Le processus d'examen, de nettoyage, de transformation et de modélisation de données dans le but de découvrir des informations utiles, d'atteindre des objectifs et de prendre des décisions éclairées.</p>
            <p><strong>Analyse de texte :</strong> Le processus d'extraction d'informations significatives à partir de documents texte, y compris la classification, l'extraction d'entités, la détection de sentiments, la génération de résumés, etc.</p>
            <p><strong>Analyse prédictive : </strong>L'utilisation de techniques statistiques et d'apprentissage automatique pour analyser des données historiques et identifier des tendances afin de prédire des événements futurs.</p>
            <p><strong>Analytique des réseaux sociaux : </strong>L'utilisation de techniques d'analyse de données pour examiner les interactions et les comportements des utilisateurs sur les plateformes de médias sociaux, afin de comprendre les tendances, les sentiments et les relations sociales.</p>
            <p><strong>Architecture sans serveur (Serverless) :</strong> Un modèle de développement d'applications dans lequel le fournisseur de services cloud gère l'infrastructure sous-jacente, permettant aux développeurs de se concentrer sur l'écriture de code sans se soucier de la gestion des serveurs.</p>
            
            <p><strong>API (Interface de programmation d'application) : </strong>Un ensemble de règles et de protocoles qui permettent à différents logiciels d'interagir entre eux.</p>
            <p><strong>Architecture informatique : </strong>La conception et la structure des systèmes informatiques, y compris les composants matériels et logiciels, leur organisation et leur interaction.</p>
            <p><strong>Backdoor : </strong>Une méthode secrète ou non documentée permettant à un utilisateur de contourner les procédures de sécurité normales pour accéder à un système, souvent utilisée à des fins malveillantes par les pirates informatiques.</p>
            <p><strong>Base de données : </strong>Un système organisé pour stocker et gérer des ensembles de données.</p>
            <p><strong>Big Data : </strong>Un terme utilisé pour décrire des ensembles de données volumineux, complexes et en croissance rapide qui dépassent les capacités des outils logiciels traditionnels de gestion et de traitement des données.</p>
            <p><strong>Blockchain : </strong>Une technologie de stockage et de transmission d'informations de manière transparente, sécurisée et décentralisée, utilisée notamment dans les cryptomonnaies.</p>
            <p><strong>Cloud computing (Informatique en nuage) :</strong> La fourniture de services informatiques sur Internet, permettant un accès à la demande à un pool partagé de ressources informatiques configurables.</p>
            <p><strong>Cryptographie :</strong> L'étude des techniques de sécurisation des communications et de l'information en utilisant des méthodes de chiffrement.</p>
            <p><strong>Cryptomonnaie :</strong> Une monnaie numérique qui utilise la cryptographie pour sécuriser les transactions et contrôler la création de nouvelles unités.</p>
            <p><strong>CSS (Cascading Style Sheets) :</strong> Un langage utilisé pour définir la présentation et le style des pages web écrites en HTML.</p>
            <p><strong>Cyberattaque :</strong> Une tentative délibérée et malveillante de compromettre la sécurité informatique d'un système, d'un réseau ou d'un appareil pour voler des données, perturber les opérations ou causer d'autres dommages.</p>
            <p><strong>Cyberdéfense :</strong> Les mesures proactives prises pour protéger les systèmes informatiques contre les cyberattaques, y compris la surveillance, la détection et la réponse aux menaces potentielles.</p>
            <p><strong>Cybersécurité :</strong> La pratique de protéger les systèmes informatiques, les réseaux et les données contre les menaces, les attaques et les vulnérabilités, en utilisant des mesures de sécurité techniques, organisationnelles et humaines.</p>
            <p><strong>Deep Learning :</strong> Une sous-catégorie du machine learning qui utilise des réseaux de neurones artificiels profonds pour apprendre des représentations hiérarchiques de données complexes, notamment pour la reconnaissance vocale, la reconnaissance d'images et la traduction automatique.</p>
            <p><strong>DevOps :</strong> Une approche de développement logiciel qui vise à améliorer la collaboration entre les équipes de développement (Dev) et les équipes opérationnelles (Ops) pour accélérer la livraison de logiciels de haute qualité.</p>
            <p><strong>Edge Computing :</strong> Un modèle informatique distribué qui consiste à traiter les données près de la source de génération, souvent sur des appareils périphériques ou des nœuds de réseau, pour réduire la latence et améliorer l'efficacité du traitement des données.</p>
            <p><strong>Filtrage du contenu (Content Filtering) :</strong> Le processus de contrôle et de restriction de l'accès aux contenus en ligne, tels que les sites web, les applications et les médias, basé sur des critères prédéfinis, tels que le contenu inapproprié, offensant ou malveillant.</p>
            <p><strong>Firewall (pare-feu) : </strong>Un système de sécurité qui contrôle le trafic entrant et sortant d'un réseau informatique et qui peut bloquer ou autoriser le trafic en fonction de règles prédéfinies.</p>
            <p><strong>Hameçonnage (Phishing) :</strong> Une technique d'attaque qui utilise des e-mails, des messages instantanés ou d'autres moyens de communication pour inciter les utilisateurs à divulguer des informations confidentielles, telles que des mots de passe ou des informations financières, en se faisant passer pour des entités légitimes.</p>
            <p><strong>HTML (Hypertext Markup Language) :</strong> Un langage de balisage utilisé pour créer des pages web et définir leur structure et leur contenu.</p>
            <p><strong>Informatique quantique :</strong> Un domaine émergent de l'informatique qui utilise les principes de la mécanique quantique pour effectuer des calculs complexes à une vitesse et à une échelle qui dépassent les capacités des ordinateurs classiques.</p>
            <p><strong>Ingénierie logicielle :</strong> L'application de principes et de pratiques d'ingénierie pour la conception, le développement, la maintenance et le test de logiciels.</p>
            <p><strong>Ingénierie sociale : </strong>Une technique d'attaque qui exploite la manipulation psychologique des individus pour obtenir des informations confidentielles, telles que des mots de passe ou des informations d'identification, ou pour inciter à des actions préjudiciables.</p>
            <p><strong>Intégration continue :</strong> Une pratique de développement logiciel qui consiste à fusionner fréquemment les modifications de code dans un dépôt partagé, suivi d'une série d'automatisations de tests et de déploiements pour garantir la stabilité et la qualité du logiciel.</p>
            <p><strong>Intelligence artificielle (IA) :</strong> La simulation de processus intelligents par des machines, notamment l'apprentissage automatique, la vision par ordinateur, le traitement du langage naturel, etc.</p>
            <p><strong>Interface utilisateur (UI) :</strong> Le moyen par lequel un utilisateur interagit avec un appareil ou une application informatique.</p>
            <p><strong>Internet des objets (IoT) : </strong>Un réseau de dispositifs physiques connectés à Internet, équipés de capteurs, de logiciels et de technologies de communication, qui permettent la collecte, l'échange et l'analyse de données pour automatiser des tâches et améliorer les processus.</p>
            <p><strong>Internet éthique : </strong>L'application de principes éthiques dans la conception, le développement et l'utilisation d'Internet et des technologies numériques, en mettant l'accent sur la confidentialité, la sécurité, la liberté d'expression et l'inclusion sociale.</p>
            <p><strong>Internet des objets (IoT) : </strong>Un réseau de dispositifs physiques connectés à Internet, équipés de capteurs, de logiciels et de technologies de communication, qui permettent la collecte, l'échange et l'analyse de données pour automatiser des tâches et améliorer les processus.</p>
            <p><strong>JavaScript :</strong> Un langage de programmation utilisé pour créer des applications web interactives.</p>
            <p><strong>Langage de programmation :</strong> Un ensemble de règles, de symboles et de syntaxe utilisés pour écrire des programmes informatiques.</p>
            <p><strong>Logiciel malveillant (Malware) :</strong> Un terme générique qui fait référence à tout logiciel conçu pour endommager, perturber, voler des données ou obtenir un accès non autorisé à un système informatique, comprenant des virus, des vers, des chevaux de Troie, des logiciels espions, etc.</p>
            <p><strong>Machine Learning :</strong> Une branche de l'intelligence artificielle qui se concentre sur le développement de techniques permettant aux machines d'apprendre à partir de données et d'améliorer leurs performances sans être explicitement programmées.</p>
            <p><strong>Mémoire cache : </strong>Une mémoire rapide utilisée par un processeur pour stocker temporairement des données fréquemment utilisées, ce qui permet d'améliorer les performances du système.</p>
            <p><strong>Microservices :</strong> Une architecture logicielle qui divise une application en petits services indépendants, modulaires et interopérables, favorisant la flexibilité, la scalabilité et la réutilisabilité.</p>
            <p><strong>Pare-feu (Firewall) :</strong> Un système de sécurité réseau qui surveille et contrôle le trafic entrant et sortant, basé sur un ensemble de règles prédéfinies, pour empêcher les intrusions non autorisées et les attaques malveillantes.</p>
            <p><strong>Piratage informatique :</strong> L'accès non autorisé à un système informatique ou à des données dans le but de voler des informations, de causer des dommages ou d'autres activités malveillantes.</p>
            <p><strong>Plateforme de données :</strong> Une infrastructure technologique qui permet de collecter, de stocker, de gérer et d'analyser de grandes quantités de données provenant de différentes sources, afin de prendre des décisions éclairées et de générer des insights commerciaux.</p>
            <p><strong>Ransomware :</strong> Un type de logiciel malveillant qui bloque l'accès à un système ou à des données et exige un paiement (rançon) pour rétablir l'accès, souvent par le biais d'un chiffrement des fichiers.</p>
            <p><strong>Réseau informatique :</strong> Un ensemble d'appareils interconnectés qui partagent des ressources et des données.</p>
            <p><strong>Réseau neuronal :</strong> Un modèle computationnel inspiré par le fonctionnement du cerveau humain, utilisé en intelligence artificielle pour apprendre à reconnaître des schémas complexes et effectuer des tâches cognitives.</p>
            <p><strong>Réseau privé virtuel (VPN) :</strong> Un service qui crée un tunnel sécurisé et chiffré entre un dispositif utilisateur et un réseau privé, permettant un accès sécurisé à Internet et la protection de la vie privée en ligne.</p>
            <p><strong>Réseau social :</strong> Une plateforme en ligne qui permet aux individus de se connecter, de partager des informations et de communiquer entre eux.</p>
            <p><strong>Robotique :</strong> Le domaine de l'ingénierie et de la science qui concerne la conception, la construction et l'utilisation de robots pour effectuer des tâches automatisées.</p>
            <p><strong>Scrum :</strong> Une méthodologie de gestion de projet agile qui favorise la collaboration, la transparence et l'adaptation continue, notamment dans le développement logiciel.</p>
            <p><strong>Sécurité des données :</strong> Les pratiques, politiques et technologies visant à protéger l'intégrité, la confidentialité et la disponibilité des données contre les menaces, les violations et les pertes.</p>
            <p><strong>Sécurité informatique :</strong> La protection des systèmes informatiques et des données contre les menaces, telles que les virus, les pirates informatiques et les attaques en ligne.</p>
            <p><strong>Sécurité physique :</strong> Les mesures de sécurité visant à protéger les ressources informatiques, telles que les serveurs, les centres de données et les équipements réseau, contre les menaces physiques, telles que le vol, l'accès non autorisé et les dommages physiques.</p>
            <p><strong>Système de gestion de contenu (CMS) :</strong> Une application logicielle qui permet de créer, de gérer et de publier du contenu numérique, notamment des sites web et des blogs.</p>
            <p><strong>Système d'exploitation :</strong> Un logiciel qui contrôle les ressources matérielles et permet aux autres logiciels de s'exécuter sur un ordinateur.</p>
            
            <p><strong>URL (Uniform Resource Locator) :</strong> L'adresse d'une ressource sur Internet, telle qu'une page web ou un fichier, qui spécifie le protocole utilisé pour accéder à la ressource, le nom de domaine et le chemin d'accès.</p>
            <p><strong>Virtualisation :</strong> La création d'une version virtuelle d'un objet, d'un système d'exploitation, d'un serveur, d'un réseau ou d'une ressource informatique, permettant de partager efficacement les ressources matérielles et d'optimiser l'utilisation des infrastructures.</p>
            <p><strong>Virus informatique : </strong>Un programme malveillant conçu pour se propager et causer des dommages aux systèmes informatiques.</p>
           
            </div>
            
        </div>
        </>
    );
};

export default Dictionnaire;