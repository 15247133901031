import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import FileExplorer from "../../../composants/FileExplorer";

const CopieColle = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>Copié collé - Clic de souris</title>
      </Helmet>
      <div className="copieColle-container">
        <div className="container">
        <br />
          <br />
          <br />
          <br />
          <h1> Les Copiés-Collés et le Déplacement d'Éléments sur Windows</h1>
          <br />
          <br />
          <p>
            <h2> I.Copié-Collé (Copier et Coller)</h2>
            <br />
            Le copié-collé est une fonctionnalité essentielle pour dupliquer du
            contenu d'un endroit à un autre. <br />
            <br />
            1. <span className="spam"> Sélection du Contenu :</span> <br />{" "}
            <br />
            . Placez le curseur à l'endroit où commence le texte ou l'élément
            que vous souhaitez copier. <br />
            . Maintenez le bouton gauche de la souris enfoncé tout en faisant
            glisser le curseur sur le contenu pour le sélectionner. <br />{" "}
            <br />
            2. <span className="spam"> Copier le Contenu : </span>
            <br /> <br />
            . Une fois le contenu sélectionné, faites un clic droit sur la
            sélection. <br />. Dans le menu contextuel, choisissez l'option
            "Copier" en cliquant dessus. <br /> <br />
            3.<span className="spam"> Coller le Contenu : </span> <br />
            <br />
            . Placez maintenant le curseur à l'endroit où vous souhaitez coller
            le contenu copié. <br />
            . Faites un clic droit à cet endroit, puis choisissez l'option
            "Coller" dans le menu contextuel. <br /> <br />
            <h2>II. Déplacement d'Éléments </h2> <br />
            Le déplacement d'éléments est utile lorsque vous souhaitez
            transférer des fichiers ou des dossiers d'un emplacement à un autre.{" "}
            <br /> <br />
            1.<span className="spam">
              {" "}
              Sélection des Éléments :{" "}
            </span> <br /> <br />
            . Ouvrez l'Explorateur de fichiers (icône de dossier dans la barre
            des tâches ou touche Windows + E). <br />
            . Allez à l'emplacement où se trouvent les fichiers ou dossiers que
            vous souhaitez déplacer. <br />. Sélectionnez-les en maintenant la
            touche Ctrl enfoncée tout en cliquant sur chaque élément. <br />{" "}
            <br />
            2.<span className="spam">
              {" "}
              Déplacer les Éléments :{" "}
            </span> <br /> <br />
            . Une fois les éléments sélectionnés, faites un clic droit sur l'une
            des sélections. <br />
            . Dans le menu contextuel, choisissez l'option "Couper" pour
            préparer le déplacement. <br /> <br />
            3.
            <span className="spam">
              {" "}
              Naviguer vers l'Emplacement de Destination :{" "}
            </span>{" "}
            <br />
            . Accédez à l'emplacement où vous souhaitez déplacer les fichiers ou
            dossiers. <br /> <br />
            4.<span className="spam"> Coller les Éléments : </span> <br />{" "}
            <br />
            . Faites un clic droit dans l'emplacement de destination. <br />
            . Choisissez l'option "Coller" dans le menu contextuel. <br />{" "}
            <br />
            5.<span className="spam"> Confirmation du Déplacement : </span>
            <br /> <br />. Suivez les instructions pour confirmer le
            déplacement. <br />
            <br />
            C'est ainsi que vous pouvez copier-coller et déplacer des éléments
            sur Windows. Ces actions sont également réalisables via les
            raccourcis clavier, tels que Ctrl+C pour copier, Ctrl+V pour coller,
            Ctrl+X pour couper, et Ctrl+Z pour annuler des actions. Pratiquer
            ces méthodes vous rendra rapidement à l'aise avec ces opérations.
          </p>
        </div>
        <FileExplorer/>
      </div>
    </>
  );
};

export default CopieColle;
