import React from "react";
import { Helmet } from "react-helmet-async";

const presentation = () => {
    
    return (
      <>
          <Helmet>
            <title>Présentation - Clic de souris</title>
          </Helmet>
        <div className="presentation">
        
          <div className="present-content">
            <h3>Bonjour !</h3>
            <p>
              Je suis ravis de vous accueillir sur la plateforme et tiens à vous
              remercier chaleureusement d'avoir choisi de vous abonner au
              service. Votre soutien est essentiel pour permettre de continuer à
              offrir des ressources éducatives de qualité et à développer la
              communauté. <br />
              <p>
                En tant qu'abonné premium, vous avez maintenant accès à une
                multitude d'avantages exclusifs qui enrichiront votre expérience
                sur le site :
              </p>
              <p>
                .Cours premium : Explorez une variété de cours sur
                l'informatique. Que vous cherchiez à approfondir vos
                connaissances ou à acquérir de nouvelles compétences, les cours
                sont là pour vous accompagner dans votre parcours
                d'apprentissage.
              </p>
              <p>
                .Accès au Discord : Rejoignez la communauté Discord dynamique où
                vous pourrez interagir avec d'autres membres, poser des
                questions, échanger des conseils et participer à des discussions
                passionnantes sur une multitude de sujets.
              </p>
              <p>
                .Quiz interactifs : Mettez vos connaissances à l'épreuve avec
                nos quiz interactifs. Testez-vous, suivez votre progression et
                découvrez de nouveaux domaines d'apprentissage à travers nos
                quiz divertissants et éducatifs.
              </p>
              <p>
                Et bien plus encore : Profitez d'autres avantages exclusifs
                réservés aux abonnés premium, tels que des événements spéciaux,
                et des mises à jour régulières avec du contenu supplémentaire.
                Et pour vous prouver à quel point vous êtes unique, à votre
                demande, vous pourrez recevoir des cours d'informatique sur un
                sujet de votre choix dans vos pages "Cours".
              </p>
              <p>
                Je suis impatients de vous accompagner dans votre parcours
                d'apprentissage et de vous offrir une expérience enrichissante
                et gratifiante sur la plateforme. N'hésitez pas à explorer tous
                les avantages dont vous bénéficiez en tant qu'abonné premium et
                à me faire part de vos commentaires et suggestions.
              </p>
              <p>
                Encore une fois, merci de votre soutien continu. Bienvenue dans
                la communauté des apprenants passionnés !
              </p>
              Bien cordialement,
            </p>
          </div>
          </div>
          </>
    );
};

export default presentation;