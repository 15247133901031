import React, { useState } from "react";

const QuizE = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 1",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1.Quel est l'outil principal pour naviguer à travers les fichiers et dossiers sur un ordinateur Windows ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Le Panneau de configuration",
          "L'Explorateur de fichiers",
          "Le Gestionnaire des tâches",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "L'exlorateur de fichiers' était la bonne réponse.",
        "point": 20
      },
      {
        "question": "2. Comment créer un nouveau dossier dans Windows ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Cliquez sur -Supprimer-",
          "Cliquez avec le bouton droit de la souris et sélectionnez -Nouveau- > -Dossier-",
          "Faites glisser un fichier vers le Bureau",

        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": " La bonne réponse était : Cliquez avec le bouton droit de la souris et sélectionnez -Nouveau- > -Dossier- !",
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Quelle est la commande pour créer un lien symbolique vers un dossier dans l'invite de commandes de Windows ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "mklink /F",
          "mklink /S",
          "mklink /D",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est  mklink /D.",
        "point": 20
      },
      {
        "question": "4. Quelle fonctionnalité Windows permet de compresser des fichiers et des dossiers pour économiser de l'espace de stockage ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Explorateur de fichiers",
          "Gestionnaire de disque",
          "Explorateur de disque",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est la Explorateur de fichiers.",
        "point": 20
      },
      {
        "question": "5. Comment accéder à l'outil de sauvegarde et de restauration de Windows ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Dans le Panneau de configuration",
          "En recherchant -Sauvegarder et restaurer- dans le menu Démarrer",
          "Dans le Gestionnaire des tâches",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est En recherchant -Sauvegarder et restaurer- dans le menu Démarrer.",
        "point": 20
      },
      {
        "question": "6. Quelle fonctionnalité Windows permet de sauvegarder automatiquement les versions précédentes de vos fichiers ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Historique des fichiers",
          "Sauvegarde des fichiers",
          "Versioning de fichiers",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est l'Historique des fichiers.",
        "point": 20
      },
      {
        "question": "7. Quel outil Windows permet de libérer de l'espace disque en supprimant les fichiers temporaires et inutiles ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Gestionnaire de disque",
          "Nettoyage de disque",
          "Optimisation de disque",
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est le Nettoyage de disque",
        "point": 20
      },
      {
        "question": "8. Comment modifier les autorisations d'un fichier ou d'un dossier sur Windows ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En double cliquant sur le fichier",
          "En cliquant avec le bouton droit de la souris et en sélectionnant Propriétés > Sécurité",
          "En glissant-déposant le fichier dans un autre dossier",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est En cliquant avec le bouton droit de la souris et en sélectionnant Propriétés > Sécurité.",
        "point": 20
      },
      {
        "question": "9. Quelle fonctionnalité Windows permet de créer des raccourcis vers des fichiers ou des dossiers pour un accès rapide ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Panneau de configuration",
          "Bureau",
          "Explorateur de fichiers",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Explorateur de fichiers.",
        "point": 20
      },
      {
        "question": "10. Quelle est la commande pour restaurer un fichier ou un dossier à partir de la Corbeille dans Windows ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "CTRL+Z",
          "CTRL+Y",
          "Clic droit sur le fichier ou le dossier > Restaurer",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Clic droit sur le fichier ou le dossier > Restaurer.",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizA">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizE;
