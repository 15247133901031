import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

const Lexique = () => {
    const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    // Vérifier l'état d'abonnement de l'utilisateur
    const checkSubscription = async () => {
      try {
        const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
        setIsSubscribed(response.data.isSubscribed);
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'abonnement :', error);
      }
    };

    checkSubscription();
  }, [userId]);
  
  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
    return (
        <div className="lexique-container">
            <div className="content">
                <h3>voici les principaux termes que vous trouverez concernant les périphériques informatique.</h3>
            <p><strong>Clavier :</strong> Un périphérique d'entrée qui permet à l'utilisateur de saisir du texte, des commandes et des caractères spéciaux en appuyant sur des touches.</p>

<p><strong>Souris :</strong> Un périphérique d'entrée utilisé pour contrôler le curseur sur l'écran de l'ordinateur et interagir avec les éléments graphiques en cliquant, en faisant glisser et en effectuant d'autres actions.</p>

<p><strong>Écran (Moniteur) :</strong> Un périphérique de sortie qui affiche les informations et les images produites par l'ordinateur, permettant à l'utilisateur de visualiser et d'interagir avec le contenu.</p>

<p><strong>Imprimante :</strong> Un périphérique de sortie utilisé pour produire des copies physiques de documents, d'images et d'autres contenus à partir de l'ordinateur en les imprimant sur du papier ou d'autres supports.</p>

<p><strong>Scanner :</strong> Un périphérique d'entrée utilisé pour capturer des images, des documents et d'autres supports physiques et les convertir en formats numériques pour les traiter et les stocker sur l'ordinateur.</p>

<p><strong>Webcam :</strong> Un périphérique d'entrée utilisé pour capturer des images et des vidéos en temps réel, permettant la communication vidéo, la diffusion en direct et d'autres applications multimédias.</p>

<p><strong>Haut-parleurs (Enceintes) :</strong> Des périphériques de sortie utilisés pour produire des sons et des audio à partir de l'ordinateur, permettant à l'utilisateur d'écouter de la musique, de regarder des vidéos et d'autres contenus audiovisuels.</p>

<p><strong>Microphone :</strong> Un périphérique d'entrée utilisé pour capturer le son et l'audio à partir de sources externes, tels que la voix humaine, les instruments de musique et d'autres sons, pour les enregistrer et les traiter sur l'ordinateur.</p>

<p><strong>Casque (Écouteurs) :</strong> Des périphériques de sortie utilisés pour écouter du son et de l'audio à partir de l'ordinateur de manière privée, permettant à l'utilisateur de profiter de la musique, des jeux, des appels audio et d'autres contenus audio sans déranger les autres.</p>

<p><strong>Lecteur de CD/DVD :</strong> Un périphérique de stockage et de lecture optique utilisé pour lire, enregistrer et stocker des données sur des disques compacts (CD) ou des disques numériques polyvalents (DVD) pour la musique, les films, les logiciels et d'autres contenus multimédias.</p>

<p><strong>Disque dur externe :</strong> Un périphérique de stockage externe utilisé pour étendre la capacité de stockage de l'ordinateur, permettant à l'utilisateur de sauvegarder, de transférer et de stocker des fichiers et des données supplémentaires en dehors du disque dur interne de l'ordinateur.</p>

<p><strong>Clé USB (Flash Drive) :</strong> Un périphérique de stockage portable utilisé pour stocker, transférer et transporter des fichiers et des données entre différents ordinateurs et appareils, offrant une solution de stockage externe pratique et légère.</p>

<p><strong>Lecteur de cartes mémoire :</strong> Un périphérique de lecture et d'écriture utilisé pour accéder aux données stockées sur des cartes mémoire, telles que les cartes SD, microSD, CompactFlash, et d'autres formats, permettant la récupération et le transfert de photos, vidéos et autres fichiers.</p>

<p><strong>Routeur :</strong> Un périphérique de réseau utilisé pour distribuer et gérer les connexions Internet et les réseaux locaux (LAN) en envoyant des données entre les ordinateurs, les appareils et les serveurs connectés dans un réseau informatique.</p>
<p><strong>Modem :</strong>
 Un périphérique de communication utilisé pour établir une connexion Internet en convertissant les signaux numériques de l'ordinateur en signaux analogiques compatibles avec les lignes téléphoniques ou les câbles coaxiaux, permettant l'accès à Internet via une connexion haut débit.</p>
        </div></div>
    );
};

export default Lexique;