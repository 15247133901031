import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import taches from "../../../media/barreDesTaches.png";
import bureau from "../../../media/bureauWindows.png";
import demarrer from "../../../media/demarrer.png";
import recherche from "../../../media/recherche.png";
import notifications from "../../../media/notifications.png";
import epingle from "../../../media/epingle.png";
import explorateur from "../../../media/explorateur.png";
import favoris from "../../../media/favoris.png";
import corbeille from "../../../media/corbeille.png";
import fenetreActive from "../../../media/fenêtreActive.png";
import barredetitre from "../../../media/barredetitre.png";
import reduire from "../../../media/reduire.png";
import agrandir from "../../../media/agrandir.png";
import close from "../../../media/close.png";
import { Helmet } from "react-helmet-async";

const Fenetre = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>La Fenêtre "Windows" - Clic de souris</title>
      </Helmet>
      <div className="fenetre-container">
        <div className="container">
        <br />
          <br />
          <br />
          <br />
          <h1>La Navigation dans une Fenêtre Windows</h1>
          <br />
          <br />
          <p>
            Bienvenue dans le monde de Windows, l'un des systèmes d'exploitation
            les plus utilisés au monde. <br />
            Pour profiter pleinement de votre expérience informatique, il est
            essentiel de comprendre comment naviguer efficacement dans
            l'interface utilisateur. Imaginons une fenêtre Windows standard pour
            explorer les éléments clés.
          </p>
          <br />
          <br />
          <h2>1. Bureau Windows : </h2>
          <br />
          <p>
            {" "}
            Le bureau est l'écran principal lorsque vous démarrez votre
            ordinateur. Il peut contenir des icônes, des raccourcis et même des
            fonds d'écran personnalisés.
          </p>
          <img className="bureau" src={bureau} alt="bureau windows" />
          <br /> <br />
          <p>
            <h2> 2. Barre des Tâches : </h2>
            <br />
            En bas de l'écran, la barre des tâches offre un accès rapide à
            plusieurs fonctionnalités.
          </p>
          <img className="taches" src={taches} alt="barre des taches windows" />
          <br />
          <br />
          <h3>Bouton Démarrer : </h3>
          <br />
          <p>
            Premier icone tout en bas à gauche en forme de 4 petits carrés bleus
            (sous windows 11). Cliquez ici pour accéder au menu Démarrer, qui
            propose des applications, des paramètres, et plus encore.
          </p>
          <img src={demarrer} alt="icone menu demarrer windows 11" />
          <br />
          <br />
          <h3>Zone de Recherche : </h3>
          <br />
          <p>
            C'est le deuxième icone de gauche sur la barre des tâches. Il est
            représenté sous forme de loupe. Saisissez des mots-clés pour
            rechercher des fichiers, applications, ou même sur le web.
          </p>
          <img src={recherche} alt="zone de recherche windows 11" />
          <br />
          <br />
          <h3>Icônes des Applications Épinglées : </h3>
          <br />
          <p>
            {" "}
            Les programmes fréquemment utilisés peuvent être épinglés ici pour
            un accès rapide.
          </p>{" "}
          <img className="epingle" src={epingle} alt="zone appli épinglé à la barre des taches" />
          <br />
          <h3>Zone de Notification : </h3>
          <br />
          <p> Affiche l'heure, la date, et des notifications système.</p>
          <img src={notifications} alt="icone notification windows 11" /> <br />
          <br />
          <h2>3. Explorateur de Fichiers : </h2>
          <br />
          <p>
            
            L'Explorateur de Fichiers est votre outil pour naviguer à travers
            les dossiers et fichiers.
          </p>
          <img className="explorateur" src={explorateur} alt="fenêtre d'exploration de fichier" />
          <br />
          <br />
          <p>
            {" "}
            Dossiers Favoris : <br />
            Accédez rapidement aux dossiers fréquemment utilisés.
          </p>{" "}
          <img src={favoris} alt="extrait de fenêtre window avec favoris" />
          <br />
          <br />
          <p>
            {" "}
            Fenêtre de Contenu : <br />
            Affiche les fichiers et dossiers dans le dossier sélectionné.
          </p>{" "}
          <br />
          <br />
          <h2>4. Corbeille : </h2>
          <br />
          <p>
            {" "}
            La Corbeille stocke temporairement les fichiers supprimés. Vous
            pouvez les restaurer ou les supprimer définitivement.
          </p>{" "}
          <img src={corbeille} alt="image du logo d'une corbeille windows" />
          <br />
          <br />
          <h2> 5. Fenêtres Actives : </h2>
          <br />
          <p>
            {" "}
            Lorsque vous ouvrez des applications, chaque fenêtre est représentée
            sur la barre des tâches.
          </p>{" "}
          <img className="fenetreActive" src={fenetreActive} alt="fenêtre actvie d'un bureau windows" />
          <br />
          <br />
          <h2> 6. Barre de Titre : </h2>
          <br />
          <p>
            {" "}
            En haut de chaque fenêtre, la barre de titre affiche le nom de
            l'application ou du dossier actif.
          </p>{" "}
          <img className="barredetitre" src={barredetitre} alt="barre de titre d'un dossier ouvert" />
          <br />
          <br />
          <h2>7. Boutons de Fenêtre : </h2>
          <br />
          <h3> Réduire : </h3>
          <br />
          <p> Minimise la fenêtre, la plaçant sur la barre des tâches.</p>{" "}
          <img className="reduire" src={reduire} alt="representation du trait qui reduit une fenêtre" />
          <br />
          <br />
          <h3>Agrandir/Réduire : </h3> <br />
          <p> Permet de passer entre les modes plein écran et fenêtré.</p>{" "}
          <img className="agrandir" src={agrandir} alt="representation du bouton pour agrandir une fenêtre" />
          <br />
          <br />
          <h3>Fermer : </h3> <br />
          <p>Ferme la fenêtre.</p>
          <img className="close" src={close} alt="representation de la croix pour fermer la fenêtre windows" /> <br />
          <br />
          <h2> 8. Manipulation des Fenêtres : </h2>
          <br />
          <h3> Glisser-Déposer : </h3>
          <br />
          <p>
            {" "}
            Déplacez une fenêtre en la saisissant par la barre de titre.<br />
            Cliquez deux fois rapidement sur la barre de titre de votre dossier avec le bouton gauche de la souris et ne relâchez pas ce bouton. Vous pouvez ensuite transporter le fichier ou vous désirez dans la fenêtre.
          </p>{" "}
          <br />
          <br />
          <h3> Redimensionner : </h3>
          <br />
          <p>
            {" "}
            Passez le curseur sur le bord d'une fenêtre pour ajuster sa taille.<br />
            En passant le curseur au bon endroit celui si se transformera en une flêche a deux direction opposées, cela veut dire que vous pourrez alors modifier la taille de votre fenêtre.
          </p>{" "}
          <br />
          <br />
          <h2>9. Personnalisation : </h2>
          <br />
          <p>
            Explorez les paramètres pour personnaliser l'apparence, le fond
            d'écran, les thèmes, et plus encore.{" "}
          </p>{" "}
          <br />
          <br />
          <p>
            {" "}
            Conclusion : <br />
            Naviguer dans une fenêtre Windows est une compétence fondamentale
            pour exploiter pleinement les fonctionnalités de votre ordinateur.
            Avec ces connaissances, vous pouvez organiser vos fichiers, accéder
            rapidement à vos applications préférées et personnaliser votre
            expérience selon vos besoins. Profitez de votre exploration dans
            l'univers Windows !
          </p>
          <br />
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default Fenetre;
