import React, { useState } from "react";

const QuizC = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 1",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1. Quelle étape est nécessaire pour accéder à Internet sur votre ordinateur ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Vérifier la connexion Wi-Fi",
          "Activer le Bluetooth",
          "Allumer la télévision",
         
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Vérifier la connexion Wi-Fi est primordial car sans Bluetooth ou télévision on peut avoir internet mais pas sans Wi-Fi !",
        "point": 20
      },
      {
        "question": "2. Qu'est-ce qu'un navigateur web ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Un logiciel de traitement de texte",
          "Un logiciel de retouche photo",
          "Un logiciel qui permet d'accéder à Internet",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse était un logiciel qui permet d'accéder à internet." ,
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Où pouvez-vous saisir l'adresse d'un site web que vous souhaitez visiter ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Dans la barre de recherche du navigateur",
          "Dans la barre d'adresse du navigateur",
          "Dans la barre des tâches de l'ordinateur",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est la barre d'adresse du navigateur.",
        "point": 20
      },
      {
        "question": "4. Comment pouvez-vous effectuer une recherche sur Internet si vous ne connaissez pas l'adresse d'un site spécifique ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En demandant à un ami",
          "En regardant la télévision",
          "En utilisant un moteur de recherche",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est en utilisant un moteur de recherche.",
        "point": 20
      },
      {
        "question": "5. Comment pouvez-vous revenir à la page précédente lors de la navigation sur un site web ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En fermant le navigateur",
          "En éteignant l'ordinateur",
          "En cliquant sur le bouton Retour du navigateur",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est En cliquant sur le bouton Retour du navigateur.",
        "point": 20
      },
      {
        "question": "6. Quelle fonctionnalité des navigateurs modernes vous permet d'ouvrir plusieurs pages web en même temps ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Les onglets",
          "Les signets",
          "Les boutons de navigation",
         
        ],
        "correctAnswer": 3,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Les Onglets.",
        "point": 20
      },
      {
        "question": "7. Comment pouvez-vous sauvegarder un site web que vous aimez pour y revenir plus tard ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "En le partageant sur les réseaux sociaux", 
          "En l'ajoutant à vos signets (favoris)",
          "En l'imprimant",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est en l'ajoutant à vos signets.",
        "point": 20
      },
      {
        "question": "8. Quelle est l'importance de la sécurité en ligne ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Cliquer sur tous les liens que vous voyez",
          "Partager vos informations personnelles en ligne",
          "Éviter les sites suspects et maintenir les logiciels à jour",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est d'éviter les sites suspects et maintenir les logiciels à jour.",
        "point": 20
      },
      {
        "question": "9. Quel symbole indique que vous êtes sur un site web sécurisé ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Un point d'exclamation rouge",
          "Un point d'interrogation bleu",
          "Un cadenas dans la barre d'adresse (https)",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est un cadenas dans la barre d'adresse.",
        "point": 20
      },
      {
        "question": "10. Que devriez-vous faire si vous souhaitez télécharger un fichier depuis Internet ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Vérifier la source et scanner le fichier avec un antivirus",
          "Télécharger sans se soucier de la source",
          "Ignorer les avertissements de sécurité",
         
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est de vérifier la source et scanner le fichier avec un antivirus.",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizC">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizC;
