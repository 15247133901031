import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import messageries from "../../../media/messageries.png";
import inscripoutlook from "../../../media/inscripoutlook.jpg";
import bandeauoutlook from "../../../media/bandeauoutlook.png";
import expemail from "../../../media/expemail.png";
import dossierbtmail from "../../../media/dossierbtmail.png";
import EmailForm from "../../../composants/EmailForm";
const CreerMail = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>Créer un mail - Clic de souris</title>
      </Helmet>
      <div className="creerMail-container">
        <div className="container">
        <br />
          <br />
          <br />
          <br />
          <h1>Création d'une Adresse Électronique</h1>
          <h2> Introduction à l'Adresse Électronique </h2>
            <p> Une adresse électronique, ou e-mail, est une identité numérique utilisée pour la
          communication en ligne.</p>
           <h2> Choix d'un Fournisseur de Messagerie</h2>
            
           <p>Explorer différentes plateformes de messagerie et choisir
          celle qui convient le mieux.</p><br/> 
          <p>Fournisseurs populaires : Gmail, Outlook,
          Yahoo, etc. Critères de choix : Sécurité, facilité d'utilisation,
          fonctionnalités.</p>
          <img src={messageries} alt="pense bêtes avec plusieurs images de messageries" /> <br /> <br />
          <h2> Étapes de Création</h2>
          <p>Remplir les informations demandées, y
          compris le nom, le prénom, et choisir un nom d'utilisateur. Choix du
          Nom d'Utilisateur : Si l'adresse électronique est une adresse professionnelle alors choisissez de mettre votre nom plutôt qu'un pseudo qui lui sera plutôt choisie pour une adresse électronique personnelle. Choix du Mot de Passe : Sécurisez vos mots de passe en faisant des 
          combinaisons de lettres, chiffres et caractères spéciaux. Optez pour un minimum de 8 caractères. Vérification
          : Certains fournisseurs peuvent nécessiter une vérification par e-mail
          ou par téléphone.</p>
          <img src={inscripoutlook} alt="page d'inscription à outlook" /><br /><br />
          <h3>Personnalisation du Compte</h3>
           <p>Photo de Profil : Ajouter une photo pour personnaliser le
          compte. </p> <br />
          <p>Signature : Ajouter une signature personnalisée pour les
          e-mails sortants.</p><br />
          <p>Filtrage des E-mails : Configurer des filtres pour
          organiser automatiquement les e-mails.</p><br />
          <img className="bandeauoutlook" src={bandeauoutlook} alt="bandeau du haut de page outlook" /><br /><br />
          <h2>Utilisation de
          l'Adresse Électronique</h2>  
          <h3>Envoyer et Recevoir des E-mails</h3> 
          <p>Composition d'un E-mail : Destinataire, objet, corps du
          message.</p>
          <p>Pièces Jointes : Attacher des fichiers à un e-mail. Répondre
          et Transférer : Savoir répondre et transférer des e-mails. </p><br />
          <img src={expemail} alt="création d'un mail" /><br /> <br />
            <h3>Maintenir une boîte de
          réception organisée.</h3>
          <p>.Dossiers et Libellés : Créer des dossiers pour
          organiser les e-mails.</p> 
          <img src={dossierbtmail} alt="representation de dossier dans la boite mail outlook" />
          <p>.Suppression et Archivage : Maintenir la boîte
          de réception propre.</p>  
          <p>Recherche : Utiliser la fonction de recherche
          pour retrouver des e-mails.</p><br /> <br />
           Ce cours fournit les bases pour créer et
          utiliser une adresse électronique de manière efficace. Je vous encourage à explorer les fonctionnalités avancées de votre
          fournisseur de messagerie et à pratiquer l'envoi et la réception
          d'e-mails. J'insistez également sur les bonnes pratiques de sécurité des
          e-mails, telles que la protection du mot de passe et la prudence face
          aux e-mails suspects.
        </div>
        <EmailForm/>
      </div>
    </>
  );
};

export default CreerMail;
