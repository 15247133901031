import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import clavier from "../../../media/clavier_souris.jpg";
import ecran from "../../../media/ecran.jpg";
import imprimante from "../../../media/imprimante.jpg";
import enceinte from "../../../media/enceinte.jpg";
import webcam from "../../../media/webcam.jpg";
import micro from "../../../media/micro.png";
import carte from "../../../media/carte.jpg";
import disquedur from "../../../media/disquedur.jpg";
import sansfil from "../../../media/sansfils.jpg";
import graphique from "../../../media/graphique.jpg";
import manette from "../../../media/manette.jpg";
import hub from "../../../media/hub.jpg";

import QuizB from "../../../composants/quizB";
const Peripheriques = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Ajoute une animation fluide au défilement
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };
  return (
    <div className="periph-container">
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <h1>Périphériques</h1>
        <br />
        <br />
        <h2>
          Les périphériques informatiques sont des composants essentiels qui
          étendent les fonctionnalités de nos ordinateurs, les rendant plus
          polyvalents et adaptés à différents besoins. Que vous utilisiez un
          ordinateur portable ou un ordinateur de bureau avec une tour, divers
          périphériques peuvent améliorer votre expérience informatique. Voici
          un aperçu des périphériques couramment utilisés :
        </h2>
        <p>
          <h3> Clavier et Souris :</h3>
          Ces périphériques d'entrée classiques permettent aux utilisateurs de
          communiquer avec leur ordinateur. Les claviers sont utilisés pour la
          saisie de texte, tandis que les souris facilitent la navigation sur
          l'écran. <br />
          <br />
          <img src={clavier} alt="un clavier et une souris d'ordinateur" />
          <br />
          <br />
          <br />
          <h3> Écran ou Moniteur : </h3>
          L'écran ou le moniteur affiche les informations visuelles générées par
          l'ordinateur. Il existe une variété de types d'écrans, y compris les
          écrans LCD, LED et OLED, offrant différentes résolutions et tailles.
          Les écrans LCD (Liquid Crystal Display) sont parmi les plus répandus
          et offrent une qualité d'image convenable avec une consommation
          d'énergie relativement faible. Les écrans LED (Light-Emitting Diode)
          sont devenus très populaires en raison de leur efficacité énergétique
          et de leur luminosité accrue, offrant des couleurs vives et une
          meilleure visibilité dans des conditions de luminosité variables. Les
          écrans OLED (Organic Light-Emitting Diode) sont réputés pour leur
          contraste élevé et leurs couleurs vibrantes, grâce à leur capacité à
          produire leur propre lumière pixel par pixel, ce qui permet des noirs
          profonds et une réponse plus rapide. Lors du choix d'un écran
          d'ordinateur, il est également essentiel de prendre en compte
          l'ergonomie, en optant par exemple pour des écrans réglables en
          hauteur pour une position de visualisation confortable, et la
          connectivité pour s'assurer qu'il est compatible avec les ports
          disponibles sur votre ordinateur. <br />
          <br />
          <img src={ecran} alt="homme souriant devant un ordinateur" />
          <br />
          <br />
          <br />
          <h3> Imprimante et Scanner : </h3>
          Les imprimantes permettent de produire des copies papier de documents
          numériques, tandis que les scanners permettent de convertir des
          documents physiques en fichiers numériques. Les imprimantes peuvent
          être de différents types, notamment les imprimantes à jet d'encre et
          les imprimantes laser. Les imprimantes à jet d'encre sont polyvalentes
          et conviennent généralement à un usage domestique ou de bureau, tandis
          que les imprimantes laser offrent une impression rapide et de haute
          qualité, idéale pour un usage professionnel. Les imprimantes
          tout-en-un combinent plusieurs fonctionnalités en un seul appareil,
          telles que l'impression, la numérisation, la copie et parfois même la
          télécopie, offrant ainsi une solution complète pour les besoins
          d'impression et de gestion de documents. Il est important de
          considérer plusieurs facteurs lors du choix d'une imprimante, tels que
          la vitesse d'impression, la qualité d'impression, le coût par page, la
          connectivité (filaire ou sans fil) et les fonctionnalités
          supplémentaires telles que l'impression recto-verso automatique ou
          l'impression depuis des appareils mobiles.
          <br />
          <br />
          <img
            className="imprimante"
            src={imprimante}
            alt="une imprimante HP blanche"
          />
          <br />
          <br />
          <br />
          <h3> Haut-parleurs ou Casques :</h3>
          Ces périphériques audio permettent de profiter de la sortie sonore de
          l'ordinateur, que ce soit pour écouter de la musique, regarder des
          vidéos ou participer à des appels. Il existe différents types de
          haut-parleurs, y compris les haut-parleurs internes intégrés aux
          ordinateurs portables ou aux moniteurs, ainsi que les haut-parleurs
          externes qui peuvent être connectés à l'ordinateur via des ports audio
          tels que la prise casque ou la prise audio. Les casques audio offrent
          une expérience d'écoute plus personnalisée en fournissant un son
          directement aux oreilles de l'utilisateur, réduisant ainsi les
          interférences sonores externes et offrant une qualité audio
          supérieure, idéale pour écouter de la musique ou regarder des films
          dans des environnements bruyants. Certains casques audio sont équipés
          de fonctionnalités supplémentaires telles que la suppression active du
          bruit (noise-cancelling), qui filtre les sons ambiants indésirables
          pour une écoute plus immersive, et des microphones intégrés pour les
          appels vocaux et les communications en ligne. Lors du choix de
          haut-parleurs ou de casques audio, il est important de considérer des
          facteurs tels que la qualité audio, le confort de port, la
          connectivité (filaire ou sans fil), la durabilité et les
          fonctionnalités supplémentaires telles que les commandes de volume
          intégrées ou les égaliseurs réglables.
          <br />
          <br />
          <img src={enceinte} alt="4 enceintes noirs d'ordinateur" />
          <br />
          <br />
          <br />
          <h3> Webcam :</h3>
          Les webcams sont des périphériques compacts équipés d'une caméra vidéo
          et d'un microphone intégré, permettant aux utilisateurs d'interagir en
          temps réel avec d'autres personnes à distance grâce à des appels vidéo
          et des conférences en ligne. Les webcams sont largement utilisées pour
          des activités telles que les réunions virtuelles, les cours en ligne,
          les entrevues d'emploi à distance et les appels vidéo personnels,
          offrant ainsi une alternative pratique aux réunions en personne.
          Certains modèles de webcams offrent des fonctionnalités
          supplémentaires telles que la résolution vidéo HD ou 4K pour une
          qualité d'image supérieure, ainsi que des objectifs grand angle ou des
          capacités de zoom pour une vue plus large ou des plans rapprochés lors
          des appels vidéo. Les webcams peuvent être connectées à un ordinateur
          via des ports USB, offrant ainsi une installation facile et une
          compatibilité avec une variété de systèmes d'exploitation, y compris
          Windows, macOS et Linux. En plus des appels vidéo et des conférences
          en ligne, les webcams peuvent également être utilisées pour créer des
          contenus multimédias tels que des vidéos YouTube, des tutoriels en
          ligne, des vlogs et des diffusions en direct sur des plateformes de
          streaming. Lors du choix d'une webcam, il est important de considérer
          des facteurs tels que la résolution vidéo, la qualité audio, la
          facilité d'installation, la compatibilité logicielle, ainsi que des
          fonctionnalités supplémentaires telles que la correction automatique
          de la luminosité et la suppression du bruit de fond pour une meilleure
          expérience utilisateur.
          <br />
          <br />
          <img src={webcam} alt="une caméra connectée à un ordinateur" />
          <br />
          <br />
          <br />
          <h3>Microphone : </h3>
          Les microphones sont des périphériques d'entrée audio qui captent les
          signaux sonores et les convertissent en signaux électriques,
          permettant ainsi aux utilisateurs de communiquer vocalement avec leur
          ordinateur. Il existe différents types de microphones adaptés à
          différents besoins, tels que les microphones intégrés aux ordinateurs
          portables, les microphones de bureau autonomes et les microphones
          casques pour une utilisation mains libres. Les microphones sont
          essentiels pour une gamme d'applications, y compris les appels vocaux
          via des logiciels de communication tels que Skype, les enregistrements
          audio pour des projets professionnels ou personnels, et les
          communications en ligne telles que les réunions virtuelles et les jeux
          en ligne. Certains microphones sont équipés de fonctionnalités
          supplémentaires telles que la suppression du bruit de fond pour une
          qualité audio améliorée, la réduction des échos pour des appels plus
          clairs et la capture directionnelle pour une focalisation précise sur
          la voix de l'utilisateur. Les microphones peuvent être connectés à un
          ordinateur via des ports audio analogiques tels que la prise micro,
          des ports USB pour une installation facile et une qualité audio
          numérique supérieure, ou même via des technologies sans fil telles que
          le Bluetooth pour une liberté de mouvement accrue. Lors du choix d'un
          microphone, il est important de considérer des facteurs tels que la
          qualité audio, la directivité du microphone (omnidirectionnel,
          cardioïde, bidirectionnel, etc.), la compatibilité avec les logiciels
          de communication, ainsi que la facilité d'installation et
          d'utilisation pour répondre aux besoins spécifiques de l'utilisateur.
          <br />
          <br />
          <img src={micro} alt="un microphone bleu sur un bureau" />
          <br />
          <br />
          <br />
          <h3> Lecteur de Cartes Mémoire :</h3>
          Les cartes mémoire sont des dispositifs de stockage de données
          compacts et portables utilisés dans une variété d'appareils
          électroniques, notamment les appareils photo, les smartphones, les
          tablettes et les ordinateurs. Les cartes mémoire sont utilisées pour
          stocker une gamme de données telles que des photos, des vidéos, des
          fichiers audio, des documents et des applications, offrant ainsi une
          solution pratique pour l'extension de la capacité de stockage des
          appareils. Les cartes mémoire sont disponibles dans différents formats
          et capacités, y compris les cartes SD (Secure Digital), les cartes
          microSD, les cartes CompactFlash, les cartes Memory Stick et les
          cartes XD-Picture, offrant ainsi aux utilisateurs une flexibilité dans
          le choix de la carte adaptée à leurs besoins. Les lecteurs de cartes
          mémoire sont des périphériques permettant de lire et d'écrire des
          données sur les cartes mémoire, facilitant ainsi le transfert de
          données entre les cartes mémoire et les ordinateurs. Certains
          ordinateurs sont équipés de lecteurs de cartes mémoire intégrés,
          tandis que d'autres nécessitent l'utilisation d'un lecteur de cartes
          externe connecté via un port USB, offrant ainsi une solution
          polyvalente pour accéder aux données stockées sur différentes cartes
          mémoire. Lors du choix d'une carte mémoire, il est important de
          considérer des facteurs tels que la capacité de stockage, la vitesse
          de lecture/écriture, la compatibilité avec les appareils, ainsi que le
          type d'utilisation prévu (photographie, vidéo, stockage de données,
          etc.) pour répondre aux besoins spécifiques de l'utilisateur.
          <br />
          <br />
          <img
            src={carte}
            alt="lecteur de carte mémoire et 3 cartes mémoires"
          />
          <br />
          <br />
          <br />
          <h3> Disque Dur Externe :</h3>
          Les disques durs externes sont des périphériques de stockage de
          données portables qui offrent un espace de stockage supplémentaire aux
          utilisateurs d'ordinateurs, leur permettant ainsi de sauvegarder,
          stocker et transporter une grande quantité de fichiers et de données.
          Les disques durs externes sont disponibles dans une gamme de capacités
          allant de quelques centaines de gigaoctets à plusieurs téraoctets,
          offrant ainsi aux utilisateurs une flexibilité pour choisir la
          capacité qui répond le mieux à leurs besoins en matière de stockage.
          Les disques durs externes se connectent à un ordinateur via différents
          ports, y compris les ports USB, les ports Thunderbolt et les ports
          eSATA, offrant ainsi une compatibilité avec une variété d'ordinateurs
          et de systèmes d'exploitation. Les disques durs externes peuvent être
          utilisés pour diverses applications telles que le stockage de
          sauvegardes, le stockage de fichiers multimédias tels que des photos,
          des vidéos et de la musique, le stockage de données professionnelles
          et le transfert de fichiers entre différents appareils. <br />
          <br />
          <img
            src={disquedur}
            alt="disque dur externe noir branché à un pc portable"
          />
          <br />
          <br />
          <br />
          <h3> Souris et Clavier Sans Fil :</h3>
          Les souris et claviers sans fil sont des périphériques d'entrée qui
          utilisent la technologie sans fil, telle que le Bluetooth ou la
          radiofréquence (RF), pour se connecter à l'ordinateur, offrant ainsi
          une flexibilité et une liberté de mouvement accrues aux utilisateurs.
          Les souris sans fil utilisent des capteurs optiques pour détecter les
          mouvements et les clics de l'utilisateur, offrant ainsi une expérience
          de navigation fluide et précise sans les contraintes des câbles. Les
          claviers sans fil offrent une expérience de frappe confortable et
          ergonomique, avec des touches réactives et une disposition familière,
          permettant aux utilisateurs de travailler à distance de leur
          ordinateur tout en conservant une connexion stable. Certains modèles
          de souris et claviers sans fil sont dotés de fonctionnalités
          supplémentaires telles que des boutons programmables, des molettes de
          défilement réglables, des touches de raccourci personnalisables et des
          repose-poignets ergonomiques pour une expérience d'utilisation
          améliorée. Les souris et claviers sans fil sont alimentés par des
          piles ou des batteries rechargeables, offrant ainsi une autonomie de
          plusieurs mois à plusieurs années selon l'utilisation, et peuvent être
          rechargés via des câbles USB ou des stations de recharge dédiées. Lors
          du choix d'une souris ou d'un clavier sans fil, il est important de
          prendre en compte des facteurs tels que la portée sans fil, la
          compatibilité avec les systèmes d'exploitation, l'ergonomie, la durée
          de vie de la batterie et les fonctionnalités spécifiques aux besoins
          de l'utilisateur.
          <br />
          <br />
          <img src={sansfil} alt="une souris et un clavier sans fil" />
          <br />
          <br />
          <br />
          <h3> Tablette Graphique :</h3>
          Les tablettes graphiques sont des périphériques d'entrée utilisés
          principalement par les professionnels de la création tels que les
          graphistes, les illustrateurs, les designers et les artistes
          numériques pour dessiner, esquisser et concevoir des graphiques
          directement sur l'écran de leur ordinateur. Les tablettes graphiques
          se composent d'un stylet sensible à la pression et d'une surface de
          dessin active, permettant aux utilisateurs de créer des dessins
          numériques précis avec des traits variés en fonction de la pression
          exercée sur le stylet. Les tablettes graphiques offrent une expérience
          de dessin naturelle et intuitive, similaire à celle du dessin sur
          papier, avec des fonctionnalités telles que la sensibilité à
          l'inclinaison du stylet, la rotation de la toile et la capacité de
          zoom pour des détails fins. Les tablettes graphiques peuvent être
          utilisées avec une variété de logiciels de création graphique tels que
          Adobe Photoshop, Illustrator, Corel Painter, Autodesk SketchBook, et
          bien d'autres, offrant ainsi aux utilisateurs une flexibilité dans le
          choix de leur environnement de travail créatif. Certains modèles de
          tablettes graphiques sont équipés de fonctionnalités supplémentaires
          telles que des touches de raccourci personnalisables, des molettes de
          défilement, des écrans tactiles intégrés et une connectivité sans fil
          pour une utilisation pratique et une productivité accrue. Lors du
          choix d'une tablette graphique, il est important de prendre en compte
          des facteurs tels que la taille de la surface de dessin, la
          résolution, la sensibilité à la pression, la compatibilité logicielle,
          ainsi que le budget et les besoins spécifiques de l'utilisateur en
          matière de création graphique.
          <br />
          <br />
          <img src={graphique} alt="tablette graphique noir avec son stylet" />
          <br />
          <br />
          <br />
          <h3> Joystick et Manettes de Jeu : </h3>
          Les joysticks et les manettes de jeu sont des périphériques d'entrée
          utilisés par les amateurs de jeux pour contrôler les actions et les
          mouvements des personnages et des véhicules dans les jeux vidéo,
          offrant ainsi une expérience de jeu immersive et interactive. Les
          joysticks sont des périphériques de contrôle qui se tiennent
          généralement à la main et permettent aux joueurs de contrôler les
          mouvements dans les jeux de simulation tels que les simulateurs de
          vol, les jeux de course et les jeux d'arcade, offrant ainsi une
          expérience de jeu plus réaliste et précise. Les manettes de jeu,
          également appelées gamepads, sont des périphériques de contrôle plus
          polyvalents qui offrent une gamme complète de boutons et de sticks
          analogiques pour contrôler les actions dans une variété de jeux vidéo,
          y compris les jeux de plateforme, d'action, de tir et de sport. Les
          joysticks et les manettes de jeu sont souvent dotés de fonctionnalités
          supplémentaires telles que des vibrations haptiques pour une
          rétroaction tactile immersive, des boutons programmables pour une
          personnalisation des commandes, et des gâchettes analogiques pour un
          contrôle précis de la vitesse et de la puissance. Certains modèles de
          joysticks et de manettes de jeu sont conçus spécifiquement pour une
          utilisation sur PC, offrant une compatibilité avec une variété de jeux
          PC et de plateformes de distribution de jeux telles que Steam, Epic
          Games Store et Origin. Lors du choix d'un joystick ou d'une manette de
          jeu, il est important de prendre en compte des facteurs tels que la
          compatibilité avec les jeux, le confort de prise en main, la précision
          de contrôle, la durabilité et les fonctionnalités spécifiques aux
          besoins du joueur. <br />
          <br />
          <img src={manette} alt="manette de jeu pour ordinateur" />
          <br />
          <br />
          <br />
          <h3>Hub USB :</h3>
          Les hubs USB sont des périphériques d'extension qui permettent
          d'ajouter plusieurs ports USB supplémentaires à un ordinateur, offrant
          ainsi une solution pratique pour connecter simultanément plusieurs
          périphériques USB tels que des claviers, des souris, des imprimantes,
          des lecteurs flash, des disques durs externes et plus encore. Les hubs
          USB sont disponibles dans une variété de configurations, y compris les
          hubs USB 2.0, USB 3.0 et USB-C, offrant ainsi une compatibilité avec
          différents types de périphériques USB et des vitesses de transfert de
          données plus rapides pour une expérience utilisateur améliorée.
          Certains hubs USB sont alimentés par le port USB de l'ordinateur
          lui-même, tandis que d'autres sont dotés d'une alimentation externe
          pour une puissance supplémentaire, permettant ainsi de connecter des
          périphériques USB qui nécessitent une alimentation électrique
          supplémentaire. Les hubs USB sont généralement compacts et portables,
          ce qui les rend idéaux pour une utilisation domestique, de bureau ou
          en déplacement, offrant ainsi une solution pratique pour étendre les
          capacités de connectivité d'un ordinateur portable ou d'un ordinateur
          de bureau. Lors du choix d'un hub USB, il est important de prendre en
          compte des facteurs tels que le nombre de ports USB disponibles, la
          vitesse de transfert de données, la compatibilité avec les
          périphériques USB, la robustesse de la construction et la conception
          ergonomique pour répondre aux besoins spécifiques de l'utilisateur.{" "}
          <br />
          <br />
          <img src={hub} alt="un hub usb branché à un ordinateur" />
          <br />
          <br />
          <br />
          <br />
          Ces périphériques diversifiés offrent une multitude de
          fonctionnalités, rendant l'informatique plus accessible, interactive
          et adaptable aux besoins spécifiques de chaque utilisateur.
        </p>
      </div>
      <QuizB />
    </div>
  );
};

export default Peripheriques;
