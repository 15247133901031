import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/profil.scss";
import { Link, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import defaultAvatar from "../media/man.png";
// import EditProfil from "../composants/EditProfil";
import Cours from "./cours";
import Avatar from "./avatar";
import Nav from "../composants/nav";
import QuizA from "../composants/quizA";
import Facebook from "./ReseauxSociaux/Facebook";
import Twitter from "./ReseauxSociaux/Twitter";
import Instagram from "./ReseauxSociaux/instagram";
import Accueil from "../composants/presentation";
import Seniors from "./Seniors";
import TopMemoire from "../composants/Jeux/TopMemoire";
import Couleurs from "../composants/Couleurs";
import Sudoku from "../composants/Jeux/Sudoku";
import Todolist from "../pages/todoList"
import TicTacToe from "../composants/Jeux/ticTacToe";
import HangmanGame from "../composants/Jeux/hangManGame";
import Blog from "./blog";
import Qrcode from "../composants/Qrcode";
import PseudonymGenerator from "../composants/Peudonyme";
import accueil from "../media/accueil.png";
import cours from "../media/cours.png";
import senior from "../media/senior.png";
import jeux from "../media/jeux.png";
import taches from "../media/taches.png";
import sociaux from "../media/sociaux.png";
import discord from "../media/discord.png";
import profil from "../media/profil.png";
import changer from "../media/changer.png";
import deconnecter from "../media/deconnecter.png";
import fermer from "../media/fermer.png";
import generateur from "../media/generateur.png";
import News from "../composants/News.jsx"
import souris from "../media/logoClicdeSouris.png";
const UserProfile = () => {
  const [data, setData] = useState({});
  // const [isSubscribed, setIsSubscribed] = useState(false);
  // const [error, setError] = useState(null);
  const { userId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [hoveredButton, setHoveredButton] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Récupérer le statut d'abonnement de l'utilisateur
//         const subscriptionResponse = await axios.get(`https://www.clicdesouris.fr/subscription/${userId}`);
//         const { isSubscribed } = subscriptionResponse.data;
//         setIsSubscribed(isSubscribed);

//   // Récupérer le rôle de l'utilisateur
//   const isAdminResponse = await fetch(`/api/users/${userId}`);
//   const userData = await isAdminResponse.json();
//   setIsAdmin(userData.role === "admin");
// } catch (error) {
//   console.error("Erreur lors de la récupération des données :", error);
// }
// };
//     fetchData();
//   }, [userId]);

//   useEffect(() => {
//   const checkSubscription = async () => {
//     try {
//       const response = await axios.get(`https://www.clicdesouris.fr/subscription/${userId}`);
//       const { isSubscribed } = response.data; // Supposons que votre API renvoie un objet avec une propriété isSubscribed
//       setIsSubscribed(isSubscribed);
//     } catch (error) {
//       // Gérer les erreurs de requête
//       console.error('Erreur lors de la vérification de l\'abonnement :', error);
//     }
//   };
//   checkSubscription();
//   },[userId])

  

//   const handleSubscription = async () => {
//     try {
//       // Envoyer une requête au serveur pour mettre à jour l'abonnement de l'utilisateur
//       const response = await axios.post(`https://www.clicdesouris.fr/subscription/${userId}`, {
//         userId: userId, // Supposons que userId est l'ID de l'utilisateur
//         subscriptionType: 'monthly' // Supposons que l'utilisateur s'abonne mensuellement
//       });
  
// //       // Vérifier si la requête a réussi
//       if (response.status === 200) {
//         // Mettre à jour l'état d'abonnement de l'utilisateur
//         setIsSubscribed(true);
//         // Autres logiques liées à l'abonnement (par exemple, afficher un message de confirmation)
//         console.log('L\'utilisateur est maintenant abonné !');
//       } else {
//         console.error('La requête d\'abonnement a échoué.');
//         // Gérer les erreurs de la requête d'abonnement
//       }
//     } catch (error) {
//       console.error('Erreur lors de l\'abonnement :', error);
//       // Gérer les erreurs de l'abonnement
//     }
//   };
  
//   useEffect(() => {
//     const fetchSubscriptionStatus = async () => {
//       try {
//         const response = await axios.get(`https://www.clicdesouris.fr/subscription/${userId}`);
//         const { isSubscribed } = response.data;
//         setIsSubscribed(isSubscribed);
//       } catch (error) {
//         console.error('Erreur lors de la récupération de l\'état d\'abonnement :', error);
//       }
//     };
  
//     fetchSubscriptionStatus();
//   }, [userId]);


  const [userData, setUserData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    pseudo: "",
    ville: "",
    systemexploit: "",
    id: userId,
  });

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    pseudo: "",
    ville: "",
    systemexploit: "",
    id: userId,
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const [authenticated, setAuthenticated] = useState(false);
  const [activeSection, setActiveSection] = useState("profil");
  const [choix, setChoix] = useState(
    localStorage.getItem("choix") || "presentation"
  );
  // Mise à jour du choix dans le stockage local lorsqu'il change
  useEffect(() => {
    localStorage.setItem("choix", choix);
  }, [choix]);
  const [isLoggedIn, setLoggedIn] = useState();

  
  // pour récupérer l'ID de l'utilisateur
  // useEffect(() => {
  //   if (userId) {
  //     // Effectuer une requête pour obtenir les données de l'utilisateur
  //     axios
  //       .get(`https://www.clicdesouris.fr/api/users/${userId}`)
  //       .then((response) => {
  //         // Traitement des données de réponse ici
  //         setData(response.data);
  //       })
  //       .catch((error) => {
  //         // Gestion des erreurs ici
  //         console.error("Erreur lors de la requête :", error);
  //       });
  //   }
  // }, [userId]); // <--- Ajouter userId comme dépendance
  // // Effectuer une requête pour vérifier si l'utilisateur est authentifié
  // useEffect(() => {
  //   axios.get(`https://www.clicdesouris.fr/api/check-auth`).then((response) => {
  //     setAuthenticated(response.data.authenticated);

  //     if (response.data.authenticated) {
  //       axios
  //         .get(`https://www.clicdesouris.fr/api/users/${userId}`)
  //         .then((response) => {
  //           const userRole = response.data.role;
  //           setIsAdmin(userRole === "admin");
  //         })
  //         .catch((error) => {
  //           setError("Erreur lors de la récupération du rôle de l'utilisateur");
  //           console.error("Erreur lors de la requête :", error);
  //         });

  //       // Récupérer les paramètres d'URL
  //       const queryParams = queryString.parse(location.search);
  //       const userParams = {
  //         email: queryParams.email,
  //         pseudo: queryParams.pseudo,
  //         ville: queryParams.ville,
  //         systemexploit: queryParams.systemexploit,
  //       };

  //       // Mettre à jour l'état des données utilisateur
  //       setData(userParams);
  //     }
  //   });
  // }, [userId, location.search]);

  useEffect(() => {
    const isAdmin =
      new URLSearchParams(location.search).get("admin") === "true";
    // Faites quelque chose avec la valeur isAdmin...
    console.log(
      `L'utilisateur avec l'ID ${userId} est un administrateur : ${isAdmin}`
    );
  }, [location.search, userId]);
  // Assurez-vous de mettre une dépendance vide pour que cela ne se déclenche qu'une seule fois au montage du composant

  axios.defaults.withCredentials = true;
// Effectuer une requête pour obtenir les données de l'utilisateur
// useEffect(() => {
//   axios
//     .get(`https://www.clicdesouris.fr/api/users/${userId}`)
//     .then((response) => {
//       // Vérifier le code d'état de la réponse
//       if (response.status === 200) {
//         // Récupération des données de l'utilisateur
//         const userData = response.data;

//         // Mise à jour de l'état
//         setUserData(userData);
//       } else {
//         // Afficher un message d'erreur
//         console.error("La requête HTTP a échoué.");
//       }
//     })
//     .catch((error) => {
//       // Afficher un message d'erreur
//       console.error(error);
//     });
// }, [userId]);
// axios
//     .get(`https://www.clicdesouris.fr/api/users/${userId}`)
//     .then((response) => {
//       const userRole = response.data.role;
//       setIsAdmin(userRole === "admin");
//     })
//     .catch((error) => {
//       setError("Erreur lors de la récupération du rôle de l'utilisateur");
//       console.error("Erreur lors de la requête :", error);
//     });
//   // Fonction pour formater une date au format "jour/mois/année"
//   const formatDate = (dateString) => {
//     const options = { year: "numeric", month: "numeric", day: "numeric" };
//     return new Date(dateString).toLocaleDateString(undefined, options);
//   };
//   useEffect(() => {
//     // Récupérez le rôle de l'utilisateur
//     const fetchIsAdmin = async () => {
//       const response = await fetch(`https://www.clicdesouris.fr/api/users/${userId}`);
//       const data = await response.json();

//       setIsAdmin(data.role === "admin");
//     };
//     fetchIsAdmin();
//   }, [userId]);

  // useEffect(() => {
  //   const adminUserId = localStorage.getItem("adminUserId");

  //   if (adminUserId) {
  //     axios
  //       .get(`https://www.clicdesouris.fr/api/users/${adminUserId}`)
  //       .then((response) => {
  //         setData(response.data);
  //       })
  //       .catch((error) => {
  //         console.error("Erreur lors de la requête :", error);
  //       });
  //   }
  // }, [userId]);

// pour récupérer le profil de l'utilisateur
// useEffect(() => {
//   if (userId) {
//     axios
//       .get(`https://www.clicdesouris.fr/api/users/${userId}`)
//       .then((response) => {
//         // Traitement des données de réponse ici
//         setData(response.data);
//       })
//       .catch((error) => {
//         // Gestion des erreurs ici
//         console.error("Erreur lors de la requête :", error);
//       });
//   }
// }, [userId]); // <--- Ajouter userId comme dépendance

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);

      // Enregistrez les données dans l'état ou faites ce que vous voulez avec elles
      setUserData((prevUserData) => ({
        ...prevUserData,
        profilePicture: file,
      }));
    }
  };

  const [file, setFile] = useState();
  const [datab, setDatab] = useState([]);

  // PREND LE CONTENU DE L'INPUT FILE
  const handleFile = (event) => {
    setFile(event.target.files[0]);
  };

  // MODIFIER L'AVATAR DANS LA BASE
  // const handleUpload = () => {
  //   console.log(file.name);
  //   const formdata = new FormData();
  //   formdata.append("img", file);

  //   axios
  //     .post(`https://www.clicdesouris.fr/uploads/${userId}`, formdata)
  //     .then((res) => {
  //       if (res.data.Status === "Success") {
  //         console.log("Success for avatar");
  //         window.location.reload();
  //       } else {
  //         console.log("Failed for avatar");
  //       }
  //     })
  //     .catch((err) => console.log(err));
  // };

  // // AFFICHER PHOTO
  // useEffect(() => {
  //   const userId = localStorage.getItem("userId");
  //   axios
  //     .get(`https://www.clicdesouris.fr/avatar/${userId}`)
  //     .then((res) => {
  //       setDatab(res.data[0]);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const [isFlipped, setFlipped] = useState(false);

  const handleFlip = () => {
    setFlipped(!isFlipped);
  };

  // Gestionnaire d'événements pour changer de section
  const handleSectionChange = (section) => {
    setActiveSection(section);
  };

  const handleLogout = () => {
    localStorage.removeItem("adminUserId");
    setLoggedIn(false);
    // Redirection vers la page d'accueil

    navigate("/");
  };
  // Fonction pour clôturer le compte
  // const closeAccount = () => {
  //   // Demandez une confirmation de l'utilisateur
  //   const confirmClose = window.confirm(
  //     "Êtes-vous sûr de vouloir clôturer votre compte ?"
  //   );

  //   if (confirmClose) {
  //     console.log("userId:", userId);
  //     // Envoyer une requête au serveur pour clôturer le compte
  //     axios
  //       .delete(`https://www.clicdesouris.fr/api/cloture/${userId}`)
  //       .then((response) => {
  //         // La clôture du compte a réussi
  //         // Rediriger l'utilisateur vers la page d'accueil ou une page de confirmation
  //         navigate("/");
  //       })
  //       .catch((error) => {
  //         // Gestion des erreurs ici
  //         console.error("Erreur lors de la clôture du compte :", error);
  //       });
  //   }
  // };

  // Exemple imaginaire de suppression des données du compte
  // const deleteAccount = async (userId) => {
  //   try {
  //     // Supprimer toutes les données associées à l'utilisateur de la base de données
  //     setUserData(userId);

  //     // Ensuite, supprimer le compte utilisateur lui-même
  //     await deleteAccount(userId);
  //   } catch (error) {
  //     console.error("Erreur lors de la suppression du compte :", error);
  //   }
  // };

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };
   const hasToken = localStorage.getItem("token") !== null;

  const Button = ({ onClick, children, title }) => {
    return (
      <div>
        <button className="custom-button" onClick={onClick} title={title}>
          {children}{" "}
        </button>
      </div>
    );
  };
  // const isConnected = () => {
  //   const token = localStorage.getItem("token");
  //   return token !== null;
  // };

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuJeu, setMenuJeu] = useState(false);
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const toggleJeu = () => {
    setMenuJeu(!menuJeu);
  };
const [menuQrcode, setMenuQrcode] = useState(false);
const [menuGénérateur, setMenuGénérateur] = useState(false);
  const toggleGénérateur = () => {
    setMenuGénérateur(!menuGénérateur);
    };
  
    return (
      <div className="user-profile">
        <div>
          {/* <Nav isConnected={isConnected} /> */}
          <Nav /> 
        
        </div>
        <div className="container-profil">
          <div className=" profile-container">
            {/* {activeSection === "profil" && ( */}
              <div className="profile-content">
                {/* <div className="profile_img text-center p-4">
                  <div className="flex flex-column justify-content-center align items-center">
                    <div
                      className={`profile-top ${isFlipped ? "flip" : ""}`}
                      onClick={handleFlip}
                    >
                      <div className="xs={6} md={4} profile-image">
                        <img
                          className={`profile-image ${isFlipped ? "rotate" : ""}`}
                          src={
                            data.profilepicture
                              ? `https://www.clicdesouris.fr/img/${data.profilepicture}`
                              : defaultAvatar
                          }
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* {userData && ( */}
                {/* <div className="data-profil">
                  <p>
                   
                    <span
                      className="spamb"
                      value={formData.pseudo}
                      onChange={handleInputChange}
                    >
                      {" "}
                      {data.pseudo}
                    </span>
                  </p>
  
                  <p>
                    
                    <span
                      className="spamb"
                      value={formData.ville}
                      onChange={handleInputChange}
                    >
                      {data.ville}
                    </span>
                  </p>
                  <p>
                    
                    <span
                      className="spamb"
                      value={formData.systemexploit}
                      onChange={handleInputChange}
                    >
                      {data.systemexploit}
                    </span>
                  </p>
                </div> */}
                 {/* )} */}
                {isAdmin ? (
                  <div className="btn-container">
                    <Link
                      className="btn btn-outline-success"
                      to={`/adminPanel/${userId}`}
                      tabIndex={0}
                      onClick={() => navigate(`/adminPanel/${userId}`)}
                    >
                      Admin Panel
                    </Link>
                    {/* <button
                      className={`btn ${
                        hoveredButton === "EditProfil" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("EditProfil")}
                      onMouseEnter={() => setHoveredButton("EditProfil")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="ajouter un profil"
                    >
                      Modifier Profil
                      {hoveredButton === "EditProfil" && (
                        <div className="arrow"></div>
                      )}
                    </button>
                    <button
                      className={`btn ${
                        hoveredButton === "Avatar" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Avatar")}
                      onMouseEnter={() => setHoveredButton("Avatar")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="avec une photo c'est plus fun !"
                    >
                      Modifier Avatar
                      {hoveredButton === "Avatar" && (
                        <div className="arrow"></div>
                      )}
                    </button> */}
                    <button
                      className={`btn ${
                        hoveredButton === "Cours" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Cours")}
                      onMouseEnter={() => setHoveredButton("Cours")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Bon courage!"
                    >
                      Mes cours
                      {hoveredButton === "Cours" && <div className="arrow"></div>}
                    </button>
                    <button
                      className={`btn ${
                        hoveredButton === "TopMemoire" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("TopMemoire")}
                      onMouseEnter={() => setHoveredButton("TopMemoire")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="A vos méninges !"
                    >
                      Jeu de mémoire
                      {hoveredButton === "TopMemoire" && (
                        <div className="arrow"></div>
                      )}
                    </button>
                    <button
                      className={`btn ${
                        hoveredButton === "Seniors" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Seniors")}
                      onMouseEnter={() => setHoveredButton("Seniors")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Ca peut vous intéresser!"
                    >
                      Sites utiles pour Séniors"
                      {hoveredButton === "Seniors" && (
                        <div className="arrow"></div>
                      )}
                    </button>
                    <button
                      className={`btn ${
                        hoveredButton === "Blog" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Blog")}
                      onMouseEnter={() => setHoveredButton("Blog")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Ca peut vous intéresser!"
                    >
                      Blog d'actualités
                      {hoveredButton === "Blog" && (
                        <div className="arrow"></div>
                      )}
                    </button>
  
                    <div className="reseau-sociaux-container">
                      <button
                        onClick={toggleMenu}
                        className="reseau-sociaux-button"
                      >
                        Réseaux Sociaux
                      </button>
                      <div className="reseau-sociaux-container">
                        {menuVisible && (
                          <div className="reseau-sociaux-menu">
                            <button
                              className={`btn ${
                                hoveredButton === "Facebook" ? "hovered" : ""
                              }`}
                              onClick={() => setChoix("Facebook")}
                              onMouseEnter={() => setHoveredButton("Facebook")}
                              onMouseLeave={() => setHoveredButton("")}
                              title="Avec une photo c'est plus fun!"
                            >
                              Facebook
                              {hoveredButton === "Facebook" && (
                                <div className="arrow"></div>
                              )}
                            </button>
                            <button
                              className={`btn ${
                                hoveredButton === "Twitter" ? "hovered" : ""
                              }`}
                              onClick={() => setChoix("Twitter")}
                              onMouseEnter={() => setHoveredButton("Twitter")}
                              onMouseLeave={() => setHoveredButton("")}
                              title="Avec une photo c'est plus fun!"
                            >
                              Twitter
                              {hoveredButton === "Twitter" && (
                                <div className="arrow"></div>
                              )}
                            </button>
                            <button
                              className={`btn ${
                                hoveredButton === "Instagram" ? "hovered" : ""
                              }`}
                              onClick={() => setChoix("Instagram")}
                              onMouseEnter={() => setHoveredButton("Instagram")}
                              onMouseLeave={() => setHoveredButton("")}
                              title="Avec une photo c'est plus fun!"
                            >
                              Instagram
                              {hoveredButton === "Instagram" && (
                                <div className="arrow"></div>
                              )}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
  
                    <Link to="https://discord.gg/NWura4Hn" rel="noopener noreferrer" target="_blank">
                      <Button
                        className={`btn ${
                          hoveredButton === "Discord" ? "hovered" : ""
                        }`}
                        onClick={() => setChoix("Discord")}
                        onMouseEnter={() => setHoveredButton("Discord")}
                        onMouseLeave={() => setHoveredButton("")}
                        title="Pour discuter et s'entraider"
                      >
                        Discord
                        {hoveredButton === "Discord" && (
                          <div className="arrow"></div>
                        )}
                      </Button>
                    </Link>
                    <button
                      className={`btn ${
                        hoveredButton === "Deconnecter" ? "hovered" : ""
                      }`}
                      onMouseEnter={() => setHoveredButton("Deconnecter")}
                      onMouseLeave={() => setHoveredButton("")}
                      onClick={handleLogout}
                    >
                      Me déconnecter
                      {hoveredButton === "Deconnecter" && (
                        <div className="arrow"></div>
                      )}
                    </button>
                  </div>
                ) : (
                  <div className="btn-containerA">
                    <button
                      className={`btn ${
                        hoveredButton === "Accueil" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Accueil")}
                      onMouseEnter={() => setHoveredButton("Accueil")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Bonjour !"
                    >
                       <img className="accueil" src={accueil}  alt="logo Accueil" />
                      {hoveredButton === "Accueil" && (
                        <div className="arrow"></div>
                      )}
                    </button>
                    <button
                      className={`btn ${
                        hoveredButton === "Cours" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Cours")}
                      onMouseEnter={() => setHoveredButton("Cours")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Bon courage !"
                    >
                      <img className="cours" src={cours}  alt="logo Cours" />
                      {hoveredButton === "Cours" && <div className="arrow"></div>}
                    </button>
                    <button
                      className={`btn ${
                        hoveredButton === "Seniors" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Seniors")}
                      onMouseEnter={() => setHoveredButton("Seniors")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Ca peut vous intéresser!"
                    >
                      <img className="senior" src={senior}  alt="logo Senior" />
                      {hoveredButton === "Seniors" && (
                        <div className="arrow"></div>
                      )}
                    </button>
                    
                    
                    <div className="jeux-container">
                      
                      <button className={`btn ${
                        hoveredButton === "Jeu" ? "hovered" : ""
                      }`} onClick={toggleJeu}
                      onMouseEnter={() => setHoveredButton("Jeu")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Entraînez votre cerveau!" >
                       <img className="jeux" src={jeux}  alt="logo Jeux" />
                        {hoveredButton === "Jeu" && (
                        <div className="arrow"></div>
                      )}
                      </button>
                     
                        <>
                      {menuJeu && (
                        <div className="jeux-menu">
                          
                          <button
                            className={`btnJeu ${
                              hoveredButton === "TopMemoire" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("TopMemoire")}
                            onMouseEnter={() => setHoveredButton("TopMemoire")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="A vos méninges!"
                          >
                            Jeu de mémoire(Pokemons)
                            {hoveredButton === "TopMemoire" && (
                              <div className="arrow"></div>
                            )}
                          </button>
  
                          <button
                            className={`btnJeu ${
                              hoveredButton === "Couleurs" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("Couleurs")}
                            onMouseEnter={() => setHoveredButton("Couleurs")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="A vos méninges!"
                          >
                            Jeu de mémoire(Couleurs)
                            {hoveredButton === "TopMemoire" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                          <button
                            className={`btnJeu ${
                              hoveredButton === "Sudoku" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("Sudoku")}
                            onMouseEnter={() => setHoveredButton("Sudoku")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="A vos méninges!"
                          >
                            Sudoku
                            {hoveredButton === "Sudoku" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                          <button
                            className={`btnJeu ${
                              hoveredButton === "TicTacToe" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("TicTacToe")}
                            onMouseEnter={() => setHoveredButton("TicTacToe")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="A vos marques!"
                          >
                            TicTacToe
                            {hoveredButton === "TicTacToe" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                          <button
                            className={`btnJeu ${
                              hoveredButton === "HangmanGame" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("HangmanGame")}
                            onMouseEnter={() => setHoveredButton("HangmanGame")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="A vos marques!"
                          >
                            Le Pendu
                            {hoveredButton === "HangmanGame" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                          
                         
                         </div>
        )}
      </>
    
    
  </div>
   <div className="generateur-container">
                      
                      <button className={`btn ${
                        hoveredButton === "Générateur" ? "hovered" : ""
                      }`}
                       onClick={toggleGénérateur}
                      onMouseEnter={() => setHoveredButton("Générateur")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Créez vous même!" >
                        <img className="generateur" src={generateur}  alt="logo Générateur" />
                        {hoveredButton === "Générateur" && (
                        <div className="arrow"></div>
                      )}
                      </button> 
  
   
    <>
     { menuGénérateur && (
    <div className="générateur-menu">
   <button
                          className={`btngénérateur ${
                            hoveredButton === "Qrcode" ? "hovered" : ""
                          }`}
                          onClick={() => setChoix("Qrcode")}
                          onMouseEnter={() => setHoveredButton("Qrcode")}
                          onMouseLeave={() => setHoveredButton("")}
                          title="QRCode Perso!"
                        >
                          Générateur de Qrcode
                          {hoveredButton === "Qrcode" && (
                            <div className="arrow"></div>
                          )}
                        </button>
   <button
                          className={`btngénérateur ${
                            hoveredButton === "Pseudo" ? "hovered" : ""
                          }`}
                          onClick={() => setChoix("Pseudo")}
                          onMouseEnter={() => setHoveredButton("Pseudo")}
                          onMouseLeave={() => setHoveredButton("")}
                          title="Pseudo Perso!"
                        >
                          Générateur de Pseudo
                          {hoveredButton === "Pseudo" && (
                            <div className="arrow"></div>
                          )}
                        </button>
                        </div>
    )}
    </>
  
  </div> 
                    <button
                      className={`btn ${
                        hoveredButton === "TodoList" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("TodoList")}
                      onMouseEnter={() => setHoveredButton("TodoList")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Tâches à faire!"
                    >
                       <img className="taches" src={taches}  alt="logo To do list" />
                      {hoveredButton === "TodoList" && (
                        <div className="arrow"></div>
                      )}
                    </button>
  
                    <div className="reseau-sociaux-container">
                      <button
                      className={`btn ${
                        hoveredButton === "Reseaux" ? "hovered" : ""
                      }`}
                      onMouseEnter={() => setHoveredButton("Reseaux")}
                      onMouseLeave={() => setHoveredButton("")}
                        onClick={toggleMenu}
                        title="Découverte des Réseaux Sociaux"
                      >
                         <img className="sociaux" src={sociaux}  alt="logo Réseaux sociaux" />
                        {hoveredButton === "Reseaux" && (
                        <div className="arrow"></div>
                      )}
                      </button>
                      {menuVisible && (
                        <div className="reseau-sociaux-menu">
                          <button
                            className={`btnReseaux ${
                              hoveredButton === "Facebook" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("Facebook")}
                            onMouseEnter={() => setHoveredButton("Facebook")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="Avec une photo c'est plus fun!"
                          >
                            Facebook
                            {hoveredButton === "Facebook" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                          <button
                            className={`btnReseaux ${
                              hoveredButton === "Twitter" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("Twitter")}
                            onMouseEnter={() => setHoveredButton("Twitter")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="Avec une photo c'est plus fun!"
                          >
                            Twitter
                            {hoveredButton === "Twitter" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                          <button
                            className={`btnReseaux ${
                              hoveredButton === "Instagram" ? "hovered" : ""
                            }`}
                            onClick={() => setChoix("Instagram")}
                            onMouseEnter={() => setHoveredButton("Instagram")}
                            onMouseLeave={() => setHoveredButton("")}
                            title="Avec une photo c'est plus fun!"
                          >
                            Instagram
                            {hoveredButton === "Instagram" && (
                              <div className="arrow"></div>
                            )}
                          </button>
                        </div>
                      )}
                    </div>
  <div className="ligne"></div>
                    <Link to="https://discord.gg/NWura4Hn" rel="noopener noreferrer" target="_blank">
                      <button
                        className={`btn ${
                          hoveredButton === "Discord" ? "hovered" : ""
                        }`}
                        onClick={() => setChoix("Discord")}
                        onMouseEnter={() => setHoveredButton("Discord")}
                        onMouseLeave={() => setHoveredButton("")}
                        title="Pour discuter et s'entraider"
                      >
                        <img className="discord" src={discord}  alt="logo Discord" />
                        {hoveredButton === "Discord" && (
                          <div className="arrow"></div>
                        )}
                      </button>
                    </Link>
                      {/* <button
                      className={`btn ${
                        hoveredButton === "EditProfil" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("EditProfil")}
                      onMouseEnter={() => setHoveredButton("EditProfil")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Ajoutez un profil"
                    >
                       <img className="profil" src={profil}  alt="logo Profil" />
                      {hoveredButton === "EditProfil" && (
                        <div className="arrow"></div>
                      )}
                    </button>
  
                    <button
                      className={`btn ${
                        hoveredButton === "Avatar" ? "hovered" : ""
                      }`}
                      onClick={() => setChoix("Avatar")}
                      onMouseEnter={() => setHoveredButton("Avatar")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Avec une photo c'est plus fun!"
                    >
                     <img className="changer" src={changer}  alt="logo Avatar" />
                      {hoveredButton === "Avatar" && (
                        <div className="arrow"></div>
                      )}
                    </button>  */}
                    <div className="ligne"></div>
                    <button
                      className={`btn ${
                        hoveredButton === "Deconnecter" ? "hovered" : ""
                      }`}
                      onMouseEnter={() => setHoveredButton("Deconnecter")}
                      onMouseLeave={() => setHoveredButton("")}
                      onClick={handleLogout}
                      title="Déconnexion !"
                    >
                      <img className="deconnecter" src={deconnecter}  alt="logo Déconnecter" />
                      {hoveredButton === "Deconnecter" && (
                        <div className="arrow"></div>
                      )}
                    </button>
  
                    {/* <button
                      className={`btn ${
                        hoveredButton === "Cloturer" ? "hovered" : ""
                      }`}
                      onClick={closeAccount}
                      onMouseEnter={() => setHoveredButton("Cloturer")}
                      onMouseLeave={() => setHoveredButton("")}
                      title="Supprimer mon compte"
                    >
                      <img className="fermer" src={fermer}  alt="logo Fermer le compte" />
                      {hoveredButton === "Cloturer" && (
                        <div className="arrow"></div>
                      )}
                    </button>  */}
                  </div>
                )}
              </div>
            
          </div>
        
        </div>
  
        <div className="choix">
          {choix === "Accueil" && <Accueil userId={userId} />}
          {/* {choix === "EditProfil" && <EditProfil userId={userId} />} */}
          {choix === "Avatar" && <Avatar userId={userId} />}
          {choix === "Cours" && <Cours userId={userId} />}
          {choix === "QuizA" && <QuizA userId={userId} />}
          {choix === "Facebook" && <Facebook />}
          {choix === "Twitter" && <Twitter />}
          {choix === "Instagram" && <Instagram />}
          {choix === "Seniors" && <Seniors userId={userId} />}
          {choix === "TopMemoire" && <TopMemoire userId={userId} />}
          {choix === "Couleurs" && <Couleurs userId={userId} />}
          {choix === "Sudoku" && <Sudoku userId={userId} />}
          {choix === "TodoList" && <Todolist userId={userId} />}
          {choix === "TicTacToe" && <TicTacToe userId={userId} />}
          {choix === "HangmanGame" && <HangmanGame userId={userId} />}
          {choix === "Blog" && <Blog userId={userId} />}
          {choix === "Qrcode" && <Qrcode userId={userId} />}
          {choix === "Pseudo" && <PseudonymGenerator userId={userId} />}
  
        </div>
        
      </div>
  );
};

export default UserProfile;