import React, { useState } from 'react';

const PseudonymGenerator = () => {
  const [generatedPseudonym, setGeneratedPseudonym] = useState('');

  const generatePseudonym = () => {
    // Liste de préfixes et suffixes pour les pseudonymes
    const prefixes = ['Cool', 'Super',  'Maudit', 'Athé', 'SuperStar', 'Sympathique', 'Lapin', 'Melon', 'Saucisson', 'Prêtre', 'Champignon', 'Etalon', 'Souris', 'Etranger', 'Voyageur', 'Rescapé', 'Oracle', 'Affreux',  'Maître', 'Lion', 'Patate', 'Guépard', 'Agneau',  'Ninja', 'Maître','Chaton', 'Chimiste',   'Champion', 'Géant', 'Nain', 'Espion', 'Singe', 'Ouistiti',  'Cochon', 'Cassoulet', 'Cheveux', 'Blaireau', 'LePrésident', 'Phoque', 'Humain', 'Pervers', 'Dingue', 'Raisin',];
    const suffixes = [ 'Gamer', 'Bizarre', 'Inquiétant', 'Suspicieux', 'Mystère',  'Masqué', 'Boutonneux',  'Fier', 'Charmant',  'Monstrueux',  'Jaune', 'Gris', 'Arc-en-ciel', 'PasBeau', 'Rigolo', 'SansDent', 'Grincheux',  'GrosNez', 'Poilue', 'Enervé', 'Silencieux', 'Chauve', 'Joyeux', 'Enrhumé', 'Paresseux',  'SansCerveau', 'Nain', 'Géant', "D'Or", 'Puant', 'Argenté', 'Salé',  'Sauvage', ];

    // Sélection aléatoire d'un préfixe et d'un suffixe
    const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];
    const randomSuffix = suffixes[Math.floor(Math.random() * suffixes.length)];

    // Création du pseudonyme
    const pseudonym = `${randomPrefix}${randomSuffix}`;

    // Mise à jour de l'état avec le pseudonyme généré
    setGeneratedPseudonym(pseudonym);
  };

  return (
    <div className='pseudo-container'>
      <h2>Générateur de pseudonymes</h2>
      <button onClick={generatePseudonym}>Générer un pseudonyme</button>
      {generatedPseudonym && (
        <p>Pseudonyme généré : <span>{generatedPseudonym}</span></p>
      )}
    </div>
  );
};

export default PseudonymGenerator;
