import React from "react";
import "../styles/service.scss";
import Footer from "../composants/footer";
import device from "../media/device-imac.png";
import web from "../media/world-www.png";
import wechat from "../media/brand-wechat.png";
import lock from "../media/cloud-lock-open.png";
import stretch from "../media/align-box-right-stretch.png";
import wordpress from "../media/brand-wordpress.png";
import { Helmet } from "react-helmet-async";
import Nav from "../composants/nav";


const Service = ({isConnected}) => {
  return (
    <>
      <Helmet>
        <title>Clic de souris - Services </title>
      </Helmet>

      <section className="services-page">
        <div className="header">
         
          <Nav isConnected={isConnected}/>
        </div>
        <div className="container-service">
          <div className="contentservice">
            <h2>Services proposés</h2>
            <ul id="services-list">
              <li className="liservice">
                
               <h3><img src={device} alt="Icône Service 1" /> Cours d'informatique de base</h3>
                <p>Je vous guiderai à travers les fondamentaux de l'informatique, depuis le démarrage de l'ordinateur jusqu'à la manipulation de la souris, la maîtrise du clavier, la création de fichiers, et le stockage de photos, entre autres compétences essentielles.</p>
              </li>
              <li className="liservice">
                
                <h3><img src={web} alt="Icône Service 2" />Formation à la navigation sur Internet</h3>
                <p>Aujourd'hui, pratiquement 100% des utilisateurs d'ordinateurs accèdent à Internet. Il devient donc essentiel d'acquérir les compétences nécessaires pour naviguer efficacement sur le web, ainsi qu'éviter les pièges de la toile !</p>
              </li>
             
              <li className="liservice">
                
                <h3><img src={wechat} alt="Icône Service 3" />Utilisation des réseaux sociaux</h3>
                <p>En dehors des sites classiques, je vais vous présenter des plateformes qui vous offriront une expérience divertissante tout en favorisant la création de liens avec d'autres personnes. Ces sites sont dédiés à des activités et des jeux, offrant ainsi une opportunité de socialiser et de rompre avec l'ennui.</p>
              </li>
              
              <li className="liservice">
                
                <h3><img src={lock} alt="Icône Service 4" />Contrôler les mots de passe</h3>
                <p>Je vais vous apprendre à composer, gérer, et stocker vos mots de passe.</p>
              </li>
              
              <li className="liservice">
                
               <h3><img src={stretch} alt="Icône Service 5" />Et bien plus encore...</h3>
                
              </li><br /><br />
           <li className="liservice">
                
               <h3><img src={wordpress} alt="Icône Service 6" />Création de sites web</h3>
                <p>Si vous recherchez un site web pour votre entreprise, votre association ou à titre personnel, je vous offre mes services pour le concevoir selon vos besoins et préférences.</p>
              </li>
               </ul>
          </div>

        </div>
        
      </section>
    </>
  );
};

export default Service;
