import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet-async';
import QuizF from "../../../composants/quizF";
import avast from "../../../media/avast.png";
import avg from "../../../media/AVG-AntiVirus.png";
import avira from "../../../media/Avira.png";
import  bitdefender from "../../../media/bitdefender.png";
import sophos from "../../../media/sophos.png";
import kaspersky from "../../../media/kaspersky.jpg";
import microsoft from "../../../media/Microsoft.png";
import panda from "../../../media/panda.png";
import dashlane from "../../../media/dashlane.png";
import lastpass from "../../../media/lastpass.png";
import norton from "../../../media/norton.png";
import keepass from "../../../media/keepass.png";
import bitwarden from "../../../media/bitwarden.png";

const Sécurité = () => {
    const { userId } = useParams();
  const [data, setData] = useState({});
   const [isSubscribed, setIsSubscribed] = useState (false);
   useEffect(() => {
      // Vérifier l'état d'abonnement de l'utilisateur
      const checkSubscription = async () => {
        try {
          const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
          setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
          console.error('Erreur lors de la vérification de l\'abonnement :', error);
        }
      };
  
      checkSubscription();
    }, [userId]);
    
    useEffect(() => {
      axios
        .get(`https://www.clicdesouris.fr/api/users/${userId}`)
        .then((response) => {
          // Traitement des données de réponse ici
          console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          // Gestion des erreurs ici
          console.error("Erreur lors de la requête :", error);
        });
    }, [userId]);
    return (
        <>
      <Helmet>
        <title>Cours Sécurité Informatique - Clic de souris</title>
      </Helmet>
        <div className='securite-container'>
           
            <div className='securite-content'>
                <h1>Sécurité Informatique : Protéger vos données et votre vie privée en ligne</h1>
                <p>La sécurité informatique est essentielle pour protéger vos données personnelles, votre identité et votre vie privée en ligne. Dans cet exposé, nous aborderons les bases de la sécurité informatique, y compris la protection contre les logiciels malveillants, la création de mots de passe sécurisés, la navigation sécurisée sur Internet et la sensibilisation aux escroqueries en ligne.</p>
                <h2>Protection contre les logiciels malveillants</h2>
                <p>Les logiciels malveillants, tels que les virus, les vers, les chevaux de Troie et les logiciels espions, représentent une menace majeure pour la sécurité informatique. Pour se protéger contre les logiciels malveillants, il est important d'installer et de maintenir à jour un programme antivirus fiable. Un logiciel antivirus détecte et supprime les logiciels malveillants, ainsi que les fichiers suspects, protégeant ainsi votre ordinateur contre les menaces en ligne.</p>
                <h3>Liste de Logiciels antivirus</h3>
                <p>Je vous ai préparé une liste de <strong>logiciels antivirus</strong> gratuits à l'heure ou je fais ce cours. Pour certains, seulement une partie du logiciel est gratuit.</p>
                <img className="avast" src={avast} alt="boitier cd installation avast" />
               <Link to={"https://www.avast.com/fr-fr/free-antivirus-download#pc"} target="_blanck"><strong>Avast Free Antivirus :</strong></Link> <p> Avast est l'un des antivirus gratuits les plus populaires. Il offre une protection complète contre les virus, les logiciels malveillants et les ransomwares.</p>
                <img className="avg" src={avg} alt="boitier avg antivirus" />
               <Link to={"https://www.avg.com/fr-fr/free-antivirus-download#pc"} target="_blanck"> <strong>AVG AntiVirus Free :</strong></Link><p> Faisant partie de la famille Avast, AVG propose une protection antivirus de base gratuite avec des fonctionnalités supplémentaires telles que la protection contre les ransomwares et les analyses en temps réel.</p>
                <img className="avira" src={avira} alt="boitier avira antivirus" />
                <Link to={"https://www.avira.com/fr/free-security"} target="_blanck"><strong>Avira Free Security :</strong></Link><p> Avira offre une suite complète de sécurité gratuite comprenant un antivirus, une protection contre les logiciels malveillants, une protection de la vie privée, un VPN et bien plus encore.</p>
                <img className="bitdefender" src={bitdefender} alt="boitier bitdefender antivirus" />
                <Link to={"https://www.bitdefender.fr/" } target="_blanck"><strong>Bitdefender Antivirus Free Edition :</strong></Link><p> Bitdefender est reconnu pour sa puissante protection antivirus et son faible impact sur les performances du système. Sa version gratuite offre une protection de base contre les virus et les logiciels malveillants.</p>
                <img className="sophos" src={sophos} alt="boitier sophos antivirus" />
                <Link to={"https://www.sophos.com/fr-fr"} target="_blanck"><strong>Sophos Home Free :</strong></Link><p> Sophos Home Free offre une protection antivirus de qualité professionnelle pour un usage domestique. Il comprend une protection en temps réel, une analyse automatique des menaces et une protection contre les ransomwares.</p>
                <img className="kaspersky" src={kaspersky} alt="boitier kaspersky antivirus" />
                <Link to={"https://www.kaspersky.fr/"} target="_blanck"><strong>Kaspersky Security Cloud Free :</strong></Link><p> Kaspersky propose une version gratuite de sa suite de sécurité Cloud, offrant une protection de base contre les virus, les ransomwares et les menaces en ligne, ainsi qu'une analyse automatique des vulnérabilités du système.</p>
                <img src={microsoft} alt="logo microsoft defender" />
                <Link to={"https://support.microsoft.com/fr-fr/windows/rester-prot%C3%A9g%C3%A9-avec-s%C3%A9curit%C3%A9-windows-2ae0363d-0ada-c064-8b56-6a39afb6a963"} target="_blanck"><strong>Microsoft Defender Antivirus :</strong></Link><p> Intégré à Windows 10, Microsoft Defender Antivirus offre une protection de base contre les virus et les logiciels malveillants. Bien qu'il soit préinstallé sur Windows, il peut également être téléchargé gratuitement sur d'autres versions de Windows.</p>
                <img className="panda" src={panda} alt="boitier panda antivirus" />
               <Link to={"https://www.pandasecurity.com/fr/homeusers/free-antivirus/"} target="_blank"><strong>Panda Free Antivirus :</strong></Link> <p> Panda propose une version gratuite de son antivirus avec une protection contre les virus, les logiciels malveillants et les ransomwares, ainsi que des fonctionnalités supplémentaires telles que la protection USB et la protection Wi-Fi.</p>
                <p>Ces logiciels antivirus gratuits sont largement utilisés et recommandés pour fournir une protection de base contre les menaces en ligne. N'oubliez pas de maintenir votre logiciel antivirus à jour et d'effectuer régulièrement des analyses pour garantir une protection maximale.</p>
                <h2>Création de mots de passe sécurisés</h2>
                <p>Les mots de passe jouent un rôle crucial dans la sécurité en ligne, car ils protègent l'accès à vos comptes et à vos informations personnelles. Pour créer des mots de passe sécurisés, suivez ces bonnes pratiques :</p>
                <p> .Utilisez des mots de passe longs et complexes, composés de lettres majuscules et minuscules, de chiffres et de caractères spéciaux.
                    .Évitez d'utiliser des mots de passe évidents, tels que des mots du dictionnaire ou des informations personnelles facilement accessibles.
                    .Utilisez un gestionnaire de mots de passe pour stocker et générer des mots de passe uniques pour chaque compte en ligne.</p>
                    <p>Je vous ai préparé une liste de Générateur de mots de passe. Essayez celui qui vous convient et surtout cela vous donnera de bonnes idées de mots sécurisés. Ils sont gratuits avec pour certains des options payantes.</p>
                    <img src={dashlane}  alt="dashlane logo"/>
                    <p><strong><Link to="https://www.dashlane.com/fr/features/password-generator" target="_blank">Générateur de Mots de Passe de Dashlane :</Link></strong> Dashlane propose un générateur de mots de passe intégré dans son gestionnaire de mots de passe. Il génère des mots de passe sécurisés selon vos préférences et les enregistre automatiquement.</p>
                    <img src={lastpass} alt="lastpass logo" />
                    <p><strong><Link to="https://www.lastpass.com/fr/features/password-generator" target="_blank">Générateur de Mots de Passe de LastPass :</Link></strong> LastPass propose également un générateur de mots de passe qui peut créer des mots de passe forts et uniques pour chaque compte. Il est disponible dans l'interface de LastPass et peut être utilisé gratuitement.</p>
                    <img src={norton} alt="norton logo" />
                    <p><strong><Link to="https://support.norton.com/sp/fr/fr/home/current/solutions/v130586296" target="_blank">Générateur de Mots de Passe de Norton :</Link></strong> Norton Password Generator est un outil en ligne gratuit qui génère des mots de passe sécurisés en fonction de critères personnalisables. Il est disponible en français et peut être utilisé sans inscription.</p>
                    <img src={keepass} alt="keepass logo" />
                    <p><strong><Link to="https://keepass.info/help/base/pwgenerator.html" target="_blank">Générateur de Mots de Passe de KeePass :</Link></strong> KeePass est un gestionnaire de mots de passe open source qui propose un générateur de mots de passe intégré. Il peut être utilisé pour créer des mots de passe forts et aléatoires selon vos préférences.</p>
                    <img src={bitwarden} alt="bitwarden logo" />
                    <p><strong><Link to="https://bitwarden.com/password-generator/" target="_blank">Générateur de Mots de Passe de Bitwarden :</Link></strong> Bitwarden propose également un générateur de mots de passe intégré dans son gestionnaire de mots de passe. Il permet de créer des mots de passe sécurisés selon vos critères et est disponible gratuitement.</p>
                <h2>Navigation sécurisée sur Internet</h2>
                <p>La navigation sur Internet comporte des risques, notamment la fraude en ligne, le phishing et le vol d'identité. Pour naviguer en toute sécurité sur Internet, suivez ces conseils :</p>
                <p> .Assurez-vous que votre navigateur Web est à jour pour bénéficier des dernières fonctionnalités de sécurité.</p>
                    <p>.Évitez de cliquer sur des liens suspects ou d'ouvrir des pièces jointes provenant d'expéditeurs inconnus.</p>
                   <p>.Utilisez des connexions sécurisées (HTTPS) lorsque vous saisissez des informations sensibles, telles que des informations de carte de crédit ou des identifiants de connexion.</p>
                <h2>Sensibilisation aux escroqueries en ligne</h2>
                <p>Les escroqueries en ligne sont des tentatives frauduleuses visant à tromper les utilisateurs pour leur soutirer de l'argent ou des informations personnelles. Voici quelques exemples d'escroqueries en ligne courantes :</p>
                <p>.Phishing : Les attaquants tentent de voler des informations sensibles, telles que des identifiants de connexion ou des informations de carte de crédit, en se faisant passer pour des entités légitimes.</p>
                <p>
                .Arnaques par e-mail : Les utilisateurs reçoivent des e-mails frauduleux leur demandant de fournir des informations personnelles ou de l'argent.</p>
                <p>
                .Fraude aux faux supports techniques : Les utilisateurs sont contactés par des fraudeurs se faisant passer pour des techniciens informatiques légitimes, leur demandant d'accéder à leur ordinateur à distance et de payer pour des services non nécessaires.</p>
               
                <p>Pour se protéger contre les escroqueries en ligne, soyez vigilant et méfiez-vous des communications non sollicitées demandant des informations personnelles ou financières. Ne fournissez jamais d'informations sensibles à moins d'être sûr de l'identité et de la légitimité de la personne ou de l'entreprise avec laquelle vous communiquez.</p>
                <h2>Gestion des mises à jour</h2>
                <p>Les mises à jour logicielles sont essentielles pour maintenir la sécurité de votre système d'exploitation, de vos applications et de vos périphériques. Assurez-vous de configurer les mises à jour automatiques sur votre système d'exploitation et vos applications pour bénéficier des correctifs de sécurité les plus récents.</p>
                <h2>Utilisation d'un pare-feu</h2>
                <p>Un pare-feu est un outil de sécurité qui contrôle et filtre le trafic réseau entrant et sortant de votre ordinateur ou de votre réseau. Activez le pare-feu intégré à votre système d'exploitation Windows et envisagez d'installer un pare-feu tiers pour une protection supplémentaire.</p>
                <h2>Utilisation d'une connexion sécurisée</h2>
                <p>Lorsque vous vous connectez à un réseau sans fil ou à Internet, assurez-vous d'utiliser des connexions sécurisées, telles que le protocole WPA2 pour les réseaux Wi-Fi, et évitez de vous connecter à des réseaux non sécurisés ou publics.</p>
                <h2>Sauvegarde régulière des données</h2>
                <p>Effectuez régulièrement des sauvegardes de vos données importantes sur un support de stockage externe ou dans le cloud. En cas d'incident de sécurité, tel qu'une attaque de logiciel malveillant ou une panne matérielle, vous pourrez restaurer vos données à partir de la sauvegarde.</p>
                <h2>Formation et sensibilisation des utilisateurs</h2>
                <p>La formation et la sensibilisation des utilisateurs sont essentielles pour renforcer la sécurité informatique. Sensibilisez les utilisateurs aux bonnes pratiques de sécurité, tels que la création de mots de passe sécurisés, la reconnaissance des tentatives de phishing et la gestion sécurisée des informations sensibles.</p>
                <h2>Évaluation de la sécurité</h2>
                <p>Effectuez régulièrement des évaluations de sécurité de votre système informatique pour identifier les vulnérabilités potentielles et prendre des mesures correctives pour renforcer la sécurité. Vous pouvez utiliser des outils de numérisation de vulnérabilité et des services d'évaluation de sécurité tiers pour cette tâche.</p>
                <h2>Conclusion</h2>
                <p>En approfondissant votre compréhension de la sécurité informatique et en mettant en œuvre des mesures de sécurité supplémentaires telles que la gestion des mises à jour, l'utilisation d'un pare-feu, l'utilisation de connexions sécurisées, la sauvegarde régulière des données, la formation et la sensibilisation des utilisateurs, ainsi que l'évaluation de la sécurité, vous pouvez renforcer la sécurité de votre système informatique et réduire les risques de cyberattaques et de violations de données.

</p>
<QuizF/>
            </div>
           
        </div>
        </>
    );
};

export default Sécurité;