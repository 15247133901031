// import React, { useState, useEffect } from 'react';

// const TodoApp = () => {
//     const [todos, setTodos] = useState([]);
//     const [inputText, setInputText] = useState('');
//     const [filter, setFilter] = useState('all');

//     useEffect(() => {
//         getTodosFromLocalStorage();

//         // Effacer les tâches du localStorage lorsque l'utilisateur se déconnecte ou ferme la page
//         window.addEventListener("beforeunload", () => {
//             localStorage.removeItem("todos");
//         });
//     }, []);

//     useEffect(() => {
//         filterTodo();
//     }, [todos, filter]);

//     const handleChange = (e) => {
//         setInputText(e.target.value);
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         if (inputText.trim() !== '') {
//             addTodo(inputText);
//             setInputText('');
//         }
//     };

//     const addTodo = (text) => {
//         const newTodo = {
//             id: Date.now(),
//             text: text,
//             completed: false
//         };
//         setTodos([...todos, newTodo]);
//         saveLocalTodos(newTodo);
//     };

//     const deleteTodo = (id) => {
//         const updatedTodos = todos.filter(todo => todo.id !== id);
//         setTodos(updatedTodos);
//         removeLocalTodo(id);
//     };

//     const toggleTodo = (id) => {
//         const updatedTodos = todos.map(todo =>
//             todo.id === id ? { ...todo, completed: !todo.completed } : todo
//         );
//         setTodos(updatedTodos);
//         saveLocalTodos(updatedTodos); // Mettre à jour les tâches dans le localStorage
//     };

//     const filterTodo = () => {
//         const filteredTodos = todos.filter(todo => {
//             if (filter === 'all') return true;
//             if (filter === 'completed') return todo.completed;
//             if (filter === 'uncompleted') return !todo.completed;
//             return true;
//         });
//         setTodos(filteredTodos);
//     };

//     useEffect(() => {
//         getTodosFromLocalStorage();
//     }, []); // Déclenché uniquement lors du montage initial du composant
    
//     const getTodosFromLocalStorage = () => {
//         const todosFromLocalStorage = JSON.parse(localStorage.getItem('todos')) || [];
//         setTodos(todosFromLocalStorage);
//     };
    

//     const saveLocalTodos = (todo) => {
//         const todosFromLocalStorage = JSON.parse(localStorage.getItem('todos')) || [];
//         const updatedTodos = [...todosFromLocalStorage, todo];
//         localStorage.setItem('todos', JSON.stringify(updatedTodos));
//     };

//     const removeLocalTodo = (id) => {
//         const todosFromLocalStorage = JSON.parse(localStorage.getItem('todos')) || [];
//         const updatedTodos = todosFromLocalStorage.filter(todo => todo.id !== id);
//         localStorage.setItem('todos', JSON.stringify(updatedTodos));
//     };
   
    
    
//     return (

//     <div className='todolist'>

//         <div className="container-list">
//            <h1>Liste de Tâches</h1>
//             <form onSubmit={handleSubmit}>
//                 <input
//                     type="text"
//                     className="todo-input"
//                     value={inputText}
//                     onChange={handleChange}
//                     placeholder="Ajouter une tâche"
//                 />
//                 <button type="submit">Ajouter</button>
//                 <div className="todo-select">
//                     <select name="todos" className="filter-todo" value={filter} onChange={(e) => setFilter(e.target.value)}>
//                         <option value="all">Toutes</option>
//                         <option value="uncompleted">À Faire</option>
//                         <option value="completed">Effacer</option>
//                     </select>
//                 </div>
//             </form>
//             <div className="todo-container">
//                 <ul className="todo-list">
//                     {todos.map(todo => (
//                         <li key={todo.id} className={todo.completed ? "completed" : ""}>
//                             <span>{todo.text}</span>
//                             <button className="complete-btn" onClick={() => toggleTodo(todo.id)}>
//                                 <i className="fas fa-check"></i>
//                                 <span>Fait</span>
//                             </button>
//                             <button className="trash-btn" onClick={() => deleteTodo(todo.id)}>
//                                 <i className="fas fa-trash"></i>
//                                 <span>Supprimer</span>
//                             </button>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         </div>
//         </div>
//     );
// };

// export default TodoApp;

import React, { useState, useEffect } from 'react';

const TaskListGenerator = () => {
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [priority, setPriority] = useState('Medium');
  const [category, setCategory] = useState('General');


  useEffect(() => {
    const storedTasks = localStorage.getItem('tasks');
    if (storedTasks) {
      setTasks(JSON.parse(storedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleNewTaskChange = (e) => {
    setNewTask(e.target.value);
  };

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleAddTask = () => {
    if (newTask.trim() === '') return;
    const task = {
      id: tasks.length + 1,
      name: newTask,
      priority: priority,
      category: category,
    };
    setTasks([...tasks, task]);
    setNewTask('');
  };

  const handleRemoveTask = (taskId) => {
    setTasks(tasks.filter(task => task.id !== taskId));
  };

  return (
    <div className='todolist'>
        <div className='container-list'>
      <h1>Pense-bête</h1>
      <div>
        <form action="">
        <input
          type="text"
          value={newTask}
          onChange={handleNewTaskChange}
          placeholder="Entrer tâche..."
        />
        <select className='select1' value={priority} onChange={handlePriorityChange}>
          <option value="Low">Basse</option>
          <option value="Medium">Medium</option>
          <option value="High">Haute</option>
        </select>
        <select className='select2' value={category} onChange={handleCategoryChange}>
          <option value="General">General</option>
          <option value="Work">Travail</option>
          <option value="Personal">Personel</option>
        </select>
        <button type="button" onClick={handleAddTask}>Ajouter</button>
      </form>
      </div>
      <div className='todo-container'>
        <h3>Liste des tâches</h3>
        <ul>
          {tasks.map((task) => (
            <li key={task.id}>
              {task.name} - Priority: {task.priority} - Category: {task.category}
              <button onClick={() => handleRemoveTask(task.id)}>Remove</button>
            </li>
          ))}
        </ul>
      </div>
    </div></div>
  );
};

export default TaskListGenerator;

