import React from "react";
import windows1 from "../../../media/windows1.png";
import boutonDemarrer from "../../../media/boutonDemarrer.png";
import windowsApp from "../../../media/windowsApp.png";
import windowsNoti from "../../../media/windowsNoti.png";
import windowsEpingle from "../../../media/windowsEpingle.png";
import windowsSearch from "../../../media/windowsSearch.png";
import windowsNoti1 from "../../../media/windowsNoti1.png";
import windowsTachePara from "../../../media/windowsTachePara.png";
import QuizD from "../../../composants/quizD";
const Tâches = () => {
  return (
    <div className="container-taches">
      <h1>La Barre des Tâches de Windows</h1>
      <h2>Introduction</h2>
      <p>
        La barre des tâches de Windows est l'une des fonctionnalités les plus
        emblématiques du système d'exploitation Windows. Elle offre un accès
        rapide aux applications, aux programmes et à d'autres fonctionnalités
        importantes de votre ordinateur. Ce cours vous guidera à travers les
        différentes parties de la barre des tâches, ainsi que ses
        fonctionnalités et ses personnalisations.
      </p>
      <h2>1. Vue d'ensemble de la Barre des Tâches</h2>
      <p>
        La barre des tâches est généralement située en bas de l'écran, bien
        qu'elle puisse être déplacée vers les côtés ou le haut selon vos
        préférences. Voici les éléments principaux de la barre des tâches :
      </p>
      <img src={windows1} alt="fenêtre entière de Windows 11" />
      <p>
        Bouton Démarrer : Il est situé à l'extrême gauche de la barre des tâches
        et vous permet d'accéder au menu Démarrer, où vous pouvez trouver des
        programmes, des paramètres et des fichiers.
      </p>
      <img
        src={boutonDemarrer}
        alt="extrait barre des taches windows montrant le bouton démarrer"
      />
      <p>
        Icônes des Applications : À droite du bouton Démarrer se trouvent les
        icônes des applications épinglées et ouvertes. Vous pouvez cliquer sur
        ces icônes pour ouvrir ou basculer entre les programmes.
      </p>
      <img
        src={windowsApp}
        alt="extrait barre des taches windows montrant les applications"
      />
      <p>
        Zone de Notification : À droite de la barre des tâches se trouve la zone
        de notification, qui affiche l'heure, la date et les icônes des
        programmes en cours d'exécution en arrière-plan.
      </p>
      <img
        src={windowsNoti}
        alt="extrait barre des taches windows montrant le coté des norifications"
      />
      <br />
      <br />
      <h2>2. Fonctionnalités de la Barre des Tâches</h2>
      <p>
        Voici quelques fonctionnalités utiles de la barre des tâches que vous
        pouvez utiliser pour optimiser votre expérience utilisateur :
      </p>
      <p>
        Épingler des Programmes : Vous pouvez épingler vos programmes et
        applications préférés à la barre des tâches en faisant un clic droit sur
        l'icône de l'application et en sélectionnant "Épingler à la barre des
        tâches".
      </p>
      <img
        src={windowsEpingle}
        alt="fenêtre montrant comment épingler une app dans la barre des tâches "
      />
      <p>
        Personnaliser la Taille des Icônes : Vous pouvez ajuster la taille des
        icônes de la barre des tâches en faisant un clic droit sur un espace
        vide de la barre des tâches, en sélectionnant "Propriétés" puis en
        modifiant la taille des icônes dans les paramètres.
      </p>
      <p>
        Barre de Recherche : La barre des tâches comprend une barre de recherche
        intégrée où vous pouvez rechercher des fichiers, des applications et des
        paramètres directement depuis la barre des tâches.
      </p>
      <img
        src={windowsSearch}
        alt="extrait barre des tâches windows montrant la barre de recherche "
      />
      <p>
        Centre de Notifications : En cliquant sur l'icône de notification à
        droite de la barre des tâches, vous pouvez accéder au Centre de
        Notifications où vous pouvez consulter les notifications système et les
        paramètres rapides.
      </p>
      <img
        src={windowsNoti1}
        alt="extrait barre des tâches coté droit montrant les notifications windows"
      />
      <br />
      <br />
      <h2>3. Personnalisation de la Barre des Tâches</h2>
      <p>
        La barre des tâches de Windows offre plusieurs options de
        personnalisation pour répondre à vos besoins spécifiques. Voici
        quelques-unes des personnalisations courantes que vous pouvez effectuer
        :
      </p>
      <p>
        Modifier l'Emplacement : Vous pouvez déplacer la barre des tâches vers
        les côtés ou le haut de l'écran en faisant un clic droit sur un espace
        vide de la barre des tâches, en sélectionnant "Propriétés", puis en
        modifiant l'option "Position de la barre des tâches".
      </p>
      <p>
        Masquer Automatiquement : Vous pouvez choisir de masquer automatiquement
        la barre des tâches lorsque vous ne l'utilisez pas en cochant l'option
        "Masquer automatiquement la barre des tâches" dans les paramètres de la
        barre des tâches.
      </p>

      <img
        src={windowsTachePara}
        alt="fenetre montrant comment modifier la barre des tâches windows 11"
      />
      <p>
        Personnaliser les Icônes Système : Vous pouvez personnaliser les icônes
        système qui apparaissent dans la zone de notification en accédant aux
        paramètres de personnalisation de la barre des tâches.
      </p>
      <h2>Conclusion</h2>
      <p>
        La barre des tâches de Windows est une fonctionnalité centrale de
        l'interface utilisateur de Windows, offrant un accès rapide aux
        programmes, aux applications et aux fonctionnalités système. En
        comprenant ses différentes parties, ses fonctionnalités et ses options
        de personnalisation, vous pouvez tirer le meilleur parti de votre
        expérience Windows.
      </p>
      <QuizD />
    </div>
  );
};

export default Tâches;
