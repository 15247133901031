import React, { useState, useEffect } from "react";
import "../styles/avatar.scss";
import FormulaireAvatar from "./FormulaireAvatar";
const Avatar = ({ userId }) => {
  const [isFormVisible, setIsFormVisible] = useState(true);

  return (
    <div className="container-avatar1">
      {isFormVisible ? (
        <FormulaireAvatar userId={userId} onSuccess={() => setIsFormVisible(false)} />
      ) : (
        <p>Avatar mis à jour avec succès !</p>
        // Vous pouvez éventuellement afficher un message de réussite ou rediriger vers la page de profil
      )}
    </div>
  );
};

export default Avatar;