
import React, { useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/inscription.scss";
import Nav from "../composants/nav";
import axios from "axios";
import { Helmet } from "react-helmet-async";

const Inscription = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);

  // Fetch data on mount
  useEffect(() => {
    fetch('/api/data')
      .then(res => res.json())  
      .then(data => setData(data))
  }, [])

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === 'email') {
      setEmail(value);
    }
  };
  const isEmailUnique = async (email) => {
    try {
      const response = await axios.get(`https://clicdesouris/api/check-email/${email}`);
      return response.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Vérification côté client
    for (const key in formData) {
      if (formData[key] === "") {
        setErrorMessage("Merci de remplir tous les champs !");
        return;
      }
    }
  
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(com|fr)$/;
    if (!emailRegex.test(formData.email)) {
      setErrorMessage("Adresse e-mail invalide. Utilisez .com ou .fr");
      return;
    }
  
    if (formData.email !== formData.confirmEmail) {
      setErrorMessage("Les adresses email ne correspondent pas.");
      return;
    }
  
    if (formData.password.length < 8) {
      setErrorMessage("Votre mot de passe doit comporter au moins 8 caractères.");
      return;
    }
  
    // Validation côté serveur pour vérifier si l'e-mail existe déjà
    try {
      console.log("Avant la validation côté serveur");
      const checkEmailResponse = await axios.get(
        `https://www.clicdesouris.fr/api/check-email/${email}`
      );
      console.log("Après la validation côté serveur", checkEmailResponse);
      if (checkEmailResponse.data.message === "Cette adresse email est déjà utilisée.") {
        // L'e-mail est déjà utilisé
        setErrorMessage("Cette adresse email est déjà utilisée.");
        console.log("Réponse du serveur :", checkEmailResponse);
      } else {
        // L'e-mail est disponible, continuez avec l'inscription
        const response = await axios.post(
          "https://www.clicdesouris.fr/inscription",
          formData
        );
  
        if (response.status === 201) {
          const userId = response.data.userId;
          navigate("/connexion");
        } else {
          setErrorMessage("Erreur lors de l'inscription.");
        }
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Erreur lors de l'inscription.");
    }
    window.location.href = '/rgpd';
  };
  return (
    <>
      <Helmet>
        <title>Clic de souris - Inscription</title>
      </Helmet><Nav/>
      <div className="inscription-container">
      {data && <pre>{JSON.stringify(data, null, 2)}</pre>} 
        <form className="inscription-form" onSubmit={handleSubmit}>
          <h1>Inscription</h1>
          <div className="form-group">
            <label htmlFor="email">Adresse email :</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmEmail">
              Confirmation de l'email :
            </label>
            <input
              type="email"
              id="confirmEmail"
              name="confirmEmail"
              value={formData.confirmEmail}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Mot de passe :</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="confirmPassword">
              Confirmation du mot de passe :
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          </div>
         
          <button type="submit" onClick={handleSubmit}>
            Créer le compte
          </button>
        </form>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        
        <p>
          Déjà inscrit ? <Link to="/connexion">Se connecter</Link>
        </p>
        
      </div>
     
    </>
  );
};

export default Inscription;
