import { createContext, useState, useContext } from 'react';

const AuthContext = createContext();

 const AuthProvider = ({ children }) => {

  const [isConnected, setIsConnected] = useState(null);

  const connect = (userData) => {
    // action de connexion
    setIsConnected(userData);
  }

  const disconnect = () => {
    // action de déconnexion
    setIsConnected(null); 
  }

  return (
    <AuthContext.Provider 
      value={{
        isConnected, 
        connect,
        disconnect  
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  return useContext(AuthContext);
};
export {AuthProvider, useAuth};