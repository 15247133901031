import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import facebook from "../../media/facebook.png";
import pageInscriptFace from "../../media/pageInscripFace.png";
import searchFace from "../../media/searchFace.png";
import ajoutAmi from "../../media/ajoutAmi.png";
import like from "../../media/like.png";
import commentaire from "../../media/commentaire.png";
import post from "../../media/post.png";
import partage from "../../media/partage.png";
import choixPartage from "../../media/choixPartage.png";
import groupe from "../../media/groupe.png";
const Facebook = () => {
  const { userId } = useParams();
  const [data, setData] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);

  useEffect(() => {
    // Récupérez le rôle de l'utilisateur
    const fetchIsAdmin = async () => {
      const response = await fetch(`https://www.clicdesouris.fr/api/users/${userId}`);
      const data = await response.json();
      setIsAdmin(data.role === "admin");
    };
    fetchIsAdmin();
  }, [userId]);

  useEffect(() => {
    axios
      .get(`https://www.clicdesouris.fr/api/users/${userId}`)
      .then((response) => {
        // Traitement des données de réponse ici
        console.log(response.data);
        setData(response.data);
      })
      .catch((error) => {
        // Gestion des erreurs ici
        console.error("Erreur lors de la requête :", error);
      });
  }, [userId]);
  return (
    <div className="container-reseaux">
      <div className="logoFacebook">
        <img src={facebook} alt="logo facebook bleue" />
      </div>
      <div className="facebook-content">
        <h2>Introduction à Facebook</h2>
        <p>
          Facebook à été créé le 04 février 2004 par Mark Zuckerberg, Eduardo
          Saverin, Andrew McCollum, Dustin Moskovitz et Chris Hughes.{" "}
        </p>
        <p>
          Facebook est un réseau social en ligne qui permet aux utilisateurs de
          se connecter, de partager du contenu, de communiquer et d'interagir
          avec d'autres personnes à travers le monde. Ce cours vous guidera à
          travers les différentes fonctionnalités de Facebook, depuis la
          création d'un compte jusqu'à l'utilisation des fonctionnalités
          principales.
        </p>
        <hr />
        <h3>1. Création d'un Compte</h3>
        <p>
          Accédez au site web de Facebook (www.facebook.com). Remplissez le
          formulaire d'inscription avec vos informations personnelles, y compris
          votre nom, votre adresse e-mail ou votre numéro de téléphone, votre
          date de naissance et votre sexe. Choisissez un mot de passe sécurisé
          pour votre compte. Cliquez sur le bouton "S'inscrire" pour créer votre
          compte.
        </p>
        <img
          className="un"
          src={pageInscriptFace}
          alt="page d'inscription Facebook"
        />
        <hr />
        <h3>2. Ajout d'Amis</h3>
        <p>
          Recherchez des amis en utilisant la barre de recherche en haut de la
          page Facebook.
          <img
            className="une"
            src={searchFace}
            alt="barre de recherche facebook"
          />
        </p>
        <p>
          Envoyez des demandes d'amis en cliquant sur le bouton "Ajouter ami(e)"
          à côté du profil de la personne que vous souhaitez ajouter. Attendez
          que la personne accepte votre demande d'ami pour devenir amis sur
          Facebook.
        </p>

        <img
          className="deux"
          src={ajoutAmi}
          alt="fenêtre de demande d'ami facebook"
        />

        <hr />
        <h3>3. Utilisation des Fonctionnalités Principales</h3>
        <p>
          <span>a. Faire des Likes et des Commentaires</span>
          Pour aimer une publication, cliquez sur le bouton "J'aime" en dessous
          de la publication.
        </p>
        <img
          className="trois"
          src={like}
          alt="représentation de la fenêtre de like facebook"
        />
        <p>
          Pour commenter une publication, cliquez sur la zone de commentaire en
          dessous de la publication, tapez votre commentaire, puis appuyez sur
          "Entrée".
        </p>
        <img
          className="quatre"
          src={commentaire}
          alt="fenêtre pour les commentaires facebook"
        />
        <p>
          <span>b. Publier un Post</span>
          Cliquez sur "Créer une publication" en haut de votre fil d'actualité.
          Rédigez votre message ou partagez une photo/vidéo. Ajoutez des tags
          d'amis ou des emplacements si désiré. Cliquez sur "Publier" pour
          partager votre publication avec vos amis.
        </p>
        <img className="cinq" src={post} alt="fenêtre de post facebook" />
        <p>
          <span>c. Partager des Publications</span>
          Pour partager une publication d'un ami, cliquez sur le bouton
          "Partager" en dessous de la publication.
        </p>
        <img className="six" src={partage} alt="fenêtre de partage facebook" />
        <p>
          Choisissez si vous souhaitez la partager sur votre fil d'actualité,
          dans un groupe, sur une page, etc. Ajoutez un commentaire si vous le
          souhaitez, puis cliquez sur "Partager".
        </p>
        <img
          className="sept"
          src={choixPartage}
          alt="fenetre de choix du partage facebook"
        />
        <p>
          <span>d. Utiliser les Groupes</span>
          Rejoignez des groupes pertinents en utilisant la barre de recherche.
        </p>
        <img
          className="huit"
          src={groupe}
          alt="fenêtre pour les groupes facebook"
        />
        <p>
          Participez aux discussions en commentant et en partageant des
          publications dans les groupes. Créez vos propres publications dans les
          groupes pour partager du contenu avec les membres.
        </p>
      </div>
    </div>
  );
};

export default Facebook;
