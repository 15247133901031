import React, { useState } from 'react';
import '../styles/mouseSimulator.scss'; // Fichier de style CSS pour personnaliser l'apparence du simulateur de souris



  const MouseSimulator = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [textPosition, setTextPosition] = useState({ x: 200, y: 150 }); // Position initiale du texte
  
    const handleMouseMove = (event) => {
      const boundingRect = event.currentTarget.getBoundingClientRect();
      const offsetX = event.clientX - boundingRect.left;
      const offsetY = event.clientY - boundingRect.top;
      setMousePosition({ x: offsetX, y: offsetY });
  
      // Vérifie si la souris est sur le texte
      if (
        offsetX >= textPosition.x &&
        offsetX <= textPosition.x + 150 && // Largeur du texte (ajustez selon votre texte)
        offsetY >= textPosition.y &&
        offsetY <= textPosition.y + 20 // Hauteur du texte (ajustez selon votre texte)
      ) {
        // Déplace le texte de manière aléatoire
        const newX = textPosition.x + Math.random() * 200 - 100; // Ajustez la valeur 100 selon vos besoins
        const newY = textPosition.y + Math.random() * 200 - 100; // Ajustez la valeur 100 selon vos besoins
        setTextPosition({ x: newX, y: newY });
      }
    };
  
    return (
      <div className="mouse-simulator" onMouseMove={handleMouseMove}>
        <h2>Salle d'entrainement !</h2>
        <div className="mouse-pointer" style={{ left: mousePosition.x, top: mousePosition.y }}></div>
        <p className="text" style={{ left: textPosition.x, top: textPosition.y }}>Essayez de m'attraper !</p>
      </div>
    );
  };
  
  export default MouseSimulator;
