import React, { useState } from "react";

const QuizE = () => {
  const quizData = {
    "quizTitle": "Quiz Niveau 3",
    "quizSynopsis": "Voici un quiz qui vous permettra de savoir ou vous en êtes. Lisez bien les cours et essayez de bien retenir les informations importantes. Il n'y a rien à gagner donc pas de stress ! Vous pouvez venir faire ce quiz autant de fois qu'il vous plaira donc si vous n'avez pas un bon score n'hésitez pas à revoir les cours et retenter votre chance.",
    "questions": [
      {
        "question": "1.Quel est l'outil essentiel pour protéger votre ordinateur contre les logiciels malveillants tels que les virus et les chevaux de Troie ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Pare-feu",
          "Antivirus",
          "Mise à jour logiciel",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "Antivirus était la bonne réponse.",
        "point": 20
      },
      {
        "question": "2. Qu'est-ce qu'un bon moyen de créer un mot de passe sécurisé ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Utiliser une combinaison de lettres majuscules et minuscules, de chiffres et de caractères spéciaux",
          "Utiliser le nom de votre animal de compagnie",
          "Utiliser la même combinaison de chiffres pour tous vos comptes",

        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": " La bonne réponse était : Utiliser une combinaison de lettres majuscules et minuscules, de chiffres et de caractères spéciaux",
        "point": 20
      },// Ajoutez le reste de vos questions ici...
      {
        "question": "3. Quelle est la meilleure pratique pour naviguer en toute sécurité sur Internet ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Utiliser un navigateur obsolète",
          "Cliquer sur tous les liens qui semblent intéressants",
          "Éviter les sites Web non sécurisés et ne pas cliquer sur des liens suspects",
         
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Éviter les sites Web non sécurisés et ne pas cliquer sur des liens suspects.",
        "point": 20
      },
      {
        "question": "4.Quelle est une caractéristique d'un e-mail de phishing ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Il est envoyé par un ami ou un collègue de confiance",
          "Il demande des informations personnelles ou financières sensibles",
          "Il ne contient aucun lien ni aucune pièce jointe",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Il demande des informations personnelles ou financières sensibles.",
        "point": 20
      },
      {
        "question": "5. Comment protéger votre connexion Wi-Fi à la maison ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Ne pas utiliser de mot de passe Wi-Fi",
          "Utiliser le protocole de sécurité WEP",
          "Utiliser le protocole de sécurité WPA2 avec un mot de passe fort",
          
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Utiliser le protocole de sécurité WPA2 avec un mot de passe fort.",
        "point": 20
      },
      {
        "question": "6. Quelle est l'une des meilleures pratiques pour protéger vos données importantes ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Ne jamais effectuer de sauvegarde",
          "Effectuer régulièrement des sauvegardes sur un support de stockage externe ou dans le cloud",
          "Utiliser le même mot de passe pour tous vos comptes",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Effectuer régulièrement des sauvegardes sur un support de stockage externe ou dans le cloud.",
        "point": 20
      },
      {
        "question": "7. Quelle est l'importance de la sensibilisation des utilisateurs en matière de sécurité informatique ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Aucune importance",
          "Ignorer les mises à jour de sécurité",
          "Réduire les risques d'attaques de phishing et de fraudes en ligne",
        ],
        "correctAnswer": 2,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Réduire les risques d'attaques de phishing et de fraudes en ligne",
        "point": 20
      },
      {
        "question": "8. Quelle est l'une des méthodes pour évaluer la sécurité de votre système informatique ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Ignorer les mises à jour logiciels",
          "Utiliser des outils de numérisation de vulnérabilité",
          "Utiliser un mot de passe simple comme '123456'",
          
        ],
        "correctAnswer": 1,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Utiliser des outils de numérisation de vulnérabilité.",
        "point": 20
      },
      {
        "question": "9. Quelle est l'importance des mises à jour logicielles pour la sécurité informatique ?",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Garder votre système d'exploitation, vos applications et vos périphériques à jour avec les derniers correctifs de sécurité",
          "Aucune importance",
          "Ignorer les correctifs de sécurité",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est  Garder votre système d'exploitation, vos applications et vos périphériques à jour avec les derniers correctifs de sécurité.",
        "point": 20
      },
      {
        "question": "10. Qu'est-ce qu'un pare-feu ? ",
        "questionType": "text",
        "answerSelectionType": "single",
        "answers": [
          "Un outil de sécurité qui contrôle et filtre le trafic réseau",
          "Un outil de sauvegarde de données",
          "Un antivirus",
          
        ],
        "correctAnswer": 0,
        "messageForCorrectAnswer": "Réponse correct. Bon travail.",
        "messageForIncorrectAnswer": "Réponse incorrecte. Essaie encore.",
        "explanation": "La bonne réponse est Un outil de sécurité qui contrôle et filtre le trafic réseau.",
        "point": 20
      },
    ]
  };

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerSelection = (index) => {
    // Vérifier si une réponse a déjà été sélectionnée
    if (selectedAnswerIndex === null) {
      setSelectedAnswerIndex(index);
      setShowFeedback(true);
  
      if (index === quizData.questions[currentQuestion].correctAnswer) {
        setScore(score + parseInt(quizData.questions[currentQuestion].point));
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestion(currentQuestion + 1);
    setSelectedAnswerIndex(null);
    setShowFeedback(false);
  };

  return (
    <div className="quizA">
        
    <div className="container-quizA">
      <h1>{quizData.quizTitle}</h1>
      <p>{quizData.quizSynopsis}</p>
      {currentQuestion < quizData.questions.length ? (
        <div className="question-quizA">
          <h2>{quizData.questions[currentQuestion].question}</h2>
          <ul>
            {quizData.questions[currentQuestion].answers.map((answer, index) => (
              <li key={index}>
                <label>
                  <input
                    type="radio"
                    value={answer}
                    checked={selectedAnswerIndex === index}
                    onChange={() => handleAnswerSelection(index)}
                  />
                  {answer}
                </label>
              </li>
            ))}
          </ul>
          {showFeedback && (
            <div>
            {selectedAnswerIndex === quizData.questions[currentQuestion].correctAnswer ? (
              <p>{quizData.questions[currentQuestion].messageForCorrectAnswer}</p>
            ) : (
              <p>{quizData.questions[currentQuestion].messageForIncorrectAnswer}</p>
            )}
            <p>{quizData.questions[currentQuestion].explanation}</p>
            <p>Score: {score}</p>
            <button onClick={handleNextQuestion}>Prochaine Question</button>
          </div>
          )}
        </div>
      ) : (
        <div>
          <h2>Quiz Terminé!</h2>
          <p>Votre Score: {score}</p>
        </div>
      )}
    </div> 
     </div>
  );
};

export default QuizE;
