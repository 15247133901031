import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from 'react-helmet-async';

const GoogleSheets = () => {
   const { userId } = useParams();
  const [data, setData] = useState({});
   const [isSubscribed, setIsSubscribed] = useState (false);
   useEffect(() => {
      // Vérifier l'état d'abonnement de l'utilisateur
      const checkSubscription = async () => {
        try {
          const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
          setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
          console.error('Erreur lors de la vérification de l\'abonnement :', error);
        }
      };
  
      checkSubscription();
    }, [userId]);
    
    useEffect(() => {
      axios
        .get(`https://www.clicdesouris.fr/api/users/${userId}`)
        .then((response) => {
          // Traitement des données de réponse ici
          console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          // Gestion des erreurs ici
          console.error("Erreur lors de la requête :", error);
        });
    }, [userId]);
    return (
      <>
      <Helmet>
        <title>Cours GoogleSheets - Clic de souris</title>
      </Helmet>
        <div className='googleSheets-container'>
        
            <div className='googleSheets-content'>
                <h1>Google Sheets : Tableurs Collaboratifs en Ligne</h1>
                <p>Google Sheets est un outil de tableur basé sur le cloud, qui fait partie de la suite Google Workspace. Il permet de créer, d'organiser et de partager des feuilles de calcul en ligne de manière collaborative. Dans cet exposé, nous explorerons les fonctionnalités de base de Google Sheets, y compris la création de feuilles de calcul, la manipulation des données, la mise en forme, les formules et les graphiques, ainsi que la collaboration en temps réel.</p>
                <h2>Création de feuilles de calcul</h2>
                <p>Pour créer une nouvelle feuille de calcul dans Google Sheets, suivez ces étapes simples :</p>
                <p>.Connectez-vous à votre compte Google.
                   .Accédez à Google Sheets en cliquant sur l'icône correspondante dans le menu Google Drive.
                   .Cliquez sur le bouton "+ Nouveau" en haut à gauche de la page.
                   .Sélectionnez "Feuille de calcul" pour créer une nouvelle feuille de calcul.</p>
                <h2>Manipulation des données</h2>
                <p>Une fois votre feuille de calcul créée, vous pouvez commencer à saisir et à manipuler des données. Voici quelques opérations de base que vous pouvez effectuer dans Google Sheets :</p>
                <p>.Saisir des données dans les cellules en cliquant dessus et en commençant à taper.
                   .Copier, coller et déplacer des données en utilisant les commandes de copie, de couper et de coller.
                   .Utilisez les fonctions de copie automatique pour remplir rapidement les cellules avec des séries de données.</p>
                 <h2>Mise en forme des cellules</h2>  
                 <p>Google Sheets offre de nombreuses options de mise en forme pour personnaliser l'apparence de vos données. Voici quelques fonctionnalités de mise en forme que vous pouvez utiliser :</p>
                 <p>.Appliquer des formats de nombre, de date et d'heure aux cellules.
                    .Modifier la couleur de fond et de texte des cellules.
                    .Appliquer des bordures et des styles de cellule pour mettre en évidence les données importantes.</p>
                 <h2>Utilisation de formules et de fonctions</h2>
                 <p>Les formules et les fonctions sont des éléments essentiels dans Google Sheets pour effectuer des calculs et analyser les données. Voici quelques exemples de formules couramment utilisées :</p>
                 <p>.SUM : Pour additionner une plage de cellules.
                    .AVERAGE : Pour calculer la moyenne d'une plage de cellules.
                    .IF : Pour effectuer un test logique et retourner différentes valeurs en fonction du résultat du test.</p>
                <h2>Création de graphiques</h2>
                <p>Google Sheets vous permet de créer des graphiques à partir de vos données pour visualiser facilement les tendances et les modèles. Voici comment créer un graphique dans Google Sheets :</p>
                <p>.Sélectionnez les données que vous souhaitez inclure dans le graphique.
                   .Allez dans le menu "Insertion" et sélectionnez "Graphique".
                   .Choisissez le type de graphique que vous souhaitez créer et personnalisez-le selon vos besoins.</p>
                <h2>Collaboration en temps réel</h2>
                <p>L'un des avantages majeurs de Google Sheets est sa capacité à permettre la collaboration en temps réel avec d'autres utilisateurs. Voici comment collaborer sur une feuille de calcul dans Google Sheets :</p>
                <p>.Partagez votre feuille de calcul avec d'autres utilisateurs en cliquant sur le bouton "Partager" en haut à droite de la fenêtre.
                   .Invitez des utilisateurs à collaborer en saisissant leurs adresses e-mail.
                   .Définissez les autorisations de partage (lecture seule, édition, commentaire, etc.).
                   .Les utilisateurs invités peuvent alors travailler sur la feuille de calcul en temps réel.</p>
                <h2>Utilisation de filtres et de tri</h2>
                <p>Google Sheets offre des fonctionnalités avancées pour filtrer et trier vos données, ce qui permet une analyse plus approfondie et une visualisation plus claire des informations. Voici comment utiliser les filtres et le tri dans Google Sheets :</p>
                <p>.Utilisez l'option "Données" dans la barre de menu pour accéder aux fonctionnalités de tri et de filtre.
                   .Triez vos données par ordre croissant ou décroissant en fonction d'une colonne spécifique.
                   .Utilisez les filtres pour afficher uniquement les données qui répondent à certains critères spécifiques.</p>
                <h2>Création de tableaux croisés dynamiques</h2>   
                <p>Les tableaux croisés dynamiques sont des outils puissants pour analyser et résumer de grandes quantités de données dans Google Sheets. Voici comment créer un tableau croisé dynamique :</p>
                <p>.Sélectionnez les données que vous souhaitez inclure dans le tableau croisé dynamique.
                   .Allez dans le menu "Données" et sélectionnez "Créer un tableau croisé dynamique".
                   .Choisissez les champs que vous souhaitez inclure dans les lignes, les colonnes et les valeurs du tableau croisé dynamique.</p>
                <h2>Utilisation de scripts et d'automatisation</h2>
                <p>Google Sheets offre la possibilité d'utiliser des scripts pour automatiser des tâches et étendre les fonctionnalités de votre feuille de calcul. Voici quelques exemples d'utilisation de scripts dans Google Sheets :</p>
                <p>.Créez des scripts pour effectuer des calculs complexes ou pour générer des rapports automatiquement.
                   .Utilisez les déclencheurs de script pour exécuter des scripts à des moments spécifiques ou en réponse à certaines actions.</p>
                <h2>Importation de données externes</h2>
                <p>Google Sheets vous permet d'importer des données externes à partir de différentes sources, telles que des fichiers CSV, des sites Web ou d'autres feuilles de calcul. Voici comment importer des données externes dans Google Sheets :</p>
                <p>.Utilisez l'option "Importer" dans le menu "Fichier" pour importer des données à partir de fichiers CSV ou de feuilles de calcul Excel.
                   .Utilisez les fonctions d'importation de données externes, telles que IMPORTXML, IMPORTHTML ou IMPORTRANGE, pour importer des données à partir de sites Web ou d'autres feuilles de calcul Google Sheets.</p>
                <h2>Utilisation de compléments</h2>
                <p>Les compléments sont des outils supplémentaires que vous pouvez ajouter à Google Sheets pour étendre ses fonctionnalités. Voici quelques exemples de compléments utiles pour Google Sheets :</p>
                <p>.Complément "Mail Merge" pour fusionner des données de feuilles de calcul avec des modèles de document Google Docs.
                   .Complément "Supermetrics" pour importer des données de différentes sources de marketing en ligne dans Google Sheets.</p>
                <h2>Conclusion</h2>
                <p>En utilisant les fonctionnalités avancées de Google Sheets telles que les filtres et le tri, les tableaux croisés dynamiques, les scripts et l'automatisation, l'importation de données externes et les compléments, vous pouvez tirer le meilleur parti de cet outil de tableur basé sur le cloud pour l'analyse de données, la gestion de projets et bien plus encore. En comprenant et en utilisant ces fonctionnalités avancées, vous serez en mesure d'optimiser votre utilisation de Google Sheets pour répondre à divers besoins professionnels et personnels.</p>
            </div>
       
        </div>
        </>
    );
};

export default GoogleSheets;