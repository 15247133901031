import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import QuizG from "../../../composants/quizG";
import { Helmet } from 'react-helmet-async';
import creationDoc from "../../../media/creationDoc.mp4"
const GoogleDoc = () => {
   const { userId } = useParams();
  const [data, setData] = useState({});
   const [isSubscribed, setIsSubscribed] = useState (false);
   useEffect(() => {
      // Vérifier l'état d'abonnement de l'utilisateur
      const checkSubscription = async () => {
        try {
          const response = await axios.get(`https://www.clicdesouris.fr/api/subscription/${userId}`);
          setIsSubscribed(response.data.isSubscribed);
        } catch (error) {
          console.error('Erreur lors de la vérification de l\'abonnement :', error);
        }
      };
  
      checkSubscription();
    }, [userId]);
    
    useEffect(() => {
      axios
        .get(`https://www.clicdesouris.fr/api/users/${userId}`)
        .then((response) => {
          // Traitement des données de réponse ici
          console.log(response.data);
          setData(response.data);
        })
        .catch((error) => {
          // Gestion des erreurs ici
          console.error("Erreur lors de la requête :", error);
        });
    }, [userId]);
   return (
      <>
      <Helmet>
        <title>Cours GoogleDoc - Clic de souris</title>
      </Helmet>
        <div className='googledoc-container'>
       
            <div className='googledoc-content'>
                <h1>Utilisation du logiciel DOC de Google : Création, Édition et Partage de Documents</h1>
                <p>Google Docs est un outil de traitement de texte basé sur le cloud, qui vous permet de créer, éditer et partager des documents en ligne de manière collaborative. Dans cet exposé, nous explorerons les fonctionnalités de base de Google Docs, y compris la création de documents, l'édition du contenu, la mise en forme du texte et la collaboration en temps réel avec d'autres utilisateurs.</p>
                <h2>Création de documents</h2>
                <p>Pour créer un nouveau document dans Google Docs, suivez ces étapes :</p>
                <p>.Connectez-vous à votre compte Google.</p>
                  <p> .Accédez à Google Drive en cliquant sur l'icône correspondante dans le menu Google.</p>
                  <p> .Cliquez sur le bouton "+ Nouveau" en haut à gauche de la page.</p>
                   <p>.Sélectionnez "Google Docs" dans le menu déroulant pour créer un nouveau document.</p>
                   <video width="520" height="440" controls>
        <source src={creationDoc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <hr />
                   <h2>Édition du contenu</h2>
                   <p>Une fois votre document créé, vous pouvez commencer à éditer son contenu. Voici quelques fonctionnalités d'édition de base dans Google Docs :</p>
                   <p>.Tapez directement pour ajouter du texte.</p>
                     <p> .Utilisez les options de mise en forme dans la barre d'outils pour mettre en gras, en italique, souligner, etc.</p>
                      <p>.Insérez des images, des liens, des tableaux et d'autres éléments en utilisant les options de la barre d'outils.</p>
                   <h2>Mise en forme du texte</h2>
                   <p>Google Docs offre de nombreuses options de mise en forme pour personnaliser l'apparence de votre document. Voici quelques fonctionnalités de mise en forme :</p>
                   <p>.Utilisez les styles de paragraphe pour appliquer des titres, des sous-titres, des listes à puces, etc.</p>
                     <p> .Modifiez la police, la taille de police, la couleur du texte, etc.</p>
                      <p>.Utilisez les options de paragraphe pour aligner le texte, modifier l'espacement entre les lignes, etc.</p>
                    <h2>Collaboration en temps réel</h2>  
                    <p>L'un des avantages majeurs de Google Docs est sa capacité à permettre la collaboration en temps réel avec d'autres utilisateurs. Voici comment collaborer sur un document dans Google Docs :</p>
                    <p>.Cliquez sur le bouton "Partager" en haut à droite de la fenêtre du document.</p>
                       <p>.Entrez les adresses e-mail des personnes avec lesquelles vous souhaitez collaborer.</p>
                       <p>.Choisissez les autorisations de partage (lecture seule, édition, commentaire, etc.).</p>
                       <p>.Cliquez sur "Envoyer" pour inviter les utilisateurs à collaborer sur le document.</p>
                    <h2>Sauvegarde automatique et Historique des versions</h2>
                    <p>Google Docs sauvegarde automatiquement votre travail pendant que vous travaillez dessus. De plus, il offre la fonctionnalité Historique des versions, qui vous permet de consulter et de restaurer des versions antérieures de votre document à tout moment.</p>
                    <h2>Insertion de commentaires et de suggestions</h2>
                    <p>Google Docs permet aux utilisateurs d'insérer des commentaires et des suggestions directement dans le document. Cette fonctionnalité est utile pour la révision et la collaboration. Voici comment ajouter des commentaires et des suggestions :</p>
                    <p>.Sélectionnez le texte ou l'élément sur lequel vous souhaitez commenter.</p>
                      <p> .Cliquez avec le bouton droit de la souris et sélectionnez "Commenter" pour ajouter un commentaire.</p>
                       <p>.Pour suggérer une modification, cliquez sur "Suggérer" et effectuez vos modifications. Les suggestions apparaîtront comme des commentaires pour que les autres puissent les examiner et les accepter ou les rejeter.</p>
                    <h2>Utilisation de modèles</h2>
                    <p>Google Docs propose une bibliothèque de modèles prédéfinis pour différents types de documents, tels que des CV, des rapports, des cartes de visite, etc. Utiliser des modèles peut vous faire gagner du temps et vous donner un point de départ pour créer des documents professionnels et esthétiques.</p>
                    <h3>Pour utiliser un modèle :</h3>
                   <p>.Ouvrez Google Docs.
                      .Cliquez sur "Modèles" en haut de l'écran.
                      .Choisissez un modèle dans la bibliothèque et cliquez sur "Utiliser ce modèle" pour commencer à personnaliser votre document.</p> 
                    <h2>Utilisation de la fonction de recherche et de remplacement</h2>
                    <p>La fonction de recherche et de remplacement dans Google Docs vous permet de trouver rapidement du texte spécifique dans votre document et de le remplacer par un autre. Voici comment l'utiliser :</p>
                    <p>.Cliquez sur l'icône de loupe dans la barre d'outils.
                       .Saisissez le texte que vous souhaitez rechercher dans le champ de recherche.
                       .Cliquez sur le bouton de remplacement pour remplacer le texte trouvé par un autre.</p>
                    <h2>Publication et intégration</h2>
                    <p>Google Docs offre la possibilité de publier votre document en ligne ou de l'intégrer dans un site Web ou un blog. Vous pouvez contrôler les autorisations de visualisation du document publié et obtenir un lien de partage pour le partager avec d'autres.</p>
                    <h3>Pour publier un document :</h3>
                    <p>.Cliquez sur "Fichier" dans la barre de menu.
                       .Sélectionnez "Publier sur le Web".
                       .Cliquez sur "Publier" pour obtenir un lien vers votre document.</p>
                    <h2>Exportation au format Microsoft Word</h2>
                    <p>Google Docs permet d'exporter vos documents au format Microsoft Word (.docx) pour une compatibilité avec d'autres programmes de traitement de texte. Pour exporter un document au format Word, allez dans "Fichier" - "Télécharger" - "Microsoft Word (.docx)".</p>
                    <h2>Astuces et conseils avancés pour Google Docs</h2>
                    <h3>Utilisation des raccourcis clavier</h3>
                    <p>Google Docs propose de nombreux raccourcis clavier pour accélérer votre flux de travail. Voici quelques raccourcis utiles :</p>
                    <p>.Ctrl + C (ou Commande + C sur Mac) : Copier
                       .Ctrl + X (ou Commande + X sur Mac) : Couper
                       .Ctrl + V (ou Commande + V sur Mac) : Coller
                       .Ctrl + Z (ou Commande + Z sur Mac) : Annuler
                       .Ctrl + Y (ou Commande + Y sur Mac) : Rétablir
                       .Ctrl + B (ou Commande + B sur Mac) : Mettre en gras
                       .Ctrl + I (ou Commande + I sur Mac) : Mettre en italique
                       .Ctrl + U (ou Commande + U sur Mac) : Souligner</p>
                       <h2>Création de formulaires</h2>
                       <p>Google Docs vous permet de créer des formulaires en ligne pour recueillir des réponses et des données auprès des utilisateurs. Pour créer un formulaire :</p>
                       <p>.Allez dans "Formulaire" dans la barre de menu.
                          .Sélectionnez "Nouveau formulaire".
                          .Ajoutez des questions en utilisant les options disponibles.</p>
                        <h2>Utilisation de la fonction "Table des matières"</h2>
                        <p>Google Docs offre la possibilité de générer automatiquement une table des matières à partir de vos titres de section. Pour utiliser la fonction "Table des matières" :</p>
                        <p>.Placez votre curseur à l'endroit où vous souhaitez insérer la table des matières.
                           .Allez dans "Insertion" dans la barre de menu.
                           .Sélectionnez "Table des matières" et choisissez un style.</p>
                        <h2>Création de graphiques et de diagrammes</h2>
                        <p>Google Docs propose des outils intégrés pour créer des graphiques et des diagrammes à partir de vos données. Pour créer un graphique :</p>
                        <p>.Sélectionnez les données que vous souhaitez inclure dans le graphique.
                           .Allez dans "Insertion" dans la barre de menu.
                           .Sélectionnez "Graphique" et choisissez le type de graphique que vous souhaitez créer.</p>
                        <h2>Utilisation de la fonction de traduction</h2>
                        <p>Google Docs offre la possibilité de traduire votre document dans différentes langues. Pour traduire votre document :</p>
                        <p>.Allez dans "Outils" dans la barre de menu.
                           .Sélectionnez "Traduire le document".
                           .Choisissez la langue cible et cliquez sur "Traduire".</p>
                         <h2>Intégration avec d'autres applications Google</h2>
                         <h3>Utilisation de Google Drive</h3>
                         <p>Google Docs est étroitement intégré avec Google Drive, ce qui facilite le stockage, la gestion et le partage de vos documents. Voici quelques fonctionnalités utiles de Google Drive en relation avec Google Docs :</p>  
                         <p>.Organisation des documents dans des dossiers pour une meilleure gestion.
                            .Partage de documents avec d'autres utilisateurs et attribution de droits d'accès.
                            .Accès aux documents depuis n'importe quel appareil connecté à Internet.</p>
                        <h2>Collaboration en temps réel avec Google Meet</h2>
                        <p>Google Docs offre la possibilité de collaborer en temps réel avec d'autres utilisateurs via Google Meet, un service de visioconférence de Google. Voici comment démarrer une réunion Google Meet depuis Google Docs :</p>
                        <p>.Ouvrez le document sur lequel vous souhaitez collaborer.
                           .Cliquez sur l'icône "Ajouter des personnes à cette réunion" dans le coin supérieur droit de l'écran.
                           .Sélectionnez "Démarrer une réunion" pour lancer une visioconférence en temps réel.</p>
                        <h2>Intégration avec Google Agenda</h2>
                        <p>Google Docs peut être intégré à Google Agenda pour planifier des réunions et des événements en fonction du contenu du document. Voici comment créer un événement Google Agenda à partir de Google Docs :</p>
                        <p>.Sélectionnez le texte ou l'élément dans le document que vous souhaitez ajouter à l'agenda.
                           .Cliquez sur "Ajouter un événement à l'agenda" dans le menu contextuel.
                           .Saisissez les détails de l'événement et cliquez sur "Enregistrer".</p>
                        <h2>Utilisation de Google Classroom</h2>
                        <p>Google Docs peut être utilisé en conjonction avec Google Classroom, une plateforme d'apprentissage en ligne de Google, pour créer des devoirs, partager des ressources et collaborer avec des élèves. Voici comment utiliser Google Docs avec Google Classroom :</p>
                        <p>.Créez un devoir dans Google Classroom et sélectionnez "Ajouter" - "Document" pour ajouter un document Google Docs.
                           .Partagez le document avec vos élèves et définissez les autorisations d'édition appropriées.
                           .Les élèves peuvent alors travailler sur le document et le soumettre directement depuis Google Classroom.</p>
                        <h2>Conclusion</h2>
                        <p>En intégrant Google Docs avec d'autres applications Google telles que Google Drive, Google Meet, Google Agenda et Google Classroom, vous pouvez enrichir votre expérience utilisateur et tirer parti de fonctionnalités supplémentaires pour la collaboration, la planification d'événements, les réunions en ligne et l'enseignement à distance. Cette intégration étroite entre les différentes applications Google offre une solution complète pour vos besoins professionnels et éducatifs.</p>
     <QuizG/>
                </div>
              
            </div>  
            </>
    );
};

export default GoogleDoc;